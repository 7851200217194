import React from 'react';
import './popup-overlay.styles.scss';

interface Props {
  children?: React.ReactChild;
  position: 'absolute' | 'fixed';
}

const PopupOverlay = (props: Props) => {
  return (
    <div className={ props.position === 'fixed' ? 'popup-overlay' : 'popup-overlay-absolute' }>
      {props.children}
    </div>
  );
}

export default PopupOverlay;