import React, { useState, useEffect } from "react";
import "./kalibracja-panel.scss";
import Helmet from "react-helmet";
import Container from "react-bootstrap/Container";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Table from "react-bootstrap/Table";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { selectInputsWages } from "../../../../redux/wagesState/wagesState.selectors";
import { InputWageDto, VariantWageDto, OutputWageDto, WageDto } from "../../../../dto/wageDto";
import Button from "react-bootstrap/Button";
import Jumbotron from "react-bootstrap/Jumbotron";
import { RoutePath } from "../../../../../route-paths";
import { Link } from "react-router-dom";
import { hslHHelper, hslSHelper, hslLHelper } from "../../../../tools/wagesColourHelper";
import SpeedoMeter from "../../../../components/speedo-meter/speedo-meter";
import { updateWage } from "../../../../redux/wagesState/wagesState.actions";

const KalibracjaAdvanced: React.FC<any> = () => {
  const inputsWages = useSelector(selectInputsWages, shallowEqual) as Array<InputWageDto>


  const [firstLoad, setfirstLoad] = useState(true);
  const [key, setKey] = useState('');

  useEffect(() => {
    if (firstLoad && inputsWages && inputsWages.length > 0) {
      setKey(inputsWages[0]!.inputName);
      setfirstLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputsWages])

  const dispatch = useDispatch();

  const handleSelectWage = (value: number, wage: WageDto) => {
    dispatch(updateWage(wage.id, Number(value)))
  }


  return (
    <>
      <div className="kalibracja-panel">
        <Helmet>
          <title>{'Kalibracja | Suggestly'}</title>
        </Helmet>
        <Container fluid={false}>
          {inputsWages ? (inputsWages.length > 0 && inputsWages[0].outputs && inputsWages[0].outputs.length > 0 && inputsWages.filter(x => x.variants && x.variants.length === 0).length === 0 ?
            <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k: string) => setKey(k)}>
              {inputsWages.map((inputWage: InputWageDto, index: number) => {
                return (
                  <Tab eventKey={inputWage.inputName} title={inputWage.inputName} key={index}>
                    <Table bordered hover>
                      <thead>
                        <tr>
                          <th>#</th>
                          {inputWage.variants.map((variant: VariantWageDto, index: number) => {
                            return <th key={index}>{variant.title}</th>
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {inputWage.outputs.map((output: OutputWageDto, index: number) => {
                          return (
                            <tr className="wagesRow" key={index}>
                              <td title={output.name}>{output.name}</td>
                              {output.wages.map((wage: WageDto, index: number) => {
                                return (
                                  <td key={index}>
                                    <div
                                      className={"coloryzator"}
                                      style={{
                                        backgroundColor:
                                          `hsla(
                                              ${hslHHelper(wage.value > 2 ? 3 : (wage.value < 0 ? -1 : wage.value))},
                                              ${hslSHelper(wage.value)}%, ${hslLHelper(wage.value > 2 ? 3 : (wage.value < 0 ? -1 : wage.value))}%,
                                              0.3` }}>

                                      <div className="control-info-panel">
                                        <i
                                          className={wage.value < 0 ? "fa fa-ban active" : "fa fa-ban"}
                                          aria-hidden="true"
                                          onClick={() => { handleSelectWage(-9999, wage) }}
                                        ></i>
                                        <SpeedoMeter wage={wage}>
                                          <span className="currentValue">{wage.value >= 0 ? wage.value.toFixed(1) : "----"} </span>
                                        </SpeedoMeter>
                                        <i
                                          className={wage.value > 2 ? "fa fa-bolt active" : "fa fa-bolt"}
                                          aria-hidden="true"
                                          onClick={() => { handleSelectWage(5, wage) }}
                                        ></i>
                                      </div>
                                    </div>
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </Tab>
                )
              })}
            </Tabs>
            :
            <Jumbotron className="delay-loading">
              <h2>Kalibracja nie jest możliwa</h2>
              <p>
                Pytania, warianty odpowiedzi lub sugestie zawierają braki. Aby kontynuować, skonfiguruj brakujace etapy. Jeśli nie wiesz, co należy zrobić, skontaktuj się z nam, pomożemy tak szybko, jak tylko będzie to możliwe.
              </p>
              <br />
              <div className="fastNav">
                {inputsWages.length > 0 ? (
                  inputsWages[0].variants && inputsWages[0].variants.length > 0 ? (
                    inputsWages.find(x => !(x.variants && x.variants.length > 0)) ? <Link to={RoutePath.PYTANIA_PANEL}>
                      <Button variant="secondary">Każde pytanie powinno mieć warianty odpowiedzi</Button>
                    </Link> : null
                  ) :
                    <Link to={RoutePath.PYTANIA_PANEL}>
                      <Button variant="secondary">Warianty</Button>
                    </Link>
                ) :
                  <Link to={RoutePath.PYTANIA_PANEL}>
                    <Button variant="secondary">Pytania</Button>
                  </Link>
                }
                {inputsWages.length > 0 && inputsWages[0].outputs && inputsWages[0].outputs.length > 0 ? null :
                  <Link to={RoutePath.PRODUKTY_PANEL}>
                    <Button variant="primary">Sugestie</Button>
                  </Link>
                }
                <Button variant="link" className={"supportBtn"}>Pomoc</Button>
              </div>
            </Jumbotron>) : null}
        </Container>
      </div>
    </>
  )
}

export default KalibracjaAdvanced;