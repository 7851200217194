import React, { useEffect, useRef } from 'react';

interface Props {
  loadData: (page: number) => any;
  itemsPerPage: number;
  currentItemsNumber: number
}

let inifnityScroll_currentItemsNumber: number = 0;
let infinityScroll_prevPage: number = 0;
let infinityScroll_requestAllowed: boolean = true;
let infinitySroll_itemsPerPage: number = 100;
let infinityScroll_loadData: (page: number) => void;

const InfiniteScroll: React.FC<Props> = (props) => {
  inifnityScroll_currentItemsNumber = props.itemsPerPage;
  const bottomRef = useRef<any>()

  useEffect(() => {
    inifnityScroll_currentItemsNumber = props.currentItemsNumber;
  }, [props])

  /* eslint-disable */
  useEffect(() => {
    inifnityScroll_currentItemsNumber = 0;
    infinityScroll_prevPage = 0;
    inifnityScroll_currentItemsNumber = props.currentItemsNumber;
    infinitySroll_itemsPerPage = props.itemsPerPage;
    infinityScroll_loadData = props.loadData
    handleScroll();
    return () => {
      inifnityScroll_currentItemsNumber = 0;
      infinityScroll_prevPage = 0;
      inifnityScroll_currentItemsNumber = 0;
      infinitySroll_itemsPerPage = 100
      infinityScroll_loadData = () => { };
      window.removeEventListener('scroll', handleScroll);
    };
  }, [])
  /* eslint-enable */

  const handleScroll = () => {

    window.addEventListener("scroll", (e: any) => {
      const actualPos = e.target.scrollTop + window.innerHeight;
      let bottomPos = 9999999999;
      if(bottomRef.current !== null){
        bottomPos = bottomRef.current.offsetTop;
      }
      if (actualPos >= bottomPos) {
        const page = Math.round(inifnityScroll_currentItemsNumber / infinitySroll_itemsPerPage);
        if (page > infinityScroll_prevPage && infinityScroll_requestAllowed === true) {
          infinityScroll_requestAllowed = false;
          setTimeout(() => {
            infinityScroll_requestAllowed = true;
          }, 1000);
          infinityScroll_prevPage = page
          infinityScroll_loadData(page);
        }
      }
    }, true);
  }

  return (
    <>
      {props.children}
      <div ref={bottomRef} />
    </>
  )
}

export default InfiniteScroll