import { all, call } from 'redux-saga/effects';
import UserSagas from './user/user.sagas';
import InputsStateSagas from './inputsState/inputsState.sagas';
import ProductsStateSagas from './productState/productsState.sagas';
import WagesStateSagas from './wagesState/wagesState.sagas';
import SuggestlyEntityStateSagas from './suggestlyEntityState/suggestlyEntityState.sagas';
import CompanyInformationsStateSagas from './companyInformationsState/companyInformationsState.sagas';
import EmbedStateSagas from './embedState/embedState.sagas';
import AccountSettingsStateSagas from './accountSettingsState/accountSettingsState.sagas';
import LeadsStateSagas from './leadsState/leadsState.sagas';


export default function* rootSaga() {
  yield all([
    call(UserSagas),
    call(InputsStateSagas),
    call(LeadsStateSagas),
    call(AccountSettingsStateSagas),
    call(EmbedStateSagas),
    call(ProductsStateSagas),
    call(WagesStateSagas),
    call(SuggestlyEntityStateSagas),
    call(CompanyInformationsStateSagas)
  ]);
}