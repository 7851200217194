import { createSelector } from 'reselect';
import { SuggestlyStore } from '../store.types';

const leadsState = (state: SuggestlyStore) => state.leadsState;

export const selectLeads = createSelector(
  [leadsState],
  leadsState => {
    return leadsState.leads
  }
);