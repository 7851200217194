import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { ToastsStore } from "react-toasts";
import { ApiMethodDto, UrlParam } from "../../api/api-method-dto";
import { ApiPath } from "../../api/apiPath";
import { callApi } from "../../api/api-call-service";
import { SagaInputDto } from "../../api/saga-input-dto";
import { CompanyInformationsEnum } from "../../dto/companyInformationsEnum";
import { CompanyInformationsDto } from "../../dto/companyInformationsDto";
import CompanyInformationsStateTypes from "./companyInformationsState.types";

function* getCompanyInformationsAsync() {
  const token = yield select(state => state.user.token);

  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.accountSettings_getBillingInformations, options);

    yield put({
      type: CompanyInformationsStateTypes.GET_ADDRESSINFORMATIONS_SUCCESS,
      payload: res.data as CompanyInformationsDto
    });
  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* updateCompanyInformationsAsync(sagaInput: SagaInputDto<{ value: string, type: CompanyInformationsEnum }>) {
  const token = yield select(state => state.user.token);
  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
    params: [{
      name: "value",
      value: sagaInput.payload.value
    }, {
      name: "type",
      value: sagaInput.payload.type
    }] as Array<UrlParam>
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.accountSettings_updateBillingField, options);
    yield put({
      type: CompanyInformationsStateTypes.GET_ADDRESSINFORMATIONS_SUCCESS,
      payload: res.data as CompanyInformationsDto
    });

    ToastsStore.success('saved');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* watchUpdateAddressField() {
  yield takeLatest(CompanyInformationsStateTypes.UPDATE_ADDRESSFIELD as any, updateCompanyInformationsAsync);
}

function* watchGetCompanyInformations() {
  yield takeLatest(CompanyInformationsStateTypes.GET_ADDRESSINFORMATIONS as any, getCompanyInformationsAsync);
}

export default function* CompanyInformationsStateSagas() {
  yield all([
    call(watchUpdateAddressField),
    call(watchGetCompanyInformations)]);
}