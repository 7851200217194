import React from "react";
import { Switch, Route } from "react-router-dom";
import { RoutePath } from "../../../../route-paths";
import NavbarTop from "../../../components/navbar-top/navbar-top";
import SideBarAccountSettings from "../../../components/sidebar/sidebarAccountSettings";
import HomeSettings from "./home-settings/home-settings";
import SubscriptionSettings from "./subscription-settings/subscription-settings";
import PersonalDataSettings from "./personal-data-settings/personal-data-settings";
import ContactSettings from "./contact-settings/contact-settings";
import RealizeCouponSettings from "./realize-coupon-settings/realize-coupon-settings";

const SettingsSwitch: React.FC = () => {
  return (
    <>
      <NavbarTop isEntityMenuActive={false} />
      <div className="mainWrapper">
        <SideBarAccountSettings />
        <div className="pagesPanel">
          <Switch>
            <Route exact path={RoutePath.HOME_SETTINGS} component={HomeSettings} />
            <Route exact path={RoutePath.PERSONAL_DATA_SETTINGS} component={PersonalDataSettings} />
            <Route exact path={RoutePath.SUBSCRIPTION_SETTINGS} component={SubscriptionSettings} />
            <Route exact path={RoutePath.CONTACT_SETTINGS} component={ContactSettings} />
            <Route exact path={RoutePath.REALIZECOUPON_SETTINGS} component={RealizeCouponSettings} />

            <Route component={HomeSettings} />
          </Switch>
        </div>
      </div>
    </>
  );
}

export default SettingsSwitch;