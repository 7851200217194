const SuggestlyEntityStateActionTypes = {
  SET_CURRENT_SUGGESTLYENTITY_ID_SUCCESS: 'SET_CURRENT_SUGGESTLYENTITY_ID_SUCCESS',
  CREATE_SUGGESTLYENTITY: 'CREATE_SUGGESTLYENTITY',
  UPDATE_WIDGETDOMAIN: 'UPDATE_WIDGETDOMAIN',
  UPDATE_EXTERNALDOMAIN: 'UPDATE_EXTERNALDOMAIN',
  DELETE_SUGGESTLYENTITY: 'DELETE_SUGGESTLYENTITY',
  RENAME_SUGGESTLYENTITY: 'RENAME_SUGGESTLYENTITY',

  HIDE_GLOBAL_MODAL_SUCCESS: 'HIDE_GLOBAL_MODAL_SUCCESS',
  SHOW_GLOBAL_MODAL_SUCCESS: 'SHOW_GLOBAL_MODAL_SUCCESS',

  GET_CLIENTACTIONS: 'GET_CLIENTACTIONS',
  GET_CLIENTACTIONS_SUCCESS: 'GET_CLIENTACTIONS_SUCCESS',
  CLEAR_CLIENTACTIONS_SUCCESS: 'CLEAR_CLIENTACTIONS_SUCCESS',

  SUGGESTLYENTITY_CLEAR_STATE: 'SUGGESTLYENTITY_CLEAR_STATE'
};

export default SuggestlyEntityStateActionTypes;