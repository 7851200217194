import { SuggestlyStore } from '../store.types';
import { createSelector } from 'reselect';

const suggestlyEntityState = (state: SuggestlyStore) => state.suggestlyEntityState;

export const selectCurrentSuggestlyEntityId = createSelector(
  [suggestlyEntityState],
  suggestlyEntityState => { 
    return suggestlyEntityState.currentEntitySuggestlyId
  }
);

export const selectGlobalModal = createSelector(
  [suggestlyEntityState],
  suggestlyEntityState => { 
    return suggestlyEntityState.globalModal
  }
);

export const selectClientActionsSum = createSelector(
  [suggestlyEntityState],
  suggestlyEntityState => { 
    return suggestlyEntityState.clientActionsSum
  }
);