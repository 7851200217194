import React from "react";
import "./home-settings.scss";
import Helmet from "react-helmet";
import { Container } from "react-bootstrap";
import ChangePasswordForm from "../../../../components/change-password-form/change-password-form.component";
import ChangeNotificationsForm from "../../../../components/change-notifications-form/change-notifications-form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MainSection from "../../../../components/main-section/main-section";

const HomeSettings: React.FC<any> = () => {
  return (
    <>
      <Helmet>
        <title>{'Settings | Suggestly'}</title>
      </Helmet>
      <div className="home-settings">
        <MainSection
          title="Ustawienia konta"
        >
        </MainSection>
        <Container fluid={false}>
          <Row>
            <Col xl={{ span: 5 }}>
              <ChangePasswordForm />
            </Col>
            <Col xl={{ span: 7 }}>
              <ChangeNotificationsForm />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default HomeSettings;