import React, { ReactNode } from "react";
import Toggle, { ToggleProps } from "react-toggle"
import "./switch.scss";

interface Props {
  children: ReactNode,
  inText?: boolean
}

const Switch: React.FC<Props & ToggleProps> = (props: ToggleProps & Props) => {
  const {
    children,
  } = props;

  return <span className="toggle-wrapper">
    <Toggle
      checked={props.checked}
      icons={props.icons ? props.icons : {
        checked: null,
        unchecked: null,
        }
      }
      onChange={props.onChange} />
    <span>{children as React.ReactElement}</span></span>
}

export default Switch;
