import React from "react";
import "./personal-data-settings.scss";
import Helmet from "react-helmet";
import { Container } from "react-bootstrap";
import BillingInformations from "../../../../components/billing-informations/billing-informations";
import MainSection from "../../../../components/main-section/main-section";


const PersonalDataSettings: React.FC<any> = () => {
  return (
    <>
      <Helmet>
        <title>{'Subscription | Suggestly'}</title>
      </Helmet>
      <div className="personal-data-settings">
        <Container fluid={false}>
          <MainSection
            title="Dane dane faktury"
          />
          <BillingInformations />
        </Container>
      </div>
    </>
  )
}

export default PersonalDataSettings;