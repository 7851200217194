import AccountSettingsTypes from "./accountSettingsState.types";
import { NewsletterSettingsDto } from "../../dto/newsletterSettingsDto";
import { ChangePasswordDto } from "../../dto/changePasswordDto";
import { ContactFormDto } from "../../dto/contactFormDto";
import AccountSettingsStateTypes from "./accountSettingsState.types";

export const changeNotifications = (newsletterSettings: NewsletterSettingsDto) => ({
  type: AccountSettingsTypes.CHANGE_NOTIFICATIONS,
  payload: newsletterSettings
});

export const getNotifications = () => ({
  type: AccountSettingsTypes.GET_NOTIFICATIONS
});

export const getNotificationsSuccess = (newsletterSettings: NewsletterSettingsDto) => ({
  type: AccountSettingsTypes.GET_NOTIFICATIONS_SUCCESS,
  payload: newsletterSettings
});

export const changePassword = (changePasswordDto: ChangePasswordDto) => ({
  type: AccountSettingsTypes.CHANGE_PASSWORD,
  payload: changePasswordDto
});

export const sendMessageToSupport = (contactFormDto: ContactFormDto) => ({
  type: AccountSettingsStateTypes.SEND_MESSAGETOSUPPORT,
  payload: contactFormDto
})