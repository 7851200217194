import SuggestlyEntityStateActionTypes from "./suggestlyEntityState.types";
import { ClientActionDto } from "../../dto/clientActionDto";

export const setCurrentSuggestlyEntityIdSuccess = (id: string) => {
  return {
    type: SuggestlyEntityStateActionTypes.SET_CURRENT_SUGGESTLYENTITY_ID_SUCCESS,
    payload: id
  };
};

export const addSuggestlyEntity = (name: string) => {
  return {
    type: SuggestlyEntityStateActionTypes.CREATE_SUGGESTLYENTITY,
    payload: name
  };
};

export const renameDefaultProject = (name: string, id: string) => {
  return {
    type: SuggestlyEntityStateActionTypes.RENAME_SUGGESTLYENTITY,
    payload: { name: name, id: id } as { name: string, id: string }
  };
};


export const deleteSuggestlyEntity = (name: string) => {
  return {
    type: SuggestlyEntityStateActionTypes.DELETE_SUGGESTLYENTITY,
    payload: name
  };
};

export const hideGlobalModal = () => {
  return {
    type: SuggestlyEntityStateActionTypes.HIDE_GLOBAL_MODAL_SUCCESS
  }
}

export const getClientActions = () => {
  return {
    type: SuggestlyEntityStateActionTypes.GET_CLIENTACTIONS
  };
};

export const getClientActionsSuccess = (clientActions: ClientActionDto) => {
  return {
    type: SuggestlyEntityStateActionTypes.GET_CLIENTACTIONS_SUCCESS,
    payload: clientActions
  };
};

export const getClearClientActionsSuccess = () => {
  return {
    type: SuggestlyEntityStateActionTypes.CLEAR_CLIENTACTIONS_SUCCESS
  };
};
