import "./popup.styles.scss";
import React from 'react';
import OutsideWrapperDetector from '../outside-wrapper-detector/outside-wrapper-detector.component';
import cancelIcon from '../../assets/cancel_icon.svg';
import PopupOverlay from "../popup-overlay/popup-overlay.component";
import Button from "react-bootstrap/Button";

interface Props {
  isOpen: boolean;
  hideCancelButton?: boolean
  handleConfirm?: any;
  handleCancel: any;
  hideFooter?: boolean;
  title?: string;
  disableCloseOnConfirm?: boolean
  isDefaultOpen?: boolean
}

interface State {
  isOpen: boolean;
}

export class Popup extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      isOpen: props.isOpen
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    const { isOpen } = this.state;

    if (isOpen !== nextProps.isOpen) {
      this.setState({ isOpen: nextProps.isOpen })
    }
  }

  handleClose = () => {
    this.setState({ isOpen: false })
    this.props.handleCancel();  
  }

  handleConfirm = () => {
    this.props.handleConfirm();
    if (this.props.disableCloseOnConfirm === undefined || this.props.disableCloseOnConfirm === false) {
      this.setState({ isOpen: false })
    }
  }

  render() {
    const { isOpen } = this.state;
    const { children } = this.props;
    return (
      isOpen ?
        <PopupOverlay position='fixed'>
          <OutsideWrapperDetector handleClose={this.handleClose}>
            <div className="popup">
              <img className="cancelIcon" src={cancelIcon} alt="X" onClick={this.handleClose} />
              {this.props.title ? <div className="popup-title">{this.props.title}</div> : null}
              {children}
              {!this.props.hideFooter ?
                <div className="popup-footer">
                  {this.props.handleConfirm ? <Button onClick={this.handleConfirm}>Confirm</Button> : null}
                  {this.props.hideCancelButton ? null : <Button onClick={this.handleClose}>Cancel</Button>}
                </div> : null}
            </div>
          </OutsideWrapperDetector>
        </PopupOverlay> : null
    );
  }
}

export default Popup;


