import React, { Component } from "react";
import { connect } from 'react-redux'
import { ToastsStore } from 'react-toasts';
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { ChangePasswordDto } from "../../dto/changePasswordDto";
import Popup from "../popup/popup.component";
import { changePassword } from "../../redux/accountSettingsState/accountSettingsState.actions";
import Row from "react-bootstrap/Row";
import "../product-item/product-item.scss";
import Col from "react-bootstrap/Col";

interface State {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  isOpen: boolean;
}

interface Props {
  changePassword: any;
}

class ChangePasswordForm extends Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      isOpen: false
    };
  }

  openPopup = (e: any) => {
    e.preventDefault()
    const { newPassword, confirmNewPassword } = this.state;

    if (newPassword !== confirmNewPassword) {
      ToastsStore.warning('Hasła nie są jednakowe.');
      return;
    }
    this.setState({ isOpen: true })
  };

  handleConfirm = () => {
    this.setState({ isOpen: false })
    this.handleSubmit();
  }

  handleCancel = () => {
    this.setState({ isOpen: false })
  }

  handleSubmit = async () => {
    const { oldPassword, newPassword } = this.state;

    const changePasswordDto: ChangePasswordDto = { oldPassword: oldPassword, newPassword: newPassword }
    this.props.changePassword(changePasswordDto);
  };

  handleChange = (e: any) => {
    const { name, value } = e.target;
    this.setState({ [name]: value } as State);
  };

  render() {
    const { oldPassword, newPassword, confirmNewPassword, isOpen } = this.state;
    return (
      <>
        <Popup isOpen={isOpen} handleConfirm={this.handleConfirm} handleCancel={this.handleCancel}>
          <div className="popup-title">To zmieni Twoje hasło!</div>
          <div className="popup-body">Czy chcesz to zrobić?</div>
        </Popup>

        <div className="product-item">
          <Card className="inputs-group-main">
            <Card.Header>
              Zmień hasło
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xl={{ span: 12 }}>
                  <Form onSubmit={this.openPopup}>
                    <Form.Label>Stare hasło*</Form.Label>
                    <Form.Control
                      name="oldPassword"
                      placeholder="Stare hasło"
                      type="password"
                      value={oldPassword}
                      onChange={this.handleChange}
                      required
                    />
                    <Form.Label>Nowe hasło*</Form.Label>
                    <Form.Control
                      name="newPassword"
                      placeholder="Nowe hasło"
                      type="password"
                      value={newPassword}
                      onChange={this.handleChange}
                      required
                    />
                    <Form.Label>Powtórz nowe hasło*</Form.Label>
                    <Form.Control
                      name="confirmNewPassword"
                      type="password"
                      placeholder="Nowe hasło"
                      value={confirmNewPassword}
                      onChange={this.handleChange}
                      required
                    />
                    <Button type="submit" style={{ float: 'right' }}>Zmień</Button>
                  </Form>
                </Col>
              </Row>
              <br />
            </Card.Body>
          </Card>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  changePassword: (changePasswordDto: ChangePasswordDto) => dispatch(changePassword(changePasswordDto))
})

export default connect(null, mapDispatchToProps)(ChangePasswordForm);
