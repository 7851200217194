const InputsStateActionTypes = {
  ADD_CATEGORY: 'ADD_CATEGORY',
  ADD_CATEGORY_SUCCESS: 'ADD_CATEGORY_SUCCESS',
  DELETE_CATEGORY: 'DELETE_CATEGORY',
  DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',

  UPDATE_CATEGORYNAME: 'UPDATE_CATEGORYNAME',
  UPDATE_CATEGORYTITLE: 'UPDATE_CATEGORYTITLE',
  UPDATE_CATEGORYDESCRIPTION: 'UPDATE_CATEGORYDESCRIPTION',
  UPDATE_CATEGORYIMAGE: 'UPDATE_CATEGORYIMAGE',

  ADD_VARIANT: 'ADD_VARIANT',
  DELETE_VARIANT: 'DELETE_VARIANT',
  UPDATE_VARIANT: 'UPDATE_VARIANT',
  UPDATE_VARIANT_TITLE: 'UPDATE_VARIANT_TITLE',
  
  UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
  
  GET_ALL_CATEGORIES: 'GET_ALL_CATEGORIES',
  GET_ALL_CATEGORIES_SUCCESS: 'GET_ALL_CATEGORIES_SUCCESS',

  CATEGORIES_CLEAR_STATE: 'CATEGORIES_CLEAR_STATE'
};

export default InputsStateActionTypes;