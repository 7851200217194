import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { ToastsStore } from "react-toasts";
import { ApiMethodDto } from "../../api/api-method-dto";
import { ApiPath } from "../../api/apiPath";
import { callApi } from "../../api/api-call-service";
import { SagaInputDto } from "../../api/saga-input-dto";
import AccountSettingsStateTypes from "./accountSettingsState.types";
import { ChangePasswordDto } from "../../dto/changePasswordDto";
import { NewsletterSettingsDto } from "../../dto/newsletterSettingsDto";
import UserActionTypes from "../user/user.types";
import { ContactFormDto } from "../../dto/contactFormDto";
import history from '../../../history';
import { RoutePath } from "../../../route-paths";

function* getNotificationsAsync() {
  const token = yield select(state => state.user.token);
  try {
    const options = {
      headers: { Authorization: token },
      method: "GET",
      expectedStatus: 200
    } as ApiMethodDto;

    const res = yield call(callApi, options.method, ApiPath.accountSettings_getNotifications, options);

    yield put({
      type: AccountSettingsStateTypes.GET_NOTIFICATIONS_SUCCESS,
      payload: res.data
    });

  } catch (error) {
    console.error("Something went wrong.", error);
  }
}

function* changeNotificationsAsync(sagaInput_changeNotifications: SagaInputDto<NewsletterSettingsDto>) {
  const token = yield select(state => state.user.token);
  try {
    const options = {
      body: sagaInput_changeNotifications.payload,
      headers: { Authorization: token, AccessControlAllowOrigin: "*", "Content-Type": "application/json" },
      method: "POST",
      expectedStatus: 200
    } as ApiMethodDto;

    const res = yield call(callApi, options.method, ApiPath.accountSettings_changeNotifications, options);

    yield put({
      type: AccountSettingsStateTypes.GET_NOTIFICATIONS_SUCCESS,
      payload: res.data
    });

    ToastsStore.success('Your notifications settings have been changed.');

  } catch (error) {
    console.error("Something went wrong.", error);
  }
}

function* changePasswordAsync(sagaInput_changePassword: SagaInputDto<ChangePasswordDto>) {
  const token = yield select(state => state.user.token);
  try {
    const options = {
      body: sagaInput_changePassword.payload,
      headers: { Authorization: token, AccessControlAllowOrigin: "*", "Content-Type": "application/json" },
      method: "POST",
      expectedStatus: 200
    } as ApiMethodDto;

    yield call(callApi, options.method, ApiPath.accountSettings_changePassword, options);

    yield put({ type: UserActionTypes.USER_CLEAR_STORE });

    ToastsStore.success('Your password has been changed, please Sign In again with new credentials.');

  } catch (error) {
    console.error("Something went wrong.", error);
  }
}

function* sendMessageToSupportAsync(sagaInput_contactForm: SagaInputDto<ContactFormDto>) {
  const token = yield select(state => state.user.token);
  try {
    const options = {
      body: sagaInput_contactForm.payload,
      headers: { Authorization: token, AccessControlAllowOrigin: "*", "Content-Type": "application/json" },
      method: "POST",
      expectedStatus: 200
    } as ApiMethodDto;

    yield call(callApi, options.method, ApiPath.accountSettings_sendMessageToSupport, options);
    ToastsStore.success('Your message has been send.');
    history.push(RoutePath.HOME_PANEL)

  } catch (error) {
    console.error("Something went wrong.", error);
  }
}

function* watchChangeNotifications() {
  yield takeLatest(AccountSettingsStateTypes.CHANGE_NOTIFICATIONS as any, changeNotificationsAsync);
}

function* watchGetNotifications() {
  yield takeLatest(AccountSettingsStateTypes.GET_NOTIFICATIONS as any, getNotificationsAsync);
}

function* watchChangePassword() {
  yield takeLatest(AccountSettingsStateTypes.CHANGE_PASSWORD as any, changePasswordAsync);
}

function* watchSendMessageToSupport() {
  yield takeLatest(AccountSettingsStateTypes.SEND_MESSAGETOSUPPORT as any, sendMessageToSupportAsync);
}

export default function* AccountSettingsStateSagas() {
  yield all([
    call(watchChangeNotifications),
    call(watchGetNotifications),
    call(watchChangePassword),
    call(watchSendMessageToSupport)
  ]);
}