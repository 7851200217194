import { createSelector } from 'reselect';
import { SuggestlyStore } from '../store.types';

const callbacksState = (state: SuggestlyStore) => state.callbacksState;

export const selectIsSignUp_Finished = createSelector(
  [callbacksState],
  callbacksState => {
    return callbacksState.is_signUp_finished
  }
);

export const selectIsSignIn_Finished = createSelector(
  [callbacksState],
  callbacksState => {
    return callbacksState.is_signIn_finished
  }
);

export const selectIs_globalToastFailedToFetchActive = createSelector(
  [callbacksState],
  callbacksState => {
    return callbacksState.is_globalToastFailedToFetchActive
  }
);