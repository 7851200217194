
export function hslHHelper(value: number): number {
  const hueUp = (value / 2) * 120;
  return hueUp;
}

export function hslSHelper(value: number): number {
  const hueUp = Math.pow(1000000000, (((Math.abs(value - 1) * 2) - 1)))
  return hueUp > 100 ? 100 : hueUp;
}

export function hslLHelper(value: number): number {
  const hueUp =  (100-(hslSHelper(value)/10));
  return hueUp;
}