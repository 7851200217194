import React from "react";
import "./product-item.scss";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TurnClients from "./turnClients.svg";

interface Props {
  isActive: boolean
}

const OutputTypeCardAffiliation: React.FC<Props> = (props: Props) => {
  return (
    <Card className={props.isActive ? "outputTypeScenario active" : "outputTypeScenario"}>
      <Row>
        <Col xl={{ span: 6 }}>
          <h5>Zamień gości w klientów</h5>
          <Row>
            <Col xl={{ span: 4 }} className="align-self-center">
              <img src={TurnClients} alt="TurnClients" className="scenarioImg" />
            </Col>
            <Col xl={{ span: 8 }} className="scenarioDescription align-self-center">
              Ten scenariusz jest idealny, jeśli chcesz przekierowywać ruch w odpowiednie miejsce np. do Twojego sklepu online. Przedstaw produkt, a następnie wskaż miejsce, gdzie można go kupić.
            </Col>
          </Row>
        </Col>
        <Col xl={{ span: 5, offset: 1 }} className="scenarioOptions align-self-center">
          <Row>
            <Col xl={{ span: 5 }} className="scenarioOption align-self-center">
              <div>Produkt</div>
            </Col>
            <Col xl={{ span: 2 }} className="scenarioIcon">
              <i className="fa fa-plus" aria-hidden="true"></i>
            </Col>
            <Col xl={{ span: 5 }} className="scenarioOption colorful">
              <div>Link</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}

export default OutputTypeCardAffiliation;