import React from "react";
import Helmet from "react-helmet";
import SignUpForm from "../../components/sign-upin-form/sign-up-form";
import "./signinup-page.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import { RoutePath } from "../../../route-paths";
import NavbarLanding from "../../components/navbar-landing/navbar-landing";

const SignUpPage: React.FC<any> = () => {
  return (
    <div className="signinup-page">
      <Helmet>
        <title>{'Suggestly | Tworzenie konta'}</title>
      </Helmet>
      <NavbarLanding isSubNavHidden />
      <Row>
        <Col xl={{ span: 6, offset: 3 }}>
          <Card className="signInForm">
            <h5>Stwórz nowe konto</h5>
            <SignUpForm />
            <div className="sign-inup-nav">
              Masz już konto? <Link to={RoutePath.SIGNIN}>Zaloguj się tutaj</Link>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default SignUpPage;