import EmbedStateTypes from './embedState.types';
import { EmbedDomainDto } from '../../dto/embedDomainDto';

const INITIAL_STATE = {
  embedDomains: [] as Array<EmbedDomainDto>,
}

const embedStateReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case EmbedStateTypes.GET_EXTERNALDOMAINS_SUCCESS:
      return {
        ...state,
        embedDomains: action.payload
      }
    case EmbedStateTypes.EMBED_CLEAR_STATE:
      return {
        ...state,
        embedDomains: [] as Array<EmbedDomainDto>
      }
    default: {
      return state;
    }
  }
}

export default embedStateReducer;