import React, { useEffect } from "react";
import Spinner from "../../../components/spinner/spinner";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { selectIsWaitingForNewRolesActive } from "../../../redux/user/user.selectors";
import { Redirect } from "react-router-dom";
import { RoutePath } from "../../../../route-paths";
import { setIsWaitingForNewRolesActiveSuccess, getUserRoles } from "../../../redux/user/user.actions";

const PaymentCheckout: React.FC = () => {
  const isWaitingForNewRolesActive = useSelector(selectIsWaitingForNewRolesActive, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(getUserRoles());
      dispatch(setIsWaitingForNewRolesActiveSuccess(false));
    }, 3500);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {isWaitingForNewRolesActive && isWaitingForNewRolesActive.isActive ?
        <Spinner isVisible={true}>
          <>
            <h6>Za chwilę nastąpi przeniesienie</h6>
            {isWaitingForNewRolesActive.text ?
              <h3>{isWaitingForNewRolesActive.text}</h3>
              :
              <h3>Twoja płatność aktualnie jest weryfikowana</h3>
            }
            <br />
            <p style={{ color: "#ddd" }}>Jeżeli proces trwa ponad minutę, skontaktuj się z administratorem</p>
          </>
        </Spinner>
        : <Redirect to={RoutePath.SUBSCRIPTION_SETTINGS} />}
    </>
  );
}

export default PaymentCheckout;