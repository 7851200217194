import CallbacksStateTypes from './callbacksState.types';

const INITIAL_STATE = {
  is_signUp_finished: false as boolean,
  is_signIn_finished: false as boolean,
  is_globalToastFailedToFetchActive: false as boolean
}

const callbacksStateReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CallbacksStateTypes.SIGNIN_FINISHED:
      return {
        ...state,
        is_signIn_finished: action.payload
      }
    case CallbacksStateTypes.SIGNUP_FINISHED:
      return {
        ...state,
        is_signUp_finished: action.payload
      }
    case CallbacksStateTypes.TOAST_FAILEDTOFETCH_ACTIVATED:
      return {
        ...state,
        is_globalToastFailedToFetchActive: action.payload
      }
    default: {
      return state;
    }
  }
}

export default callbacksStateReducer;