import "./couponCodeForm.scss";
import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { PaymentController } from "../../api/paymentController";
import MicroSpinner from "../spinner/microSpinner";
import { Link } from "react-router-dom";
import { RoutePath } from "../../../route-paths";

interface Props {
}

const CouponCodeForm: React.FC<Props> = () => {
  const [coupon, setCoupon] = useState("")
  const [isSpinner, setisSpinner] = useState(false);

  const handleRealizeCoupon = () => {
    setisSpinner(true);
    var subscribePromise = PaymentController.RealizeCoupon(coupon);
    subscribePromise.then(() => {
      // setIsConfigmModalOpen(false);
      setisSpinner(false);
      setCoupon('');
    }, reason => {
      setisSpinner(false);
      // ToastsStore.error("Wystąpił problem z modyfikacją");
      // console.error(reason); // Error!
    })
  }

  return (
    <div className="couponCodeForm">
      <Row>
        <Col xl={{ span: 6, offset: 3 }}>
          <Card className="inputs-group-main">
            <Card.Header>
              Modyfikuj konto
            </Card.Header>
            <Card.Body>
              <Form onSubmit={(e: any) => e.preventDefault()}>
                <Form.Label>Klucz modyfikujący</Form.Label>
                <Form.Control
                  className="form-control-alternative"
                  placeholder="Klucz modyfikujący"
                  type="text"
                  onChange={(e: any) => setCoupon(`${e.target.value ? e.target.value : ""}`)}
                  name="coupon"
                />
              </Form>
              <div className="cardFooter">
                <div className="microSpinnerWrapper">
                  <MicroSpinner isVisible={isSpinner} />
                </div>
                <Button onClick={handleRealizeCoupon} variant="secondary" className="float-right">Zrealizuj klucz</Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="backLink">
        <Link to={RoutePath.SUBSCRIPTION_SETTINGS}>&#8592; Wróć do Ustawień Subskrypcji&nbsp;&nbsp;&nbsp;</Link>
      </Row>
    </div >
  )
}

export default CouponCodeForm;