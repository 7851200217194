import React from "react";
import "./subscription-settings.scss";
import Helmet from "react-helmet";
import { Container } from "react-bootstrap";

import MainSection from "../../../../components/main-section/main-section";
import ActivePlans from "../../../../components/activePlans/active-plans";
import PrincingPlans from "../../../../components/princingPlans/princing-plans";

const SubscriptionSettings: React.FC<any> = () => {
  return (
    <>
      <Helmet>
        <title>{'Subscription | Suggestly'}</title>
      </Helmet>
      <div className="subscription-settings">
        <Container fluid={false}>
          <MainSection
            title="Ustawienia subskrypcji"
          >
          </MainSection>
          <ActivePlans />
          <PrincingPlans />
        </Container>
      </div>
    </>
  )
}

export default SubscriptionSettings;