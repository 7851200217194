

import React, { useState, useEffect } from "react";
import "./../navbar-top/navbar-top.scss";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import { useDispatch } from "react-redux";
import { deleteSuggestlyEntity } from "../../redux/suggestlyEntityState/suggestlyEntityState.actions";


interface Props {
  showModal: boolean
}

const DeleteSuggestlyEntityModal = (props: Props) => {
  const [showSuggestlyEntityModal, setShowSuggestlyEntityModal] = useState(false);
  const handleCloseSuggestlyEntityModal = () => setShowSuggestlyEntityModal(false)

  useEffect(() => {
    if (props.showModal) {
      setShowSuggestlyEntityModal(true);
    }
  }, [props.showModal])

  const dispatch = useDispatch();
  const [name, setName] = useState("")

  const handleDelete = (e: any) => {
    if (name !== "") {
      dispatch(deleteSuggestlyEntity(name));
      setShowSuggestlyEntityModal(false);
    }
  }


  return (
    <>
      <Modal show={showSuggestlyEntityModal} onHide={handleCloseSuggestlyEntityModal} size="sm" className="create-suggestly-entity-modal">
        <Modal.Header closeButton>
          <h3>Na pewno chcesz to zrobić?</h3>
        </Modal.Header>
        <Modal.Body>
          <h6><strong>Ta operacja nie może zostać cofnięta. Usuniesz ten projekt na zawsze.</strong></h6>
          <h6>Aby potwierdzić, wpisz nazwę projektu, który chcesz usunąć.</h6>
          <Form onSubmit={(e: any) => e.preventDefault()}>
            <FormGroup>
              <Form.Control
                placeholder="Nazwa projektu"
                autoFocus
                onChange={(e: any) => setName(`${e.target.value}`)}
                name="name"
                type="text"
              />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Button variant="danger" onClick={handleDelete}>
          Rozumiem konsekwencje, USUŃ
        </Button>
      </Modal>
    </>
  )
};

export default DeleteSuggestlyEntityModal;