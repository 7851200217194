import React from "react";
import { Switch, Route } from "react-router-dom";
import { RoutePath } from "../../../../route-paths";
import PytaniaPanel from "./pytania-panel/pytania-panel";
import ProduktyPanel from "./produkty-panel/produkty-panel";
import KalibracjaPanel from "./kalibracja-panel/kalibracja-panel";
import HomePanel from "./home-panel/home-panel";
import EmbedPanel from "./embed-panel/embed-panel";
import SideBar from "../../../components/sidebar/sidebar";
import NavbarTop from "../../../components/navbar-top/navbar-top";
import LeadsPanel from "./leads-panel/leads-panel";
import GlobalAlert from "../../../components/globalAlert/global-alert";
import ProjectSettingsPanel from "./projectSettings-panel/projectSettings-panel";
import { useSelector, shallowEqual } from "react-redux";
import { selectCurrentUser } from "../../../redux/user/user.selectors";
import RenameHomeScreenHelper from "./home-panel/renameHomeScreenHelper";

const PanelSwitch: React.FC = () => {

  const currentUser = useSelector(selectCurrentUser, shallowEqual);

  return (
    <>
      <NavbarTop isPanelSwitch={true} isDisabled={(currentUser && currentUser.isSignUpTutorialActive)} />
      <GlobalAlert />
      <div className="mainWrapper">
        <SideBar isDisabled={(currentUser && currentUser.isSignUpTutorialActive)} />
        <div className="pagesPanel">
          {currentUser && currentUser.isSignUpTutorialActive ? <RenameHomeScreenHelper /> :
            <Switch>
              <Route exact path={RoutePath.EMBED_PANEL} component={EmbedPanel} />
              <Route exact path={RoutePath.LEADS_PANEL} component={LeadsPanel} />
              <Route exact path={RoutePath.PYTANIA_PANEL} component={PytaniaPanel} />
              <Route exact path={RoutePath.PRODUKTY_PANEL} component={ProduktyPanel} />
              <Route exact path={RoutePath.KALIBRACJA_PANEL} component={KalibracjaPanel} />
              <Route exact path={RoutePath.PROJECTSETTINGS_PANEL} component={ProjectSettingsPanel} />
              <Route component={HomePanel} />
            </Switch>
          }
        </div>
      </div>
    </>
  );
}

export default PanelSwitch;