import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { ToastsStore } from "react-toasts";
import { ApiMethodDto, UrlParam } from "../../api/api-method-dto";
import { ApiPath } from "../../api/apiPath";
import { callApi } from "../../api/api-call-service";
import { SagaInputDto } from "../../api/saga-input-dto";
import SuggestlyEntityStateActionTypes from "./suggestlyEntityState.types";
import UserActionTypes from "../user/user.types";
import ProductsStateActionTypes from "../productState/productsState.types";
import InputsStateActionTypes from "../inputsState/inputsState.types";
import { SuggestlyStore } from "../store.types";
import { ModalDto } from "../../dto/modalDto";
import { RoutePath } from "../../../route-paths";
import WagesStateActionTypes from "../wagesState/wagesState.types";
import EmbedStateActionTypes from "../embedState/embedState.types";
import { SuggestlyEntityDto } from "../../dto/suggestlyEntityDto";

function* renameSuggestlyEntityAsync(sagaInput_payload: SagaInputDto<{ name: string, id: string }>) {
  const token = yield select(state => state.user.token);

  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: [200],
    params: [{
      name: "suggestlyEntityId",
      value: sagaInput_payload.payload.id
    }, {
      name: "name",
      value: sagaInput_payload.payload.name
    }] as Array<UrlParam>
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.suggestlyEntity_renameSuggestlyEntity, options);

    yield put({
      type: UserActionTypes.GET_ALLSUGGESTLYENTITIES
    });

    yield put({
      type: InputsStateActionTypes.GET_ALL_CATEGORIES,
      payload: res.data as string
    });

    yield put({
      type: ProductsStateActionTypes.GET_ALL_OUTPUTS,
      payload: res.data as string
    });

    yield put({
      type: SuggestlyEntityStateActionTypes.GET_CLIENTACTIONS,
    });

    yield put({
      type: SuggestlyEntityStateActionTypes.GET_CLIENTACTIONS,
    });
    
    yield put({
      type: UserActionTypes.AUTH_SIGNUPTUTORIALFINISH,
    });

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* createSuggestlyEntityAsync(sagaInput_name: SagaInputDto<string>) {
  const token = yield select(state => state.user.token);

  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: [201, 423],
    params: {
      name: "name",
      value: sagaInput_name.payload
    } as UrlParam
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.suggestlyEntity_createSuggestlyEntity, options);
    if (res.details.status === 423) {
      yield put({
        type: SuggestlyEntityStateActionTypes.SHOW_GLOBAL_MODAL_SUCCESS,
        payload: {
          title: "Przekroczono limit",
          description: "Twoje konto nie pozwala na stworzenie dodatkowych projektów. Aby zwiększyć limit, zaktualizuj swój plan cenowy.",
          buttonTitle: "Zmień plan",
          path: RoutePath.SUBSCRIPTION_SETTINGS
        } as ModalDto
      });
    } else {

      yield put({
        type: UserActionTypes.GET_ALLSUGGESTLYENTITIES
      });

      yield put({
        type: SuggestlyEntityStateActionTypes.SET_CURRENT_SUGGESTLYENTITY_ID_SUCCESS,
        payload: res.data as string
      });

      yield put({
        type: InputsStateActionTypes.GET_ALL_CATEGORIES,
        payload: res.data as string
      });

      yield put({
        type: ProductsStateActionTypes.GET_ALL_OUTPUTS,
        payload: res.data as string
      });

      yield put({
        type: SuggestlyEntityStateActionTypes.GET_CLIENTACTIONS,
      });

      ToastsStore.success('Suggestly entity created successfully');
    }
  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* getClientActionsMonthlyAsync() {
  const token = yield select(state => state.user.token);
  const suggestlyEntityId = yield select((state: SuggestlyStore) => state.suggestlyEntityState.currentEntitySuggestlyId);
  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
    params: {
      name: "suggestlyEntityId",
      value: suggestlyEntityId
    } as UrlParam
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.suggestlyEntity_getClientActionsMonthly, options);

    yield put({
      type: SuggestlyEntityStateActionTypes.GET_CLIENTACTIONS_SUCCESS,
      payload: res.data
    });

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* deleteSuggestlyEntityAsync(sagaInput_name: SagaInputDto<string>) {
  const token = yield select(state => state.user.token);
  const suggestlyEntityId = yield select((state: SuggestlyStore) => state.suggestlyEntityState.currentEntitySuggestlyId);

  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
    params: [
      {
        name: "suggestlyEntityId",
        value: suggestlyEntityId
      }, {
        name: "name",
        value: sagaInput_name.payload
      }] as Array<UrlParam>
  } as ApiMethodDto;

  try {


    yield call(callApi, "get", ApiPath.suggestlyEntity_deleteSuggestlyEntity, options);

    yield put({
      type: UserActionTypes.GET_ALLSUGGESTLYENTITIES
    });

    const suggestlyEntitiess = yield select((state: SuggestlyStore) => state.user.suggestlyEntities);
    const filteredSuggestlyEntities = (suggestlyEntitiess as Array<SuggestlyEntityDto>).filter(x => x.id !== suggestlyEntityId)

    yield put({
      type: SuggestlyEntityStateActionTypes.SET_CURRENT_SUGGESTLYENTITY_ID_SUCCESS,
      payload: filteredSuggestlyEntities && filteredSuggestlyEntities.length > 0 ? filteredSuggestlyEntities[0].id : null
    });

    yield put({
      type: ProductsStateActionTypes.GET_ALL_OUTPUTS
    });

    yield put({
      type: InputsStateActionTypes.GET_ALL_CATEGORIES
    });

    yield put({
      type: WagesStateActionTypes.GET_ALL_CATEGORIESWAGES
    });

    yield put({
      type: EmbedStateActionTypes.GET_EXTERNALDOMAINS
    });

    yield put({
      type: SuggestlyEntityStateActionTypes.GET_CLIENTACTIONS
    });

    ToastsStore.success('Suggestly entity deleted successfully');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}


function* watchRenameSuggestlyEntity() {
  yield takeLatest(SuggestlyEntityStateActionTypes.RENAME_SUGGESTLYENTITY as any, renameSuggestlyEntityAsync);
}

function* watchDeleteSuggestlyEntity() {
  yield takeLatest(SuggestlyEntityStateActionTypes.DELETE_SUGGESTLYENTITY as any, deleteSuggestlyEntityAsync);
}

function* watchCreateSuggestlyEntity() {
  yield takeLatest(SuggestlyEntityStateActionTypes.CREATE_SUGGESTLYENTITY as any, createSuggestlyEntityAsync);
}

function* watchGetClientActionsMonthly() {
  yield takeLatest(SuggestlyEntityStateActionTypes.GET_CLIENTACTIONS as any, getClientActionsMonthlyAsync);
}

export default function* SuggestlyEntityStateSagas() {
  yield all([
    call(watchRenameSuggestlyEntity),
    call(watchDeleteSuggestlyEntity),
    call(watchCreateSuggestlyEntity),
    call(watchGetClientActionsMonthly)
  ]);
}