import React, { ReactNode, useState } from "react";
import "./navbar-landing.scss";
import Navbar from "react-bootstrap/Navbar";
import LogoImg from "./../../assets/logo.svg";
import { Link } from "react-router-dom";
import { RoutePath } from "../../../route-paths";
import Container from "react-bootstrap/Container";

interface Props {
  isSubNavHidden?: boolean;
  children?: ReactNode
}

const NavbarLanding: React.FC<Props> = (props: Props) => {
  const [navExpanded, setNavExpanded] = useState(false);

  return (
    <div className="navbarLanding" >
      <Navbar expanded={navExpanded} collapseOnSelect={true} expand="lg">
        <Container>
          <div className="brand" onClick={() => window.scrollTo(0, 0)}>
            <Link to={RoutePath.HOME}>
              <img src={LogoImg} alt="LogoImg" />
            </Link>
          </div>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setNavExpanded(!navExpanded)} />
          <Navbar.Collapse id="responsive-navbar-nav" onClick={() => setNavExpanded(false)}>
            <>
              {props.isSubNavHidden ?
                null : <div className="midNav">
                  {props.children}
                </div>
              }
              <div className="actionNav">
                <div className="actionBtn">
                  <Link to={RoutePath.SIGNIN}>
                    Zaloguj się
                  </Link>
                </div>
                <div className="actionBtn actionBtn--dark">
                  <Link to={RoutePath.SIGNUP}>
                    Rejestracja
                  </Link>
                </div>
              </div>
            </>
          </Navbar.Collapse>
        </Container>
      </Navbar>


      {/* <Navbar bg="light" collapseOnSelect expand="lg">
        <Container>
          <div className="brand" onClick={() => window.scrollTo(0, 0)}>
            <Link to={RoutePath.HOME}>
              <img src={LogoImg} alt="LogoImg" />
            </Link>
          </div>

        </Container>
      </Navbar> */}
    </div>
  )
}

export default NavbarLanding;