import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { ToastsStore } from "react-toasts";
import { ApiMethodDto, UrlParam } from "../../api/api-method-dto";
import { ApiPath } from "../../api/apiPath";
import { callApi } from "../../api/api-call-service";
import { SagaInputDto } from "../../api/saga-input-dto";
import ProductsStateActionTypes from "./productsState.types";
import { SuggestlyStore } from "../store.types";
import { OutputTypeEnum } from "../../dto/outputTypeEnum";
import { OutputDto } from "../../dto/outputDto";
import { ModalDto } from "../../dto/modalDto";
import SuggestlyEntityStateActionTypes from "../suggestlyEntityState/suggestlyEntityState.types";
import { RoutePath } from "../../../route-paths";

function* addOutputAsync(sagaInput_name: SagaInputDto<{ name: string, outputType: OutputTypeEnum }>) {
  const token = yield select(state => state.user.token);
  const suggestlyEntityId = yield select((state: SuggestlyStore) => state.suggestlyEntityState.currentEntitySuggestlyId);

  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: [201, 423],
    params: [{
      name: "suggestlyEntityId",
      value: suggestlyEntityId
    }, {
      name: "name",
      value: sagaInput_name.payload.name
    }, {
      name: "outputType",
      value: sagaInput_name.payload.outputType
    }] as Array<UrlParam>
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.output_addOutput, options);
    if (res.details.status === 423) {
      yield put({
        type: SuggestlyEntityStateActionTypes.SHOW_GLOBAL_MODAL_SUCCESS,
        payload: {
          title: "Osiągnięto limit",
          description: "Aktualnie Suggestly nie umożliwia na stworzenie większej liczby możliwych sugestii dla pojedynczego projektu. Jeśli chcesz się dowiedzieć więcej, skontaktuj się z administracją.",
          buttonTitle: "Kontakt",
          path: RoutePath.CONTACT_SETTINGS
        } as ModalDto
      });
    } else {
      yield put({
        type: ProductsStateActionTypes.ADD_OUTPUT_SUCCESS,
        payload: res.data as OutputDto
      });

      ToastsStore.success('product created successfully');
    }
  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* getAllOutputsAsync() {
  const token = yield select(state => state.user.token);
  const suggestlyEntityId = yield select((state: SuggestlyStore) => state.suggestlyEntityState.currentEntitySuggestlyId);

  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
    params: {
      name: "suggestlyEntityId",
      value: suggestlyEntityId
    } as UrlParam
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.output_getAllOutputs, options);
    yield put({
      type: ProductsStateActionTypes.GET_ALL_OUTPUTS_SUCCESS,
      payload: res.data as Array<OutputDto>
    });
  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* deleteOutputAsync(sagaInput_outputId: SagaInputDto<string>) {
  const token = yield select(state => state.user.token);
  const options = {
    headers: { Authorization: token },
    method: "DELETE",
    expectedStatus: 200,
    params: {
      name: "outputId",
      value: sagaInput_outputId.payload
    } as UrlParam
  } as ApiMethodDto;

  try {
    yield call(callApi, "delete", ApiPath.output_deleteOutput, options);

    yield put({
      type: ProductsStateActionTypes.DELETE_OUTPUT_SUCCESS,
      payload: sagaInput_outputId.payload
    });

    ToastsStore.warning('deleted');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* updateProductDescriptionAsync(sagaInput_description: SagaInputDto<{ productId: string, description: string }>) {
  const token = yield select(state => state.user.token);
  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
    params: [{
      name: "productId",
      value: sagaInput_description.payload.productId
    }, {
      name: "description",
      value: sagaInput_description.payload.description
    }] as Array<UrlParam>
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.output_updateProductDescription, options);

    yield put({
      type: ProductsStateActionTypes.UPDATE_OUTPUT_SUCCESS,
      payload: res.data as OutputDto
    });

    ToastsStore.success('saved');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* updateProductImageAsync(sagaInput_image: SagaInputDto<{ productId: string, image: File }>) {
  const token = yield select(state => state.user.token);
  let formData = new FormData();

  formData.append('file', sagaInput_image.payload.image);
  formData.append('productId', sagaInput_image.payload.productId);

  const options = {
    body: formData,
    headers: { Authorization: token },
    method: "POST",
    expectedStatus: 200,
    datatype: "FormData"
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "post", ApiPath.output_updateProductImage, options);

    yield put({
      type: ProductsStateActionTypes.UPDATE_OUTPUT_SUCCESS,
      payload: res.data as OutputDto
    });

    ToastsStore.success('saved');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* updateProductPriceAsync(sagaInput_price: SagaInputDto<{ productId: string, price: string }>) {
  const token = yield select(state => state.user.token);
  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
    params: [{
      name: "productId",
      value: sagaInput_price.payload.productId
    }, {
      name: "price",
      value: sagaInput_price.payload.price
    }] as Array<UrlParam>
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.output_updateProductPrice, options);

    yield put({
      type: ProductsStateActionTypes.UPDATE_OUTPUT_SUCCESS,
      payload: res.data as OutputDto
    });

    ToastsStore.success('saved');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* updateProductReffLinkAsync(sagaInput_reffLink: SagaInputDto<{ productId: string, reffLink: string }>) {
  const token = yield select(state => state.user.token);
  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
    params: [{
      name: "productId",
      value: sagaInput_reffLink.payload.productId
    }, {
      name: "reffLink",
      value: sagaInput_reffLink.payload.reffLink
    }] as Array<UrlParam>
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.output_updateProductReffLink, options);

    yield put({
      type: ProductsStateActionTypes.UPDATE_OUTPUT_SUCCESS,
      payload: res.data as OutputDto
    });

    ToastsStore.success('saved');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* updateProductTitleAsync(sagaInput_title: SagaInputDto<{ productId: string, title: string }>) {
  const token = yield select(state => state.user.token);
  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
    params: [{
      name: "productId",
      value: sagaInput_title.payload.productId
    }, {
      name: "title",
      value: sagaInput_title.payload.title
    }] as Array<UrlParam>
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.output_updateProductTitle, options);

    yield put({
      type: ProductsStateActionTypes.UPDATE_OUTPUT_SUCCESS,
      payload: res.data as OutputDto
    });

    ToastsStore.success('saved');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* updateProductSubtitleAsync(sagaInput_title: SagaInputDto<{ productId: string, subtitle: string }>) {
  const token = yield select(state => state.user.token);
  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
    params: [{
      name: "productId",
      value: sagaInput_title.payload.productId
    }, {
      name: "subtitle",
      value: sagaInput_title.payload.subtitle
    }] as Array<UrlParam>
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.output_updateProductSubtitle, options);

    yield put({
      type: ProductsStateActionTypes.UPDATE_OUTPUT_SUCCESS,
      payload: res.data as OutputDto
    });

    ToastsStore.success('saved');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* updateProductHeaderAsync(sagaInput_title: SagaInputDto<{ productId: string, productHeader: string }>) {
  const token = yield select(state => state.user.token);
  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
    params: [{
      name: "productId",
      value: sagaInput_title.payload.productId
    }, {
      name: "productHeader",
      value: sagaInput_title.payload.productHeader
    }] as Array<UrlParam>
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.output_updateProductHeader, options);

    yield put({
      type: ProductsStateActionTypes.UPDATE_OUTPUT_SUCCESS,
      payload: res.data as OutputDto
    });

    ToastsStore.success('saved');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* updateProductActionButtonLabelAsync(sagaInput_title: SagaInputDto<{ productId: string, actionButtonLabel: string }>) {
  const token = yield select(state => state.user.token);
  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
    params: [{
      name: "productId",
      value: sagaInput_title.payload.productId
    }, {
      name: "actionButtonLabel",
      value: sagaInput_title.payload.actionButtonLabel
    }] as Array<UrlParam>
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.output_updateProductActionButtonLabel, options);

    yield put({
      type: ProductsStateActionTypes.UPDATE_OUTPUT_SUCCESS,
      payload: res.data as OutputDto
    });

    ToastsStore.success('saved');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* updateOutputNameAsync(sagaInput_name: SagaInputDto<{ outputId: string, name: string }>) {
  const token = yield select(state => state.user.token);
  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
    params: [{
      name: "outputId",
      value: sagaInput_name.payload.outputId
    }, {
      name: "name",
      value: sagaInput_name.payload.name
    }] as Array<UrlParam>
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.output_updateOutputName, options);

    yield put({
      type: ProductsStateActionTypes.UPDATE_OUTPUT_SUCCESS,
      payload: res.data as OutputDto
    });

    ToastsStore.success('saved');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* updateMaillistgrowthoutputTitleAsync(sagaInput_name: SagaInputDto<{ mailListGrowthOutputId: string, title: string }>) {
  const token = yield select(state => state.user.token);
  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
    params: [{
      name: "mailListGrowthOutputId",
      value: sagaInput_name.payload.mailListGrowthOutputId
    }, {
      name: "title",
      value: sagaInput_name.payload.title
    }] as Array<UrlParam>
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.output_updateMaillistgrowthoutputTitle, options);

    yield put({
      type: ProductsStateActionTypes.UPDATE_OUTPUT_SUCCESS,
      payload: res.data as OutputDto
    });

    ToastsStore.success('saved');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* updateMaillistgrowthoutputDescriptionAsync(sagaInput_name: SagaInputDto<{ mailListGrowthOutputId: string, description: string }>) {
  const token = yield select(state => state.user.token);
  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
    params: [{
      name: "mailListGrowthOutputId",
      value: sagaInput_name.payload.mailListGrowthOutputId
    }, {
      name: "description",
      value: sagaInput_name.payload.description
    }] as Array<UrlParam>
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.output_updateMaillistgrowthoutputDescription, options);

    yield put({
      type: ProductsStateActionTypes.UPDATE_OUTPUT_SUCCESS,
      payload: res.data as OutputDto
    });

    ToastsStore.success('saved');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}
function* updateMaillistgrowthoutputHeaderAsync(sagaInput_name: SagaInputDto<{ mailListGrowthOutputId: string, header: string }>) {
  const token = yield select(state => state.user.token);
  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
    params: [{
      name: "mailListGrowthOutputId",
      value: sagaInput_name.payload.mailListGrowthOutputId
    }, {
      name: "header",
      value: sagaInput_name.payload.header
    }] as Array<UrlParam>
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.output_updateMaillistgrowthoutputHeader, options);

    yield put({
      type: ProductsStateActionTypes.UPDATE_OUTPUT_SUCCESS,
      payload: res.data as OutputDto
    });

    ToastsStore.success('saved');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* updateMaillistgrowthoutputAcceptLabelAsync(sagaInput_name: SagaInputDto<{ mailListGrowthOutputId: string, acceptLabel: string }>) {

  const token = yield select(state => state.user.token);
  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
    params: [{
      name: "mailListGrowthOutputId",
      value: sagaInput_name.payload.mailListGrowthOutputId
    }, {
      name: "acceptLabel",
      value: sagaInput_name.payload.acceptLabel
    }] as Array<UrlParam>
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.output_updateMaillistgrowthoutputAcceptLabel, options);

    yield put({
      type: ProductsStateActionTypes.UPDATE_OUTPUT_SUCCESS,
      payload: res.data as OutputDto
    });

    ToastsStore.success('saved');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* updateMaillistgrowthoutputMessageAsync(sagaInput_name: SagaInputDto<{ mailListGrowthOutputId: string, message: string }>) {
  const token = yield select(state => state.user.token);
  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
    params: [{
      name: "mailListGrowthOutputId",
      value: sagaInput_name.payload.mailListGrowthOutputId
    }, {
      name: "message",
      value: sagaInput_name.payload.message
    }] as Array<UrlParam>
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.output_updateMaillistgrowthoutputMessage, options);

    yield put({
      type: ProductsStateActionTypes.UPDATE_OUTPUT_SUCCESS,
      payload: res.data as OutputDto
    });

    ToastsStore.success('saved');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* updateLeadmagnetoutputTitleAsync(sagaInput_name: SagaInputDto<{ leadMagnetOutputId: string, title: string }>) {
  const token = yield select(state => state.user.token);
  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
    params: [{
      name: "leadMagnetOutputId",
      value: sagaInput_name.payload.leadMagnetOutputId
    }, {
      name: "title",
      value: sagaInput_name.payload.title
    }] as Array<UrlParam>
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.output_updateLeadmagnetoutputTitle, options);

    yield put({
      type: ProductsStateActionTypes.UPDATE_OUTPUT_SUCCESS,
      payload: res.data as OutputDto
    });

    ToastsStore.success('saved');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* updateLeadmagnetoutputDescriptionAsync(sagaInput_name: SagaInputDto<{ leadMagnetOutputId: string, description: string }>) {
  const token = yield select(state => state.user.token);
  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
    params: [{
      name: "leadMagnetOutputId",
      value: sagaInput_name.payload.leadMagnetOutputId
    }, {
      name: "description",
      value: sagaInput_name.payload.description
    }] as Array<UrlParam>
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.output_updateLeadmagnetoutputDescription, options);

    yield put({
      type: ProductsStateActionTypes.UPDATE_OUTPUT_SUCCESS,
      payload: res.data as OutputDto
    });

    ToastsStore.success('saved');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* updateLeadmagnetoutputMessageAsync(sagaInput_name: SagaInputDto<{ leadMagnetOutputId: string, message: string }>) {
  const token = yield select(state => state.user.token);
  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
    params: [{
      name: "leadMagnetOutputId",
      value: sagaInput_name.payload.leadMagnetOutputId
    }, {
      name: "message",
      value: sagaInput_name.payload.message
    }] as Array<UrlParam>
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.output_updateLeadmagnetoutputMessage, options);

    yield put({
      type: ProductsStateActionTypes.UPDATE_OUTPUT_SUCCESS,
      payload: res.data as OutputDto
    });

    ToastsStore.success('saved');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* updateLeadmagnetoutputHeaderAsync(sagaInput_name: SagaInputDto<{ leadMagnetOutputId: string, header: string }>) {
  const token = yield select(state => state.user.token);
  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
    params: [{
      name: "leadMagnetOutputId",
      value: sagaInput_name.payload.leadMagnetOutputId
    }, {
      name: "header",
      value: sagaInput_name.payload.header
    }] as Array<UrlParam>
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.output_updateLeadmagnetoutputHeader, options);

    yield put({
      type: ProductsStateActionTypes.UPDATE_OUTPUT_SUCCESS,
      payload: res.data as OutputDto
    });

    ToastsStore.success('saved');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* updateLeadmagnetoutputAcceptLabelAsync(sagaInput_name: SagaInputDto<{ leadMagnetOutputId: string, acceptLabel: string }>) {
  const token = yield select(state => state.user.token);
  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
    params: [{
      name: "leadMagnetOutputId",
      value: sagaInput_name.payload.leadMagnetOutputId
    }, {
      name: "acceptLabel",
      value: sagaInput_name.payload.acceptLabel
    }] as Array<UrlParam>
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.output_updateLeadmagnetoutputAcceptLabel, options);

    yield put({
      type: ProductsStateActionTypes.UPDATE_OUTPUT_SUCCESS,
      payload: res.data as OutputDto
    });

    ToastsStore.success('saved');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* watchUpdateProductDescription() {
  yield takeLatest(ProductsStateActionTypes.UPDATE_PRODUCTDESCRIPTION as any, updateProductDescriptionAsync);
}

function* watchUpdateProductImage() {
  yield takeLatest(ProductsStateActionTypes.UPDATE_PRODUCTIMAGE as any, updateProductImageAsync);
}

function* watchUpdateProductTitle() {
  yield takeLatest(ProductsStateActionTypes.UPDATE_PRODUCTTITLE as any, updateProductTitleAsync);
}

function* watchUpdateProductSubtitle() {
  yield takeLatest(ProductsStateActionTypes.UPDATE_PRODUCTTITLESUBTITLE as any, updateProductSubtitleAsync);
}

function* watchUpdateProductHeader() {
  yield takeLatest(ProductsStateActionTypes.UPDATE_PRODUCTHEADER as any, updateProductHeaderAsync);
}

function* watchUpdateProductActionButtonLabel() {
  yield takeLatest(ProductsStateActionTypes.UPDATE_PRODUCTACTIONBUTTONLABEL as any, updateProductActionButtonLabelAsync);
}

function* watchUpdateProductReffLink() {
  yield takeLatest(ProductsStateActionTypes.UPDATE_PRODUCTREFFLINK as any, updateProductReffLinkAsync);
}

function* watchUpdateProductPrice() {
  yield takeLatest(ProductsStateActionTypes.UPDATE_PRODUCTPRICE as any, updateProductPriceAsync);
}

function* watchUpdateOutputName() {
  yield takeLatest(ProductsStateActionTypes.UPDATE_OUTPUTNAME as any, updateOutputNameAsync);
}

function* watchAddOutput() {
  yield takeLatest(ProductsStateActionTypes.ADD_OUTPUT as any, addOutputAsync);
}

function* watchDeleteOutput() {
  yield takeLatest(ProductsStateActionTypes.DELETE_OUTPUT as any, deleteOutputAsync);
}

function* watchGetAllOutputs() {
  yield takeLatest(ProductsStateActionTypes.GET_ALL_OUTPUTS as any, getAllOutputsAsync);
}

function* watchUpdateLeadmagnetoutputTitle() {
  yield takeLatest(ProductsStateActionTypes.UPDATE_LEADMAGNETOUTPUT_TITLE as any, updateLeadmagnetoutputTitleAsync);
}

function* watchUpdateLeadmagnetoutputDescription() {
  yield takeLatest(ProductsStateActionTypes.UPDATE_LEADMAGNETOUTPUT_DESCRIPTION as any, updateLeadmagnetoutputDescriptionAsync);
}

function* watchUpdateLeadmagnetoutputMessage() {
  yield takeLatest(ProductsStateActionTypes.UPDATE_LEADMAGNETOUTPUT_MESSAGE as any, updateLeadmagnetoutputMessageAsync);
}

function* watchUpdateLeadmagnetoutputHeader() {
  yield takeLatest(ProductsStateActionTypes.UPDATE_LEADMAGNETOUTPUT_HEADER as any, updateLeadmagnetoutputHeaderAsync);
}

function* watchUpdateLeadmagnetoutputAcceptLabel() {
  yield takeLatest(ProductsStateActionTypes.UPDATE_LEADMAGNETOUTPUT_ACCEPTLABEL as any, updateLeadmagnetoutputAcceptLabelAsync);
}

function* watchUpdateMaillistgrowthoutputTitle() {
  yield takeLatest(ProductsStateActionTypes.UPDATE_MAILLISTGROWTHOUTPUT_TITLE as any, updateMaillistgrowthoutputTitleAsync);
}

function* watchUpdateMaillistgrowthoutputDescription() {
  yield takeLatest(ProductsStateActionTypes.UPDATE_MAILLISTGROWTHOUTPUT_DESCRIPTION as any, updateMaillistgrowthoutputDescriptionAsync);
}

function* watchUpdateMaillistgrowthoutputMessage() {
  yield takeLatest(ProductsStateActionTypes.UPDATE_MAILLISTGROWTHOUTPUT_MESSAGE as any, updateMaillistgrowthoutputMessageAsync);
}

function* watchUpdateMaillistgrowthoutputHeader() {
  yield takeLatest(ProductsStateActionTypes.UPDATE_MAILLISTGROWTHOUTPUT_HEADER as any, updateMaillistgrowthoutputHeaderAsync);
}

function* watchUpdateMaillistgrowthoutputAcceptLabel() {
  yield takeLatest(ProductsStateActionTypes.UPDATE_MAILLISTGROWTHOUTPUT_ACCEPTLABEL as any, updateMaillistgrowthoutputAcceptLabelAsync);
}

export default function* ProductsStateSagas() {
  yield all([
    call(watchAddOutput),
    call(watchUpdateProductReffLink),
    call(watchUpdateProductPrice),
    call(watchDeleteOutput),
    call(watchGetAllOutputs),
    call(watchUpdateOutputName),
    call(watchUpdateProductSubtitle),
    call(watchUpdateProductTitle),
    call(watchUpdateProductHeader),
    call(watchUpdateProductActionButtonLabel),

    call(watchUpdateProductImage),
    call(watchUpdateProductDescription),
    call(watchUpdateLeadmagnetoutputTitle),
    call(watchUpdateLeadmagnetoutputDescription),
    call(watchUpdateLeadmagnetoutputMessage),
    call(watchUpdateLeadmagnetoutputHeader),
    call(watchUpdateLeadmagnetoutputAcceptLabel),

    call(watchUpdateMaillistgrowthoutputTitle),
    call(watchUpdateMaillistgrowthoutputDescription),
    call(watchUpdateMaillistgrowthoutputMessage),
    call(watchUpdateMaillistgrowthoutputHeader),
    call(watchUpdateMaillistgrowthoutputAcceptLabel)
  ]);
}