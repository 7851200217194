import React, { useRef, useState, useEffect } from "react";
import "./speedo-meter-selector.scss";
import { WageDto } from "../../dto/wageDto";
import { updateWage } from "../../redux/wagesState/wagesState.actions";
import { useDispatch } from "react-redux";

interface Props {
  wage: WageDto;
}

const SpeedoMeterSelector: React.FC<Props> = (props: Props) => {
  let mouseDown = false;
  let staticDeg = (props.wage.value - 1) * 82;

  const [degState, setDeg] = useState((props.wage.value - 1) * 82)
  const logoRef = useRef<any>();
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener('mousedown', handleMouseDown);

    return () => {
      window.removeEventListener('touchmove', handleMouseMove);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('mousedown', handleMouseDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleMouseMove = (e: any) => {
    if (mouseDown) {
      const refWidth = logoRef.current!.clientWidth;
      const refHeight = logoRef.current!.clientHeight;
      const refStartX = logoRef.current!.getBoundingClientRect().left;
      const refStartY = logoRef.current!.getBoundingClientRect().top;

      const realWPosition = (e.clientX - refStartX) / refWidth;
      const realHPosition = (e.clientY - refStartY) / refHeight;
      let angleDeg = 82 * (-Math.atan2(-((realWPosition * 2) - 1), -((realHPosition * 2) - 1)) / 2.28);

      let correctDeg = angleDeg;
      if (angleDeg > 82) {
        correctDeg = 82
      }
      else if (angleDeg < -82) {
        correctDeg = -82
      }
      setDeg(correctDeg);
      staticDeg = correctDeg;
    }
  }

  const handleMouseDown = (e: any) => {
    if (e.target.id === 'needlee') {
      mouseDown = true;

      window.addEventListener('touchmove', handleMouseMove);
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
      window.removeEventListener('mousedown', handleMouseDown);

    }
    else if (e.target.id === 'speedometerr') {
      mouseDown = true;
      handleMouseMove(e);
      mouseDown = false;
      let newDeg = ((staticDeg / 82) + 1).toFixed(1);
      dispatch(updateWage(props.wage.id, Number(newDeg)))
    }
    else {
      mouseDown = false;
    }
  }

  const handleMouseUp = (e: any) => {
    handleMouseMove(e)
    window.removeEventListener('touchmove', handleMouseMove);
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);
    window.addEventListener('mousedown', handleMouseDown);

    mouseDown = false;
    let newDeg = ((staticDeg / 82) + 1).toFixed(1);
    dispatch(updateWage(props.wage.id, Number(newDeg)))
  }
  return (
    <>
      <div className="speedo-meter-selector"
        id="dotbuttonn"
      >
        <div className="logoo" id="logoo"
          ref={logoRef}
        >
          <span
            id="speedometerr"
            className="speedometerr">
            <span id="speedometerrr" className="speedometerrr"></span>
          </span>
          <span id="needle" className="needle" style={{ transform: `rotate(${degState}deg)` }}>
            <span
              id="needlee"
              className="needlee"
            />
          </span>
        </div>
      </div>
      <div className={"speedometerTitle"}>
        {((degState / 82) + 1).toFixed(1)}
      </div>
    </>
  )
}

export default SpeedoMeterSelector;