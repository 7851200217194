import React, { useState } from "react";
// import "./input-item.scss";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { sendMessageToSupport } from "../../redux/accountSettingsState/accountSettingsState.actions";
import { ContactFormDto } from "../../dto/contactFormDto";

const ContactForm: React.FC = () => {
  const [topic, setTopic] = useState("")
  const [message, setMessage] = useState("")

  const dispatch = useDispatch();

  const handleSendEmail = () => {
    dispatch(sendMessageToSupport({ topic: topic, message: message } as ContactFormDto));
  }

  return (
    <div className="input-item">
      <Row>
        <Col xl={{ span: 6, offset: 3 }}>
          <Card className="inputs-group-main">
          <Card.Header>
              Formularz kontaktowy
            </Card.Header>
            <Card.Body>
              <Form onSubmit={(e: any) => e.preventDefault()}>
                <Form.Label>Temat</Form.Label>
                <Form.Control
                  className="form-control-alternative"
                  placeholder="Temat wiadomości"
                  type="text"
                  onChange={(e: any) => setTopic(`${e.target.value ? e.target.value : ""}`)}
                  name="topic"
                />
                <Form.Label>Wiadomość</Form.Label>
                <Form.Control
                  className="form-control-alternative"
                  placeholder="Treść wiadomości ..."
                  as="textarea"
                  rows={6}
                  onChange={(e: any) => setMessage(`${e.target.value ? e.target.value : ""}`)}
                  name="message"
                />
                <Button onClick={handleSendEmail} className="float-right">Wyślij wiadomość</Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default ContactForm;