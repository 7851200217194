import React, { useState } from "react";
import "./input-item.scss";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "react-bootstrap/FormGroup";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useDispatch } from "react-redux";
import { addInput } from "../../redux/inputsState/inputsState.actions";
import Button from "react-bootstrap/Button";
interface Props {
  index: number;
}

const InputItemCreator: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("")

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' && name !== "") {
      dispatch(addInput(name));
    }
  }

  const handleCreate = () => {
    if (name !== "") {
      dispatch(addInput(name));
    }
  }

  return (
    <div className="input-item">
      <Card>
        <Card.Header>
          <Row>
            <Col>
              <Form onSubmit={(e: any) => e.preventDefault()}>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroup.Prepend>
                      <InputGroup.Text>{props.index}. </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      className="form-control-alternative"
                      placeholder="Nazwa pytania (dowolna, tylko Ty ją widzisz)"
                      autoFocus
                      onChange={(e: any) => setName(`${e.target.value}`)}
                      name="name"
                      type="text"
                      onKeyDown={handleKeyDown}
                    />
                  </InputGroup>
                </FormGroup>
              </Form>
            </Col>
            <Col style={{ textAlign: 'right' }}>
              <Button onClick={handleCreate} >Dalej</Button>
            </Col>
          </Row>
        </Card.Header>
      </Card>
    </div>
  )
}

export default InputItemCreator;