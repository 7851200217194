import React, { useEffect, useState } from "react";
import "./confirmEmail-page.scss";
import { GetParamsHelper } from "../../tools/PathParamsHelper";
import { PublicController } from "../../api/publicController";

const ConfirmEmailPage: React.FC<any> = () => {
  const [confirmation, setConfirmation] = useState(0)
  useEffect(() => {
    let params = GetParamsHelper();
    let leadId = params.find((x) => x.name === 'leadId')!.value;
    try {
      let promise = PublicController.confirmEmail(leadId)
      promise.then((isConfirmed: boolean) => {
        if (isConfirmed) {
          setConfirmation(1);
        }
        else {
          setConfirmation(2);
        }
      })
    } catch (error) {
      setConfirmation(2);
    }

  }, [])

  return (
    <div className="confirmEmail-page">
      <div>
        {confirmation === 0 ? <h3>Weryfikacja w toku...</h3> : null}
        {confirmation === 1 ? <h3>Twój adres email został potwierdzony.</h3> : null}
        {confirmation === 2 ? <h3>Weryfikacja nie przebiegła pomyślnie, spróbuj ponownie później.</h3> : null}
      </div>
    </div>
  )
}

export default ConfirmEmailPage;