import ProductsStateTypes from "./productsState.types";
import { OutputTypeEnum } from "../../dto/outputTypeEnum";

export const addOutput = (name: string, outputType: OutputTypeEnum) => {
  return {
    type: ProductsStateTypes.ADD_OUTPUT,
    payload: { name: name, outputType: outputType }
  };
};

export const updateProductHeader = (productId: string, productHeader: string) => {
  return {
    type: ProductsStateTypes.UPDATE_PRODUCTHEADER,
    payload: { productId: productId, productHeader: productHeader }
  };
};

export const updateProductActionButtonLabel = (productId: string, actionButtonLabel: string) => {
  return {
    type: ProductsStateTypes.UPDATE_PRODUCTACTIONBUTTONLABEL,
    payload: { productId: productId, actionButtonLabel: actionButtonLabel }
  };
};

export const updateLeadmagnetoutputHeader = (leadMagnetOutputId: string, header: string) => {
  return {
    type: ProductsStateTypes.UPDATE_LEADMAGNETOUTPUT_HEADER,
    payload: { leadMagnetOutputId: leadMagnetOutputId, header: header }
  };
};

export const updateLeadmagnetoutputAcceptlabel = (leadMagnetOutputId: string, acceptLabel: string) => {
  return {
    type: ProductsStateTypes.UPDATE_LEADMAGNETOUTPUT_ACCEPTLABEL,
    payload: { leadMagnetOutputId: leadMagnetOutputId, acceptLabel: acceptLabel }
  };
};

export const updateMaillistgrowthoutputHeader = (mailListGrowthOutputId: string, header: string) => {
  return {
    type: ProductsStateTypes.UPDATE_MAILLISTGROWTHOUTPUT_HEADER,
    payload: { mailListGrowthOutputId: mailListGrowthOutputId, header: header }
  };
};

export const updateMaillistgrowthoutputAcceptlabel = (mailListGrowthOutputId: string, acceptLabel: string) => {
  return {
    type: ProductsStateTypes.UPDATE_MAILLISTGROWTHOUTPUT_ACCEPTLABEL,
    payload: { mailListGrowthOutputId: mailListGrowthOutputId, acceptLabel: acceptLabel }
  };
};

export const updateProductPrice = (productId: string, price: string) => {
  return {
    type: ProductsStateTypes.UPDATE_PRODUCTPRICE,
    payload: { productId: productId, price: price }
  };
};

export const updateProductReffLink = (productId: string, reffLink: string) => {
  return {
    type: ProductsStateTypes.UPDATE_PRODUCTREFFLINK,
    payload: { productId: productId, reffLink: reffLink }
  };
};

export const updateProductImage = (productId: string, image: File | null) => {
  return {
    type: ProductsStateTypes.UPDATE_PRODUCTIMAGE,
    payload: { productId: productId, image: image }
  };
};

export const updateProductDescription = (productId: string, description: string) => {
  return {
    type: ProductsStateTypes.UPDATE_PRODUCTDESCRIPTION,
    payload: { productId: productId, description: description }
  };
};

export const updateProductTitle = (productId: string, title: string) => {
  return {
    type: ProductsStateTypes.UPDATE_PRODUCTTITLE,
    payload: { productId: productId, title: title }
  };
};

export const updateProductSubtitle = (productId: string, subtitle: string) => {
  return {
    type: ProductsStateTypes.UPDATE_PRODUCTTITLESUBTITLE,
    payload: { productId: productId, subtitle: subtitle }
  };
};

export const updateOutputName = (outputId: string, name: string) => {
  return {
    type: ProductsStateTypes.UPDATE_OUTPUTNAME,
    payload: { outputId: outputId, name: name }
  };
};

export const deleteOutput = (outputId: string) => {
  return {
    type: ProductsStateTypes.DELETE_OUTPUT,
    payload: outputId
  };
};

export const getAllOutputs = () => {
  return {
    type: ProductsStateTypes.GET_ALL_OUTPUTS
  };
};

export const updateLeadMagnetOutputTitle = (leadMagnetOutputId: string, title: string) => {
  return {
    type: ProductsStateTypes.UPDATE_LEADMAGNETOUTPUT_TITLE,
    payload: { leadMagnetOutputId: leadMagnetOutputId, title: title }
  };
};

export const updateLeadMagnetOutputDescription = (leadMagnetOutputId: string, description: string) => {
  return {
    type: ProductsStateTypes.UPDATE_LEADMAGNETOUTPUT_DESCRIPTION,
    payload: { leadMagnetOutputId: leadMagnetOutputId, description: description }
  };
};

export const updateLeadMagnetOutputMessage = (leadMagnetOutputId: string, message: string) => {
  return {
    type: ProductsStateTypes.UPDATE_LEADMAGNETOUTPUT_MESSAGE,
    payload: { leadMagnetOutputId: leadMagnetOutputId, message: message }
  };
};


export const updateMailListGrowthOutputTitle = (mailListGrowthOutputId: string, title: string) => {
  return {
    type: ProductsStateTypes.UPDATE_MAILLISTGROWTHOUTPUT_TITLE,
    payload: { mailListGrowthOutputId: mailListGrowthOutputId, title: title }
  };
};

export const updateMailListGrowthOutputDescription = (mailListGrowthOutputId: string, description: string) => {
  return {
    type: ProductsStateTypes.UPDATE_MAILLISTGROWTHOUTPUT_DESCRIPTION,
    payload: { mailListGrowthOutputId: mailListGrowthOutputId, description: description }
  };
};

export const updateMailListGrowthOutputMessage = (mailListGrowthOutputId: string, message: string) => {
  return {
    type: ProductsStateTypes.UPDATE_MAILLISTGROWTHOUTPUT_MESSAGE,
    payload: { mailListGrowthOutputId: mailListGrowthOutputId, message: message }
  };
};