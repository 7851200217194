const ProductsStateActionTypes = {
  ADD_OUTPUT: 'ADD_OUTPUT',
  ADD_OUTPUT_SUCCESS: 'ADD_OUTPUT_SUCCESS',

  DELETE_OUTPUT: 'DELETE_OUTPUT',
  DELETE_OUTPUT_SUCCESS: 'DELETE_OUTPUT_SUCCESS',

  UPDATE_OUTPUTNAME: 'UPDATE_OUTPUTNAME',

  GET_ALL_OUTPUTS: 'GET_ALL_OUTPUTS',
  GET_ALL_OUTPUTS_SUCCESS: 'GET_ALL_OUTPUTS_SUCCESS',

  UPDATE_PRODUCTHEADER: 'UPDATE_PRODUCTHEADER',
  UPDATE_PRODUCTTITLE: 'UPDATE_PRODUCTTITLE',
  UPDATE_PRODUCTTITLESUBTITLE: 'UPDATE_PRODUCTTITLESUBTITLE',
  UPDATE_PRODUCTDESCRIPTION: 'UPDATE_PRODUCTDESCRIPTION',
  UPDATE_PRODUCTIMAGE: 'UPDATE_PRODUCTIMAGE',
  UPDATE_PRODUCTREFFLINK: 'UPDATE_PRODUCTREFFLINK',
  UPDATE_PRODUCTPRICE: 'UPDATE_PRODUCTPRICE',
  UPDATE_PRODUCTACTIONBUTTONLABEL: 'UPDATE_PRODUCTACTIONBUTTONLABEL',

  UPDATE_LEADMAGNETOUTPUT_HEADER: 'UPDATE_LEADMAGNETOUTPUT_HEADER',
  UPDATE_LEADMAGNETOUTPUT_TITLE: 'UPDATE_LEADMAGNETOUTPUT_TITLE',
  UPDATE_LEADMAGNETOUTPUT_DESCRIPTION: 'UPDATE_LEADMAGNETOUTPUT_DESCRIPTION',
  UPDATE_LEADMAGNETOUTPUT_MESSAGE: 'UPDATE_LEADMAGNETOUTPUT_MESSAGE',
  UPDATE_LEADMAGNETOUTPUT_ACCEPTLABEL: 'UPDATE_LEADMAGNETOUTPUT_ACCEPTLABEL',

  UPDATE_MAILLISTGROWTHOUTPUT_HEADER: 'UPDATE_MAILLISTGROWTHOUTPUT_HEADER',
  UPDATE_MAILLISTGROWTHOUTPUT_TITLE: 'UPDATE_MAILLISTGROWTHOUTPUT_TITLE',
  UPDATE_MAILLISTGROWTHOUTPUT_DESCRIPTION: 'UPDATE_MAILLISTGROWTHOUTPUT_DESCRIPTION',
  UPDATE_MAILLISTGROWTHOUTPUT_MESSAGE: 'UPDATE_MAILLISTGROWTHOUTPUT_MESSAGE',
  UPDATE_MAILLISTGROWTHOUTPUT_ACCEPTLABEL: 'UPDATE_MAILLISTGROWTHOUTPUT_ACCEPTLABEL',

  UPDATE_OUTPUT_SUCCESS: 'UPDATE_OUTPUT_SUCCESS',

  PRODUCTS_CLEAR_STATE: 'PRODUCTS_CLEAR_STATE'
};

export default ProductsStateActionTypes;