import React from "react";
import "./product-item.scss";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MagnetLead from "./magnetLead.svg";

interface Props {
  isActive: boolean
}

const OutputTypeCardMagnetLead: React.FC<Props> = (props: Props) => {
  return (
    <Card className={props.isActive ? "outputTypeScenario active" : "outputTypeScenario"}>
      <Row>
        <Col xl={{ span: 6 }}>
          <h5>Popraw swój Lead Magnet</h5>
          <Row>
            <Col xl={{ span: 4 }} className="align-self-center">
              <img src={MagnetLead} alt="" className="scenarioImg" />
            </Col>
            <Col xl={{ span: 8 }} className="scenarioDescription align-self-center">
              Wskaż właściwy produkt, a następnie zaprezentuj odnoszący się do niego magnet lead. Użytkownik zostanie poproszony o email, na który automatycznie zostanie wysłana wiadomość.
            </Col>
          </Row>
        </Col>
        <Col xl={{ span: 5, offset: 1 }} className="scenarioOptions align-self-center">
          <Row>
            <Col xl={{ span: 5 }} className="scenarioOption align-self-center">
              <div>Produkt</div>
            </Col>
            <Col xl={{ span: 2 }} className="scenarioIcon">
              <i className="fa fa-plus" aria-hidden="true"></i>
            </Col>
            <Col xl={{ span: 5 }} className="scenarioOption colorful">
              <div>Formularz email</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}

export default OutputTypeCardMagnetLead;