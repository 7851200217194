import React, { useState, useEffect } from "react";
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import "./sign-upin-form.scss";
import { Link } from "react-router-dom";
import { RoutePath } from "../../../route-paths";
import Button from "react-bootstrap/Button";
import { SignUpDto } from "../../dto/sign-up-dto";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { signUp } from "../../redux/user/user.actions";
import Form from "react-bootstrap/Form";
import { NewsletterSettingsDto } from "../../dto/newsletterSettingsDto";
import Switch from "../switch/switch";
import { selectSignUpEmail } from "../../redux/landingPageState/landingPageState.selectors";
import { setSignUpEmail } from "../../redux/landingPageState/landingPageState.actions";
import { selectIsSignUp_Finished } from "../../redux/callbacksState/callbacksState.selectors";

const SignUpForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isNotifications, setIsNotifications] = useState(false);
  const [disableButton, setdisableButton] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser, shallowEqual);
  const signUpEmail = useSelector(selectSignUpEmail, shallowEqual);
  const isSignUpFinished = useSelector(selectIsSignUp_Finished, shallowEqual);

  const handleSubmit = async (e: any) => {
    setdisableButton(true);
    let signUpDto = {
      email: email, password: password,
      newsletterSettings: {
        aboutUpdates: isNotifications,
        sendInformationsAboutCodeeboTeamOtherProjects: isNotifications
      } as NewsletterSettingsDto
    } as SignUpDto
    dispatch(signUp(signUpDto));
  };

  useEffect(() => {
    if (isSignUpFinished === true) {
      setdisableButton(false);
    }
  }, [isSignUpFinished])

  useEffect(() => {
    if (signUpEmail !== '') {
      setEmail(signUpEmail);
      setSignUpEmail('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <form className="sign-upin-form sign-up-email-form" onSubmit={(e: React.FormEvent<HTMLFormElement>) => e.preventDefault()} >
      <Form.Label>Email</Form.Label>
      <Form.Control
        name="email"
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setEmail(e.target.value)}
        required
      />
      <Form.Label>Hasło</Form.Label>
      <Form.Control
        name="password"
        type="password"
        placeholder="Hasło"
        value={password}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setPassword(e.target.value)}
        required
      />
      <div className="notificationsBlock">
        <p>
          Chcę otrzymywać notyfikacje email
        </p>
        <Form.Label >
          <Switch
            inText
            checked={isNotifications}
            onChange={() => setIsNotifications(!isNotifications)}
          >
          </Switch>
        </Form.Label>
      </div>
      <p>Klikając zarejestruj oznajmiasz, że przeczytałeś regulamin i akceptujesz naszą <Link to={RoutePath.RULES}>politykę prywatności</Link></p>
      <div className="btnWrapper">
        <Button variant="secondary" onClick={handleSubmit} disabled={((user && user.token) || disableButton) as boolean}>Zarejestruj</Button>
      </div>
    </form >
  )
}

export default SignUpForm;