import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import cookiePolicyPopupReducer from './cookiePolicyPopup/cookiePolicyPopup.reducer';
import inputsStateReducer from './inputsState/inputsState.reducer';

import userReducer from './user/user.reducer';
import productsStateReducer from './productState/productsState.reducer';
import wagesStateReducer from './wagesState/wagesState.reducer';
import suggestlyEntityStateReducer from './suggestlyEntityState/suggestlyEntityState.reducer';
import companyInformationsStateReducer from './companyInformationsState/companyInformationsState.reducer';
import embedStateReducer from './embedState/embedState.reducer';
import accountSettingsStateReducer from './accountSettingsState/accountSettingsState.reducer';
import leadsStateReducer from './leadsState/leadsState.reducer';
import landingPageStateReducer from './landingPageState/landingPageState.reducer';
import callbacksStateReducer from './callbacksState/callbacksState.reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['cookiePolicyPopup', 'user', 'suggestlyEntityState']
}

const rootReducer = combineReducers({
  cookiePolicyPopup: cookiePolicyPopupReducer,
  callbacksState: callbacksStateReducer,
  landingPageState: landingPageStateReducer,
  accountSettingsState: accountSettingsStateReducer,
  inputsState: inputsStateReducer,
  leadsState: leadsStateReducer,
  wagesState: wagesStateReducer,
  productsState: productsStateReducer,
  embedState: embedStateReducer,
  user: userReducer,
  suggestlyEntityState: suggestlyEntityStateReducer,
  companyInformationsState: companyInformationsStateReducer
})

export default persistReducer(persistConfig, rootReducer);