import { createSelector } from 'reselect';
import { SuggestlyStore } from '../store.types';

const embedState = (state: SuggestlyStore) => state.embedState;

export const selectEmbedDomains = createSelector(
  [embedState],
  embedState => { 
    return embedState.embedDomains
  }
);