import React, { useState, useEffect } from "react";
import "./princing-plans.scss";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Switch from "../switch/switch";
import MainSection from "../main-section/main-section";
import SwitchWithLabels from "../switch/switch-with-labels";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import { StripePlanIdEnum } from "../../dto/stripe-plan-id-enum";
import { PaymentController } from "../../api/paymentController";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { UserDto } from "../../dto/user-dto";
import { shallowEqual, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { ToastsStore } from "react-toasts";
import BillingInformationsForm from "../billing-informations/billing-informations-form";
import { selectCompanyInformations } from "../../redux/companyInformationsState/companyInformationsState.selectors";
import MicroSpinner from "../spinner/microSpinner";
import { Link } from "react-router-dom";
import { RoutePath } from "../../../route-paths";

interface Props {
}

const PrincingPlans: React.FC<Props> = (props: Props) => {
  const [isAnnual, setIsAnnual] = useState(true);
  const yearlyPrice = [60, 120, 360];
  const monthlyPrice = [68, 136, 392];
  const user = useSelector(selectCurrentUser, shallowEqual) as UserDto;
  const [stripePlanId, setStripePlanId] = useState();
  const [isFillDataModalOpen, setIsConfigmModalOpen] = useState(false);
  // const [isSave, setIsSave] = useState(true)
  const companyInformations = useSelector(selectCompanyInformations, shallowEqual);
  const [isSpinner, setisSpinner] = useState(false);


  // const companyInformations = useSelector(selectCompanyInformations, shallowEqual) as CompanyInformationsDto;
  // const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(getCompanyInformations());
    if (!(companyInformations.addressCity && companyInformations.addressCountry && companyInformations.addressLineOne && companyInformations.addressPostalCode && companyInformations.userFirstname && companyInformations.userLastname)) {
      // setIsSave(true);
    }
    else {
      // setIsSave(false);
    }

  }, [companyInformations])

  const handleSubscribe = (stripePlanIdEnum: StripePlanIdEnum) => {
    setIsConfigmModalOpen(true)
    setStripePlanId(stripePlanIdEnum as any);
  }

  const handleConfirmSubscribe = (stripePlanIdEnum: StripePlanIdEnum) => {
    setisSpinner(true);
    setTimeout(() => {
      var subscribePromise = PaymentController.subscribe(stripePlanId as any);
      subscribePromise.then(() => {
        setIsConfigmModalOpen(false);

      }, reason => {
        if (isFillDataModalOpen) {
          ToastsStore.error("Uzupełnij wymagane pola");
        }
        console.error(reason); // Error!
      })
      setisSpinner(false);
    }, 2500);
  }
  const handleUpdatePlan = (stripePlanIdEnum: StripePlanIdEnum) => {
    PaymentController.updatePlan(stripePlanIdEnum);
  }

  const handleUpdateCard = () => {
    PaymentController.updateCard();
  }

  const handleUnsubscribe = () => {
    PaymentController.unsubscribe();
  }

  const whatIsEntity = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Czym jest projekt?</Popover.Title>
      <Popover.Content>
        Projekt to pojedynczy, indywidualny system wspomagania decyzji. Zawiera zestaw pytań i odpowiedzi z jednej kategorii.
        <br /> <br />
        W planach Plus i Pro konto Suggestly umożliwia stworzenie wielu projektów. <br /> Np. osobno dla każdej kategorii produktów/usług w sklepie online.
      </Popover.Content>
    </Popover>
  );

  const whatIsResult = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Czym jest sugestia?</Popover.Title>
      <Popover.Content>
        Sugestią może być np. produkt lub usługa, którą chcesz promować. Są to dane pokazujący się użytkownikowi jako rezultat końcowy.
      </Popover.Content>
    </Popover>
  );

  const whatIsDomain = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Co to oznacza?</Popover.Title>
      <Popover.Content>
        Suggestly można osadzać w formie modułu na zewnętrznych stronach. Liczba dostępnych domen oznacza liczbę stron, na których można umieścić widżet.
      </Popover.Content>
    </Popover>
  );

  const princingPlanNav = (stripePlanIdEnum: StripePlanIdEnum) => {
    return (
      <div>
        {
          (user && user.rolePlan && user.rolePlan.stripePlan === stripePlanIdEnum ?
            <>
              <br />
              <Button onClick={(e: any) => handleUpdateCard()}>Aktualizuj kartę</Button>&nbsp;
              <Button onClick={(e: any) => handleUnsubscribe()}>Anuluj</Button>
              <br />
            </>
            :
            (user && user.rolePlan && user.rolePlan ?
              <>
                <br />
                <Button onClick={(e: any) => handleUpdatePlan(stripePlanIdEnum)}>Zmień plan</Button>
                <br />
              </>
              :
              <>
                <br />
                <Button onClick={(e: any) => handleSubscribe(stripePlanIdEnum)}>Subskrybuj</Button>
                <br />
              </>
            )
          )
        }
        <br />
      </div>
    )
  }
  return (
    <div className="princing-plans-config">
      <Modal show={isFillDataModalOpen} onHide={() => setIsConfigmModalOpen(false)} size="lg">
        <Modal.Header closeButton>
          <h5>Dane niezbędne do wystawienia faktury</h5>
        </Modal.Header>
        <Modal.Body>
          <BillingInformationsForm />
        </Modal.Body>
        <Modal.Footer>
          <MicroSpinner isVisible={isSpinner} />
          &nbsp;
          &nbsp;
          &nbsp;
          <Button variant="secondary" onClick={() => handleConfirmSubscribe(stripePlanId as any)}>
            Potwierdź i zapłać
          </Button>
          <Button variant="primary" onClick={() => setIsConfigmModalOpen(false)}>
            Anuluj
          </Button>
        </Modal.Footer>
      </Modal >
      <MainSection
        smallTitle="Dostępne plany subskrypcyjne"
      >
        <SwitchWithLabels
          leftLabel={<>Miesięcznie</>}
          rightLabel={
            <>
              <div>Rocznie</div>
              <div className="saveLabel">OSZCZĘDZASZ DO 15% </div>
            </>
          }
          isLeftActive={isAnnual}
        >
          <Switch
            inText
            checked={isAnnual}
            onChange={() => setIsAnnual(!isAnnual)}
          >
          </Switch>
        </SwitchWithLabels>
      </MainSection>
      <Row>
        <Col xl={{ span: 4 }}>
          <Card>
            <Card.Header>Basic</Card.Header>
            <Card.Body>
              <h3><span></span>{isAnnual ? yearlyPrice[0] : monthlyPrice[0]}<span>zł/m</span></h3>
              <h6> {isAnnual ? `Łącznie rocznie ${yearlyPrice[0] * 12}zł` : <> &nbsp; </>}</h6>
              {/* <h6> {isAnnual ? `Łącznie rocznie $${yearlyPrice[0] * 12}` : `Łącznie rocznie $${monthlyPrice[0] * 12}`}</h6> */}
              <hr />
              <ul>
                <li>1 projekt&nbsp;
                  <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={whatIsEntity}>
                    <i className="fa fa-question-circle" aria-hidden="true" />
                  </OverlayTrigger>
                </li>
                <li>1 domena&nbsp;
                  <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={whatIsDomain}>
                    <i className="fa fa-question-circle" aria-hidden="true" />
                  </OverlayTrigger></li>
                <li>Do 5000 dopasowań/m&nbsp;
                  <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={whatIsResult}>
                    <i className="fa fa-question-circle" aria-hidden="true" />
                  </OverlayTrigger></li>
                <hr />
                <li>Sugestie z linkiem</li>
              </ul>
              {isAnnual ? princingPlanNav(StripePlanIdEnum.BasicYearly) : princingPlanNav(StripePlanIdEnum.BasicMonthly)}
            </Card.Body>
          </Card>
        </Col>
        <Col xl={{ span: 4 }}>
          <Card>
            <Card.Header>Plus <span className="most-popuplar">MOST POPULAR</span></Card.Header>
            <Card.Body>
              <h3><span></span>{isAnnual ? yearlyPrice[1] : monthlyPrice[1]}<span>zł/m</span></h3>
              <h6> {isAnnual ? `Łącznie rocznie ${yearlyPrice[1] * 12}zł` : <> &nbsp; </>}</h6>
              {/* //`Łącznie rocznie $${monthlyPrice[1] * 12}`}</h6> */}
              <hr />
              <ul>
                <li>5 projektów&nbsp;
                  <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={whatIsEntity}>
                    <i className="fa fa-question-circle" aria-hidden="true" />
                  </OverlayTrigger>
                </li>
                <li>3 domeny&nbsp;
                  <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={whatIsDomain}>
                    <i className="fa fa-question-circle" aria-hidden="true" />
                  </OverlayTrigger></li>
                <li>Do 10000 dopasowań/m&nbsp;
                  <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={whatIsResult}>
                    <i className="fa fa-question-circle" aria-hidden="true" />
                  </OverlayTrigger></li>
                <hr />
                <li>Sugestia z linkiem</li>
                <li>Sugestia z lead magnetem</li>
                <li>Sugestia po podaniu emaila</li>
              </ul>
              {isAnnual ? princingPlanNav(StripePlanIdEnum.PlusYearly) : princingPlanNav(StripePlanIdEnum.PlusMonthly)}
            </Card.Body>
          </Card>
        </Col>
        <Col xl={{ span: 4 }}>
          <Card>
            <Card.Header>Pro</Card.Header>
            <Card.Body>
              <h3><span></span>{isAnnual ? yearlyPrice[2] : monthlyPrice[2]}<span>zł/m</span></h3>
              <h6>{isAnnual ? `Łącznie rocznie ${yearlyPrice[2] * 12}zł` : <> &nbsp; </>}</h6>
              {/* <h6> {isAnnual ? `Total in the year $${yearlyPrice[2] * 12}` : `Total in the year $${monthlyPrice[2] * 12}`}</h6> */}
              <hr />
              <ul>
                <li>15 projektów&nbsp;
                  <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={whatIsEntity}>
                    <i className="fa fa-question-circle" aria-hidden="true" />
                  </OverlayTrigger>
                </li>
                <li>3 domeny&nbsp;
                  <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={whatIsDomain}>
                    <i className="fa fa-question-circle" aria-hidden="true" />
                  </OverlayTrigger></li>
                <li>Do 30000 dopasowań/m&nbsp;
                  <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={whatIsResult}>
                    <i className="fa fa-question-circle" aria-hidden="true" />
                  </OverlayTrigger></li>
                <hr />
                <li>Sugestia z linkiem</li>
                <li>Sugestia z magnet leadem</li>
                <li>Sugestia po podaniu emaila</li>
              </ul>
              {isAnnual ? princingPlanNav(StripePlanIdEnum.ProYearly) : princingPlanNav(StripePlanIdEnum.ProMonthly)}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <MainSection
        smallTitle="Istnieje możliwość rozszerzenia konta"
        subtitle="Skontaktuj się z nami, przedstaw swój pomysł, a zaoferujemy Ci coś więcej!"
      />
      <MainSection
        smallTitle="Posiadasz klucz rozszerzający konto?"
        subtitle={<div className="realize-coupon">Zrealizuj go tutaj: <Link to={RoutePath.REALIZECOUPON_SETTINGS}>Modyfikacja konta</Link></div>}
      />
    </div >
  )
}

export default PrincingPlans;