import React, { useState } from "react";
// import "./input-item.scss";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";

import { useDispatch, shallowEqual, useSelector } from "react-redux";
import MainSection from "../../../../components/main-section/main-section";
import AwesomeHand from "./../../../../assets/awesomeHand.svg";
import { selectCurrentSuggestlyEntityId } from "../../../../redux/suggestlyEntityState/suggestlyEntityState.selectors";
import { renameDefaultProject } from "../../../../redux/suggestlyEntityState/suggestlyEntityState.actions";

const RenameHomeScreenHelper: React.FC = () => {
  const [suggestlyEntityName, setSuggestlyEntityName] = useState("")
  const currentSuggestlyEntityId = useSelector(selectCurrentSuggestlyEntityId, shallowEqual);

  const dispatch = useDispatch();

  const handleClickNext = () => {
    dispatch(renameDefaultProject(suggestlyEntityName, currentSuggestlyEntityId));
  }

  return (
    <>
      <Container fluid={false}>
        <div className="input-item renameHomeScreen">
          <Row>
            <Col xl={{ span: 12 }} className="colJumbo">
              <Card className="inputs-group-main">
                <Card.Body>
                  <Row>
                    <Col xl={{ span: 7 }} className="col">
                      <img src={AwesomeHand} alt="" />
                    </Col>
                    <Col xl={{ span: 5, offset: 0 }} className="col">
                      <MainSection
                        title="Nowy projekt"
                        subtitle="Nazwa projektu jest widoczna tylko u Ciebie"
                      >
                      </MainSection>
                      <Form onSubmit={(e: any) => e.preventDefault()}>
                        <Form.Control
                          className="form-control-alternative"
                          placeholder="Nazwa projektu"
                          type="text"
                          onChange={(e: any) => setSuggestlyEntityName(`${e.target.value ? e.target.value : ""}`)}
                          name="suggestlyEntityName"
                        />
                        <Button onClick={handleClickNext} variant="secondary" className="float-right">Dalej</Button>
                      </Form>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  )
}

export default RenameHomeScreenHelper;