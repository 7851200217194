import LeadsStateTypes from './leadsState.types';
import { LeadDto } from '../../dto/leadDto';

const INITIAL_STATE = {
  leads: [] as Array<LeadDto>,
}

const leadsStateReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LeadsStateTypes.DELETE_LEAD_SUCCESS:
      let leadsFiltered = state.leads.filter(x => x.id !== action.payload);
      return {
        ...state,
        leads: leadsFiltered
      }
    case LeadsStateTypes.GET_ALL_LEADS_SUCCESS:
      let allLeads = [...state.leads, ...action.payload];
      return {
        ...state,
        leads: allLeads
      }
    case LeadsStateTypes.LEADS_CLEAR_STATE:
      return {
        ...state,
        leads: [] as Array<LeadDto>
      }
    default: {
      return state;
    }
  }
}

export default leadsStateReducer;