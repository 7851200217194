import "./circural-chart.scss";
import React from "react";

interface Props {
  isNegative?: boolean;
  val: number;
  text?: string;
}

const CircuralChart: React.FC<Props> = (props: Props) => {
  const { isNegative, val, text } = props;

  return (
    <svg className="circle-chart" viewBox="0 0 33.83098862 33.83098862" width="200" height="200" xmlns="http://www.w3.org/2000/svg">
      <circle className="circle-chart__background" stroke="#fcf3e2" strokeWidth="2" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431" />
      <circle className={isNegative ? "circle-chart__circle circle-chart__circle--negative" : "circle-chart__circle"} stroke="#FAC85A" strokeWidth="2" strokeDasharray={`${val},100`} strokeLinecap="round" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431" />

      <g className="circle-chart__info">
        <text className="circle-chart__percent" x="16.91549431" y={text ? "15.5" : "17"} alignmentBaseline="central" textAnchor="middle" fontSize="8">
          {isNegative ? (`-${val}%`) : (`${val}%`)}
        </text>
        {text ? <text className="circle-chart__subline" x="16.91549431" y="20.5" alignmentBaseline="central" textAnchor="middle" fontSize="2">{text}</text> : null}
      </g>
    </svg>
  );
}

export default CircuralChart