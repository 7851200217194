import SuggestlyEntityStateActionTypes from './suggestlyEntityState.types';
import { ModalDto } from '../../dto/modalDto';
import { RoutePath } from '../../../route-paths';
import { ClientActionDto } from '../../dto/clientActionDto';

const INITIAL_STATE = {
  currentEntitySuggestlyId: "" as string,
  globalModal: {
    title: "",
    description: "",
    buttonTitle: "",
    path: RoutePath.HOME,
    isVisible: false
  } as ModalDto,
  clientActionsSum: {
    results: 0,
    newLeads: 0,
    redirects: 0
  } as ClientActionDto
}

const suggestlyEntityStateReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SuggestlyEntityStateActionTypes.SET_CURRENT_SUGGESTLYENTITY_ID_SUCCESS:
      return {
        ...state,
        currentEntitySuggestlyId: action.payload
      }
    case SuggestlyEntityStateActionTypes.SHOW_GLOBAL_MODAL_SUCCESS:
      return {
        ...state,
        globalModal: {
          title: action.payload.title,
          description: action.payload.description,
          buttonTitle: action.payload.buttonTitle,
          path: action.payload.path,
          isVisible: true
        }
      }
    case SuggestlyEntityStateActionTypes.HIDE_GLOBAL_MODAL_SUCCESS:
      return {
        ...state,
        globalModal: {
          title: "",
          description: "",
          buttonTitle: "",
          path: RoutePath.HOME,
          isVisible: false
        }
      }
    case SuggestlyEntityStateActionTypes.GET_CLIENTACTIONS_SUCCESS:
      return {
        ...state,
        clientActionsSum: {
          results: action.payload.results,
          newLeads: action.payload.newLeads,
          redirects: action.payload.redirects,
        }
      }
    case SuggestlyEntityStateActionTypes.CLEAR_CLIENTACTIONS_SUCCESS:
      return {
        ...state,
        clientActionsSum: {
          results: 0,
          newLeads: 0,
          redirects: 0
        }
      }
    case SuggestlyEntityStateActionTypes.SUGGESTLYENTITY_CLEAR_STATE:
      return {
        ...state,
        currentEntitySuggestlyId: "",
        globalModal: {
          title: "",
          description: "",
          buttonTitle: "",
          path: RoutePath.HOME,
          isVisible: false
        } as ModalDto,
        clientActionsSum: {
          results: 0,
          newLeads: 0,
          redirects: 0
        } as ClientActionDto
      }
    default: {
      return state;
    }
  }
}

export default suggestlyEntityStateReducer;