import React, { useState, useEffect } from "react";
import "./variant-item.scss";
import Form from "react-bootstrap/Form";
import Dropzone from "../drop-zone/drop-zone.component";
import { useDispatch } from "react-redux";
import { deleteVariant, updateVariant, updateVariantTitle } from "../../redux/inputsState/inputsState.actions";
import { ApiRootPath } from "../../api/apiRootPath";
import { VariantDto } from "../../dto/variantDto";
import { VariantTypeEnum } from "../../dto/variantTypeEnum";

interface Props {
  variantDto: VariantDto;
  index: number;
}

const VariantItem: React.FC<Props> = (props: Props) => {
  const [variantTitle, setVariantTitle] = useState("")
  const [variantText, setVariantText] = useState("")
  const [variantImage, setVariantImage] = useState("")
  const dispatch = useDispatch();
  const { variantDto } = props;

  useEffect(() => {
    setVariantTitle(variantDto.title);
    setVariantText(variantDto.text);
    if (variantDto.image != null && variantDto.image.length > 0) {
      setVariantImage(ApiRootPath.rootPath + variantDto.image);
    }
    else {
      setVariantImage("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  const onFileDelete = () => {
    dispatch(updateVariant(variantDto.id, null, null));
  }

  const onFileAdded = (item: any) => {
    dispatch(updateVariant(variantDto.id, item.file, null));
  }

  const handleDelete = () => {
    dispatch(deleteVariant(variantDto.id));
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      if (e.target.name === "variantText") {
        e.target.blur();
      }
      if (e.target.name === "variantTitle") {
        e.target.blur();
      }
    }
  }
  const handleBlur = (e: any) => {
    if (e.target.name === "variantText") {
      dispatch(updateVariant(variantDto.id, null, e.target.value)); // update variant
    }
    if (e.target.name === "variantTitle") {
      dispatch(updateVariantTitle(variantDto.id, e.target.value)); // update variant
    }
  }

  return (
    <div className="variant-item">
      <div className="variant-item-infoBar">
        <span>
          Wariant odpowiedzi nr. {props.index + 1}
        </span>
        <i onClick={handleDelete} className="fa fa-trash" aria-hidden="true"></i>
      </div>
      {variantDto.variantType === VariantTypeEnum.Text ?
        <Form>
          <Form.Label>Tytuł</Form.Label>
          <Form.Control
            className="form-control-alternative"
            placeholder="Tytuł wariantu"
            type="text"
            onChange={(e: any) => setVariantTitle(`${e.target.value ? e.target.value : ""}`)}
            name="variantTitle"
            defaultValue={variantTitle}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
          />
          <Form.Label>Opis</Form.Label>
          <Form.Control
            className="form-control-alternative"
            placeholder="Opis wariantu ..."
            type="text"
            as="textarea"
            rows={3}
            onChange={(e: any) => setVariantText(`${e.target.value ? e.target.value : ""}`)}
            name="variantText"
            defaultValue={variantText}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
          />
        </Form> :
        (variantDto.variantType === VariantTypeEnum.Image ?
          <Form>
            <Form.Label>Trtuł</Form.Label>
            <Form.Control
              className="form-control-alternative"
              placeholder="Tytuł wariantu"
              type="text"
              onChange={(e: any) => setVariantTitle(`${e.target.value ? e.target.value : ""}`)}
              name="variantTitle"
              defaultValue={variantTitle}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
            />
            <Form.Label>Zdjęcie/grafika wariantu</Form.Label>
            <Dropzone
              imagePreviewUrl={variantImage}
              onFileDelete={() => onFileDelete()}
              onFileAdded={(e: any) => onFileAdded(e)}
              disabled={false}
            />
          </Form> : null)}
    </div>
  )
}

export default VariantItem;