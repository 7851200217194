import { ApiMethodDto } from "./api-method-dto";
import { ApiPath } from "./apiPath";
import { callApi } from "./api-call-service";

export class PublicController {
  static async confirmEmail(leadId: string): Promise<boolean> {
    return new Promise(async (resolve) => {
      const options = {
        method: "GET",
        expectedStatus: [200, 400],
        params: { name: "leadId", value: leadId },
        noToast: true
      } as ApiMethodDto;

      await callApi(options.method, ApiPath.publicApi_confirmEmail, options)
        .then((res: any) => {
          if (res.details.status === 200) {
            resolve(true);
          }
          else{
            resolve(false);
          }
        })
        .catch(()=>{
          resolve(false);
        })
    });
  }

  static async IsLive(): Promise<boolean> {
    return new Promise(async (resolve) => {
      const options = {
        method: "GET",
        expectedStatus: [200],
        noToast: true
      } as ApiMethodDto;

      await callApi(options.method, ApiPath.publicApi_isLive, options)
        .then((res: any) => {
          if (res.details.status === 200) {
            resolve(true);
          }
          else{
            resolve(false);
          }
        })
        .catch(()=>{
          resolve(false);
        })
    });
  }
}