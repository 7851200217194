const EmbedStateActionTypes = {
  GET_EXTERNALDOMAINS: 'GET_EXTERNALDOMAINS',
  GET_EXTERNALDOMAINS_SUCCESS: 'GET_EXTERNALDOMAINS_SUCCESS',
  
  DELETE_EXTERNALDOMAIN: 'DELETE_EXTERNALDOMAIN',
  ADD_EXTERNALDOMAIN: 'ADD_EXTERNALDOMAIN',

  EMBED_CLEAR_STATE: 'EMBED_CLEAR_STATE'
};

export default EmbedStateActionTypes;