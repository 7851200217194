import React, { useState } from "react";
import "./princing-plans-landing.scss";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Switch from "../switch/switch";
import MainSection from "../main-section/main-section";
import SwitchWithLabels from "../switch/switch-with-labels";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Container from "react-bootstrap/Container";

interface Props {
}

const PrincingPlansLanding: React.FC<Props> = () => {
  const [isAnnual, setIsAnnual] = useState(true);
  const yearlyPrice = [60, 120, 360];
  const monthlyPrice = [68, 136, 392];

  const whatIsEntity = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Czym jest projekt?</Popover.Title>
      <Popover.Content>
        Projekt to pojedynczy, indywidualny system wspomagania decyzji. Zawiera zestaw pytań, wariantów odpowiedzi i sugestii.
        <br /> <br />
        W planach Plus i Pro konto Suggestly umożliwia stworzenie wielu projektów. <br /> Np. osobno dla każdej kategorii produktów/usług w sklepie online.
      </Popover.Content>
    </Popover>
  );

  const whatIsResult = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Czym jest sugestia?</Popover.Title>
      <Popover.Content>
        Sugestią może być np. produkt lub usługa, którą chcesz promować. Jest to coś, co pokazuje się użytkownikowi jako rezultat końcowy.
      </Popover.Content>
    </Popover>
  );

  const whatIsDomain = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Co to oznacza?</Popover.Title>
      <Popover.Content>
        Suggestly można osadzać w formie modułu na zewnętrznych stronach. Liczba dostępnych domen oznacza liczbę stron, na których można umieścić widżet.
      </Popover.Content>
    </Popover>
  );


  return (
    <section className="princing-plans-landing">
      <Container>
        <div className="sectionTitle">
          <h4>Dostępne plany cenowe</h4>
          <p>Wybierz właściwy plan dla siebie</p>
        </div>
        <SwitchWithLabels
          leftLabel={<>Miesięcznie</>}
          rightLabel={
            <>
              <div>Płatność roczna</div>
              <div className="saveLabel">OSZCZĘDZASZ DO 15% </div>
            </>
          }
          isLeftActive={isAnnual}
        >
          <Switch
            inText
            checked={isAnnual}
            onChange={() => setIsAnnual(!isAnnual)}
          >
          </Switch>
        </SwitchWithLabels>
        <Row>
          <Col xl={{ span: 10, offset: 1 }}>
            <Row>
              <Col xl={{ span: 4 }}>
                <Card>
                  <Card.Header>Basic</Card.Header>
                  <Card.Body>
                    <div className="price"><span></span>{isAnnual ? yearlyPrice[0] : monthlyPrice[0]}<span>zł/m</span></div>
                    <h6> {isAnnual ? `Łącznie rocznie ${yearlyPrice[0] * 12}zł` : <> &nbsp; </>}</h6>
                    {/* <h6> {isAnnual ? `Łącznie rocznie $${yearlyPrice[0] * 12}` : `Łącznie rocznie $${monthlyPrice[0] * 12}`}</h6> */}
                    <hr />
                    <ul>
                      <li>1 projekt&nbsp;
                  <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={whatIsEntity}>
                          <i className="fa fa-question-circle" aria-hidden="true" />
                        </OverlayTrigger>
                      </li>
                      <li>1 domena&nbsp;
                  <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={whatIsDomain}>
                          <i className="fa fa-question-circle" aria-hidden="true" />
                        </OverlayTrigger></li>
                      <li>Do 5000 sugestii/m&nbsp;
                  <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={whatIsResult}>
                          <i className="fa fa-question-circle" aria-hidden="true" />
                        </OverlayTrigger></li>
                      <hr />
                      <li>Sugestie z linkiem</li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl={{ span: 4 }}>
                <Card>
                  <Card.Header>Plus <span className="most-popuplar">MOST POPULAR</span></Card.Header>
                  <Card.Body>
                    <div className="price"><span></span>{isAnnual ? yearlyPrice[1] : monthlyPrice[1]}<span>zł/m</span></div>
                    <h6> {isAnnual ? `Łącznie rocznie ${yearlyPrice[1] * 12}zł` : <> &nbsp; </>}</h6>
                    {/* //`Łącznie rocznie $${monthlyPrice[1] * 12}`}</h6> */}
                    <hr />
                    <ul>
                      <li>5 projektów&nbsp;
                  <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={whatIsEntity}>
                          <i className="fa fa-question-circle" aria-hidden="true" />
                        </OverlayTrigger>
                      </li>
                      <li>3 domeny&nbsp;
                  <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={whatIsDomain}>
                          <i className="fa fa-question-circle" aria-hidden="true" />
                        </OverlayTrigger></li>
                      <li>Do 10000 sugestii/m&nbsp;
                  <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={whatIsResult}>
                          <i className="fa fa-question-circle" aria-hidden="true" />
                        </OverlayTrigger></li>
                      <hr />
                      <li>Sugestie z linkiem</li>
                      <li>Sugestie z lead magnetem</li>
                      <li>Sugestie po podaniu emaila</li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl={{ span: 4 }}>
                <Card>
                  <Card.Header>Pro</Card.Header>
                  <Card.Body>
                    <div className="price"><span></span>{isAnnual ? yearlyPrice[2] : monthlyPrice[2]}<span>zł/m</span></div>
                    <h6>{isAnnual ? `Łącznie rocznie ${yearlyPrice[2] * 12}zł` : <> &nbsp; </>}</h6>
                    {/* <h6> {isAnnual ? `Total in the year $${yearlyPrice[2] * 12}` : `Total in the year $${monthlyPrice[2] * 12}`}</h6> */}
                    <hr />
                    <ul>
                      <li>15 projektów&nbsp;
                  <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={whatIsEntity}>
                          <i className="fa fa-question-circle" aria-hidden="true" />
                        </OverlayTrigger>
                      </li>
                      <li>3 domeny&nbsp;
                  <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={whatIsDomain}>
                          <i className="fa fa-question-circle" aria-hidden="true" />
                        </OverlayTrigger></li>
                      <li>Do 30000 sugestii/m&nbsp;
                  <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={whatIsResult}>
                          <i className="fa fa-question-circle" aria-hidden="true" />
                        </OverlayTrigger></li>
                      <hr />
                      <li>Wszystko z planu Plus</li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <MainSection
          subtitle={<>Podstawowa oferta to za mało? <br/> Przedstaw swój pomysł, a zaoferujemy Ci coś więcej!</>}
        />
      </Container>
    </section >
  )
}

export default PrincingPlansLanding;