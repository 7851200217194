import "./drop-zone.scss";
import React, { useState, useRef, useEffect, RefObject } from "react";
import { ToastsStore } from "react-toasts";

interface Props {
  onFileAdded: any;
  onFileDelete?: any;
  disabled: boolean;
  clear?: boolean;
  imagePreviewUrl?: string;
}


const Dropzone: React.FC<Props> = (props: Props) => {
  const [hightlight, setHightlight] = useState(false)

  const fileInputRef = useRef<HTMLInputElement>() as RefObject<HTMLInputElement>; //createRef<HTMLInputElement>();

  useEffect(() => {
  }, [props.clear])

  const handleDeleteImage = () => {
    props.onFileDelete();
  }

  const onDragOver = (e: any) => {
    e.preventDefault();
    if (props.disabled) return;
    setHightlight(true);
  };

  const onDragLeave = (e: any) => {
    e.preventDefault();
    setHightlight(false);
  };

  const handleImageChange = (file: File) => {
    let reader = new FileReader();
    reader.onloadend = () => {
    };
    reader.readAsDataURL(file);
  };

  const onDrop = (e: any) => {
    e.preventDefault();
    if (props.disabled) return;
    const files = e.dataTransfer.files;

    let FileSizeLimit = files[0].size / 1024 / 1024;
    if (FileSizeLimit > 2) {
      ToastsStore.error('File size cannot exceed 2MB');
      setHightlight(false);
      return;
    }
    else if (props.onFileAdded && files[0]) {
      handleImageChange(files[0]);
      const image = { file: files[0] };
      props.onFileAdded(image);
    }
    setHightlight(false);
  };

  const openFileDialog = () => {
    if (props.disabled) return;
    const node = fileInputRef.current;
    if (node) {
      node.click();
    }
  };

  const handleFileAdd = (e: any) => {
    if (props.disabled) return;
    const file = e.target.files[0];
    if (props.onFileAdded && file) {
      handleImageChange(file);
      const image = { file: file };
      props.onFileAdded(image);
    }
  };

  const { imagePreviewUrl } = props;

  return (
    <>
      <div className="dropzone-wrapper form-control-alternative" style={{ backgroundImage: imagePreviewUrl ? ("url(" + imagePreviewUrl + ")") : "" }}>
        {imagePreviewUrl ? <i color="danger" onClick={handleDeleteImage} className="deleteImage fa fa-times" aria-hidden="true"></i> : null}
        <div
          className={`dropzone ${hightlight ? "highlight" : ""}`}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          onClick={openFileDialog}
          style={{ cursor: props.disabled ? "default" : "pointer" }}
        >
          {imagePreviewUrl ? null : <i className="placeholder fa fa-file-image-o" aria-hidden="true"></i>}
          <input
            ref={fileInputRef}
            className="fileInput"
            type="file"
            multiple={false}
            onChange={handleFileAdd}
          />
        </div>
      </div>
    </>
  );
}

export default Dropzone;