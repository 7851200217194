import { createSelector } from 'reselect';
import { SuggestlyStore } from '../store.types';

const wagesState = (state: SuggestlyStore) => state.wagesState;

export const selectInputsWages = createSelector(
  [wagesState],
  wagesState => {
    return wagesState.inputsWages
  }
);

export const selectInputWage = (index: number) => createSelector(
  [wagesState],
  wagesState => {
    return wagesState.inputsWages[index]
  }
);