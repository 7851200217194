import React, { useState, useEffect } from "react";
import "./product-item.scss";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "react-bootstrap/FormGroup";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Dropzone from "../drop-zone/drop-zone.component";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { ApiRootPath } from "../../api/apiRootPath";
import { updateProductPrice, updateProductDescription, updateProductImage, deleteOutput, updateOutputName, updateProductTitle, updateProductSubtitle, updateLeadMagnetOutputTitle, updateLeadMagnetOutputDescription, updateLeadMagnetOutputMessage, updateLeadmagnetoutputHeader, updateLeadmagnetoutputAcceptlabel, updateProductHeader } from "../../redux/productState/productsState.actions";
import { selectOutput } from "../../redux/productState/productsState.selectors";
import { OutputDto } from "../../dto/outputDto";

interface Props {
  index: number;
}

const LeadMagnetOutputItem: React.FC<Props> = (props: Props) => {
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [description, setDescription] = useState("");
  const [mainImage, setMainImage] = useState("");
  const [price, setPrice] = useState("");
  const [productHeader, setProductHeader] = useState("");

  const [mailFormTitle, setMailFormTitle] = useState("");
  const [mailFormDescription, setMailFormDescription] = useState("");
  const [mailFormMessage, setMailFormMessage] = useState("");

  const [leadmagnetoutputHeader, setLeadmagnetoutputHeader] = useState("");
  const [leadmagnetoutputAcceptLabel, setLeadmagnetoutputAcceptLabel] = useState("");

  const output = useSelector(selectOutput(props.index), shallowEqual) as OutputDto;
  const dispatch = useDispatch();

  useEffect(() => {
    setName(output.name);
    setTitle(output.affiliationOutput.title);
    setSubtitle(output.affiliationOutput.subtitle);
    setDescription(output.affiliationOutput.description);
    setPrice(output.affiliationOutput.price);
    setProductHeader(output.affiliationOutput.header);

    setMailFormTitle(output.leadMagnetOutput.title);
    setMailFormDescription(output.leadMagnetOutput.description);
    setMailFormMessage(output.leadMagnetOutput.message);
    setLeadmagnetoutputHeader(output.leadMagnetOutput.header);
    setLeadmagnetoutputAcceptLabel(output.leadMagnetOutput.acceptLabel);

    if (output.affiliationOutput.mainImg != null && output.affiliationOutput.mainImg.length > 0) {
      setMainImage(ApiRootPath.rootPath + output.affiliationOutput.mainImg);
    }
    else {
      setMainImage("");
    }
  }, [output])

  const onFileDelete = () => {
    dispatch(updateProductImage(output.affiliationOutput.id, null));
  }

  const onFileAdded = (item: any) => {
    dispatch(updateProductImage(output.affiliationOutput.id, item.file));
  }

  const handleDelete = () => {
    dispatch(deleteOutput(output.id));
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      if (e.target.name === "name" || e.target.name === 'title' || e.target.name === 'description' || e.target.name === 'price') {
        e.target.blur();
      }
      else if (e.target.name === 'mailFormTitle' || e.target.name === 'productHeader' || e.target.name === 'mailFormDescription' || e.target.name === 'mailFormMessage' || e.target.name === 'leadmagnetoutputHeader' || e.target.name === 'leadmagnetoutputAcceptLabel') {
        e.target.blur();
      }
    }
  }

  const handleBlur = (e: any) => {
    if (e.target.name === "name") {
      dispatch(updateOutputName(output.id, e.target.value));
    }
    if (e.target.name === "productHeader") {
      dispatch(updateProductHeader(output.affiliationOutput.id, e.target.value));
    }
    if (e.target.name === "title") {
      dispatch(updateProductTitle(output.affiliationOutput.id, e.target.value));
    }
    if (e.target.name === "subtitle") {
      dispatch(updateProductSubtitle(output.affiliationOutput.id, e.target.value));
    }
    if (e.target.name === "description") {
      dispatch(updateProductDescription(output.affiliationOutput.id, e.target.value));
    }
    if (e.target.name === "price") {
      dispatch(updateProductPrice(output.affiliationOutput.id, e.target.value));
    }

    if (e.target.name === "mailFormTitle") {
      dispatch(updateLeadMagnetOutputTitle(output.leadMagnetOutput.id, e.target.value));
    }
    if (e.target.name === "mailFormDescription") {
      dispatch(updateLeadMagnetOutputDescription(output.leadMagnetOutput.id, e.target.value));
    }
    if (e.target.name === "mailFormMessage") {
      dispatch(updateLeadMagnetOutputMessage(output.leadMagnetOutput.id, e.target.value));
    }
    if (e.target.name === "leadmagnetoutputHeader") {
      dispatch(updateLeadmagnetoutputHeader(output.leadMagnetOutput.id, e.target.value));
    }
    if (e.target.name === "leadmagnetoutputAcceptLabel") {
      dispatch(updateLeadmagnetoutputAcceptlabel(output.leadMagnetOutput.id, e.target.value));
    }
  }

  const { index } = props;

  return (
    <div className="product-item">
      <Card className="inputs-group-main">
        <Card.Header>
          <Row>
            <Col>
              <Form onSubmit={(e: any) => e.preventDefault()}>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroup.Prepend>
                      <InputGroup.Text>{index + 1}. </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      className="form-control-alternative"
                      placeholder="Nazwa sugestii (pole widoczne tylko u Ciebie)"
                      type="text"
                      onChange={(e: any) => setName(`${e.target.value ? e.target.value : ""}`)}
                      name="name"
                      defaultValue={name}
                      onKeyDown={handleKeyDown}
                      onBlur={handleBlur}
                    />
                  </InputGroup>
                </FormGroup>
              </Form>
            </Col>
            <Col style={{ textAlign: 'right' }}>
              <div className="deleteItemWrapper" onClick={handleDelete}><i className="fa fa-trash" aria-hidden="true"></i></div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <h5>Ustawienia produktu</h5>
          <Row>
            <Col xl={{ span: 6 }}>
              <Form onSubmit={(e: any) => e.preventDefault()}>
                <Form.Label>Nagłówek</Form.Label>
                <Form.Control
                  className="form-control-alternative"
                  placeholder="Nagłówek nad produktem"
                  type="text"
                  onChange={(e: any) => setProductHeader(`${e.target.value ? e.target.value : ""}`)}
                  name="productHeader"
                  defaultValue={productHeader}
                  onKeyDown={handleKeyDown}
                  onBlur={handleBlur}
                />
              </Form>
              <Form onSubmit={(e: any) => e.preventDefault()}>
                <Form.Label>Tytuł</Form.Label>
                <Form.Control
                  className="form-control-alternative"
                  placeholder="Tytuł produktu"
                  type="text"
                  onChange={(e: any) => setTitle(`${e.target.value ? e.target.value : ""}`)}
                  name="title"
                  defaultValue={title}
                  onKeyDown={handleKeyDown}
                  onBlur={handleBlur}
                />
              </Form>
              <Form onSubmit={(e: any) => e.preventDefault()}>
                <Form.Label>Podtytuł</Form.Label>
                <Form.Control
                  className="form-control-alternative"
                  placeholder="Podtytuł produktu"
                  type="text"
                  onChange={(e: any) => setSubtitle(`${e.target.value ? e.target.value : ""}`)}
                  name="subtitle"
                  defaultValue={subtitle}
                  onKeyDown={handleKeyDown}
                  onBlur={handleBlur}
                />
              </Form>
              <Form>
                <Form.Label>Zdjęcie/grafika produktu</Form.Label>
                <Dropzone
                  imagePreviewUrl={mainImage}
                  onFileDelete={() => onFileDelete()}
                  onFileAdded={(e: any) => onFileAdded(e)}
                  disabled={false}
                />
              </Form>
            </Col>
            <Col xl={{ span: 6 }}>
              <Form onSubmit={(e: any) => e.preventDefault()}>
                <Form.Label>Cena</Form.Label>
                <Form.Control
                  className="form-control-alternative"
                  placeholder="Cena produktu"
                  type="text"
                  onChange={(e: any) => setPrice(`${e.target.value ? e.target.value : ""}`)}
                  name="price"
                  defaultValue={price}
                  onKeyDown={handleKeyDown}
                  onBlur={handleBlur}
                />
              </Form>
              <Form>
                <Form.Label>Opis</Form.Label>
                <Form.Control
                  className="form-control-alternative"
                  placeholder="Opis produktu ..."
                  as="textarea"
                  rows={6}
                  onChange={(e: any) => setDescription(`${e.target.value ? e.target.value : ""}`)}
                  name="description"
                  defaultValue={description}
                  onKeyDown={handleKeyDown}
                  onBlur={handleBlur}
                />
              </Form>
            </Col>
          </Row>
          <br />
          <h5>Ustawienia formularza na email</h5>
          <Row>
            <Col xl={{ span: 6 }}>
              <Form onSubmit={(e: any) => e.preventDefault()}>
                <Form.Label>Nagłówek</Form.Label>
                <Form.Control
                  className="form-control-alternative"
                  placeholder="Nagłówek nad formularzem"
                  type="text"
                  onChange={(e: any) => setLeadmagnetoutputHeader(`${e.target.value ? e.target.value : ""}`)}
                  name="leadmagnetoutputHeader"
                  defaultValue={leadmagnetoutputHeader}
                  onKeyDown={handleKeyDown}
                  onBlur={handleBlur}
                />
              </Form>
              <Form onSubmit={(e: any) => e.preventDefault()}>
                <Form.Label>Tytuł</Form.Label>
                <Form.Control
                  className="form-control-alternative"
                  placeholder="Tytuł formularza"
                  type="text"
                  onChange={(e: any) => setMailFormTitle(`${e.target.value ? e.target.value : ""}`)}
                  name="mailFormTitle"
                  defaultValue={mailFormTitle}
                  onKeyDown={handleKeyDown}
                  onBlur={handleBlur}
                />
              </Form>
              <Form onSubmit={(e: any) => e.preventDefault()}>
                <Form.Label>Opis</Form.Label>
                <Form.Control
                  className="form-control-alternative"
                  placeholder="Opis formularza ..."
                  as="textarea"
                  rows={4}
                  onChange={(e: any) => setMailFormDescription(`${e.target.value ? e.target.value : ""}`)}
                  name="mailFormDescription"
                  defaultValue={mailFormDescription}
                  onKeyDown={handleKeyDown}
                  onBlur={handleBlur}
                />
              </Form>
            </Col>
            <Col xl={{ span: 6 }}>
              <Form onSubmit={(e: any) => e.preventDefault()}>
                <Form.Label>Zgoda na przetwarzanie</Form.Label>
                <Form.Control
                  className="form-control-alternative"
                  placeholder="Zgoda na przetwarzanie, którą będzie aceptował użytkownik"
                  as="textarea"
                  rows={4}
                  onChange={(e: any) => setLeadmagnetoutputAcceptLabel(`${e.target.value ? e.target.value : ""}`)}
                  name="leadmagnetoutputAcceptLabel"
                  defaultValue={leadmagnetoutputAcceptLabel}
                  onKeyDown={handleKeyDown}
                  onBlur={handleBlur}
                />
              </Form>
              <Form onSubmit={(e: any) => e.preventDefault()}>
                <Form.Label>Wiadomość w mailu</Form.Label>
                <Form.Control
                  className="form-control-alternative"
                  placeholder="Wiadomość, która zostanie przekazana po wypełnieniu formularza ..."
                  as="textarea"
                  rows={6}
                  onChange={(e: any) => setMailFormMessage(`${e.target.value ? e.target.value : ""}`)}
                  name="mailFormMessage"
                  defaultValue={mailFormMessage}
                  onKeyDown={handleKeyDown}
                  onBlur={handleBlur}
                />
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  )
}

export default LeadMagnetOutputItem;