import React, { useState } from "react";
import "./entity-config-form.scss";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import DeleteSuggestlyEntityModal from "./delete-suggestly-entity-modal";


const EntityConfigForm: React.FC = () => {
  const [openDeleteModal, setopenDeleteModal] = useState(false)

  const openModal = () => {
    setopenDeleteModal(true);
    setTimeout(() => {
      setopenDeleteModal(false);
    }, 10);
  }

  return (
    <div className="entity-config-form">
      <DeleteSuggestlyEntityModal showModal={openDeleteModal} />
      <Card className="inputs-group-main">
        <Card.Header>
          Strefa niebezpieczna
        </Card.Header>
        <Card.Body>
          <Row className="settingsRowable">
            <Col xl={{ span: 8 }} className="settingsRowable_descriptionWrapper">
              <p><strong>Usuń projekt</strong></p>
              <p>Kiedy usuniesz projekt, nie będzie odwrotu. Bądź ostrożny.</p>
            </Col>
            <Col xl={{ span: 4 }} className="text-right settingsRowable_buttons">
              <Button onClick={openModal} size="sm" variant="outline-danger">Usuń projekt</Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  )
}

export default EntityConfigForm;