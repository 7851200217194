import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import "./home-panel.scss";
import { RoutePath } from "../../../../../route-paths";
import { Link } from "react-router-dom";
import { getAllOutputs } from "../../../../redux/productState/productsState.actions";
import { getAllInputs } from "../../../../redux/inputsState/inputsState.actions";
import { getAllWages } from "../../../../redux/wagesState/wagesState.actions";
import { selectInputsWages } from "../../../../redux/wagesState/wagesState.selectors";
import { InputWageDto } from "../../../../dto/wageDto";
import Jumbotron from "react-bootstrap/Jumbotron";

const ConfigurationRequirements: React.FC<any> = () => {
  const dispatch = useDispatch();
  const inputsWages = useSelector(selectInputsWages, shallowEqual) as Array<InputWageDto>

  const [firstLoad, setfirstLoad] = useState(true);

  useEffect(() => {
    if (firstLoad && inputsWages && inputsWages.length > 0) {
      setfirstLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputsWages])

  useEffect(() => {
    dispatch(getAllOutputs());
    dispatch(getAllInputs());
    dispatch(getAllWages());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {inputsWages ? (inputsWages.length > 0 && inputsWages[0].outputs && inputsWages[0].outputs.length > 0 && inputsWages.filter(x => x.variants && x.variants.length === 0).length === 0 ?
        null
        :
        <>
          <br />
          <Jumbotron className="delay-loading">
            <Row>
              <Col>
                <h2>Skonfiguruj projekt</h2>
                <br/>
                <p>
                  Konfiguracja projektów w Suggestly składa się z 3 etapów, z czego 2 pierwsze to definiowanie pytań i sugestii, a ostatni to kalibracja. Nawigację po poszczególnych etapach umożliwia menu po lewej stronie.
                </p>
                <p>
                  Aby kalibracja była możliwa, powinno być zdefiniowane:
                </p>
                <ul>
                  <li>minimum jedno pytanie z wariantami odpowiedzi</li>
                  <li>minimum jedna sugestia np. produkt lub usługa</li>
                </ul>
                <p>
                  Po uzupełnieniu powyższych kroków, przejdź do sekcji kalibracja, aby określić kiedy ma się pojawiać konkretna sugestia.
                </p>
                <br />
                <div className="fastNav">
                  {inputsWages.length > 0 ? (
                    inputsWages[0].variants && inputsWages[0].variants.length > 0 ? (
                      inputsWages.find(x => !(x.variants && x.variants.length > 0)) ? <Link to={RoutePath.PYTANIA_PANEL}>
                        <Button variant="secondary">Każde pytanie powinno mieć zdefiniowane warianty odpowiedzi</Button>
                      </Link> : null
                    ) :
                      <Link to={RoutePath.PYTANIA_PANEL}>
                        <Button variant="secondary">Uzupełnij warianty</Button>
                      </Link>
                  ) :
                    <Link to={RoutePath.PYTANIA_PANEL}>
                      <Button variant="secondary">Uzupełnij pytania</Button>
                    </Link>
                  }
                  {inputsWages.length > 0 && inputsWages[0].outputs && inputsWages[0].outputs.length > 0 ? null :
                    <Link to={RoutePath.PRODUKTY_PANEL}>
                      <Button variant="primary">Uzupełnij sugestie</Button>
                    </Link>
                  }
                </div>
                <br />
                <div className="fastNav fastFooter">
                  <p>
                    Jeśli nie masz pewności co należy zrobić, skontaktuj się z administracją.
                  </p>
                  <Link to={RoutePath.CONTACT_SETTINGS} className={"supportBtn"}>
                    <Button variant="link" className={"supportBtn"} >Pomoc</Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Jumbotron>
          <br /></>) : null}
    </>
  )
}

export default ConfigurationRequirements;