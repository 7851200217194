import React from "react";
import "./product-item.scss";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GrowthList from "./growthList.svg";

interface Props {
  isActive: boolean
}

const OutputTypeCardGrowthMList: React.FC<Props> = (props: Props) => {
  return (
    <Card className={props.isActive ? "outputTypeScenario active" : "outputTypeScenario"}>
      <Row>
        <Col xl={{ span: 6 }}>
          <h5>Zwiększ listę mailingową</h5>
          <Row>
            <Col xl={{ span: 4 }} className="align-self-center">
              <img src={GrowthList} alt="GrowthList" className="scenarioImg" />
            </Col>
            <Col xl={{ span: 8 }} className="scenarioDescription align-self-center">
              Scenariusz dla osób, które nie posiadają  magnet leadu. Użytkownik przed poznaniem wyniku zostanie poproszony o email. Produkt zostanie wyświetlony dopiero po potwierdzeniu emaila.
              </Col>
          </Row>
        </Col>
        <Col xl={{ span: 5, offset: 1 }} className="scenarioOptions align-self-center">
          <Row>
            <Col xl={{ span: 5 }} className="scenarioOption align-self-center colorful">
              <div>Formularz email</div>
            </Col>
            <Col xl={{ span: 2 }} className="scenarioIcon">
              <i className="fa fa-arrow-right" aria-hidden="true"></i>
            </Col>
            <Col xl={{ span: 5 }}>
              <div className="scenarioOption">Produkt</div>
              <div className="scenarioIconVertical">
                <i className="fa fa-plus" aria-hidden="true"></i>
              </div>
              <div className="scenarioOption colorful">Link</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}

export default OutputTypeCardGrowthMList;