import InputsStateTypes from "./inputsState.types";
import { InputDto } from "../../dto/inputDto";
import { VariantTypeEnum } from "../../dto/variantTypeEnum";

export const addInput = (name: string) => {
  return {
    type: InputsStateTypes.ADD_CATEGORY,
    payload: name
  };
};

export const addVariant = (inputId: string, variantType: VariantTypeEnum) => {
  return {
    type: InputsStateTypes.ADD_VARIANT,
    payload: { inputId: inputId, variantType: variantType } as { inputId: string, variantType: VariantTypeEnum }
  };
};

export const deleteVariant = (variantId: string) => {
  return {
    type: InputsStateTypes.DELETE_VARIANT,
    payload: variantId
  };
}

export const updateVariant = (variantId: string, image: File | null, variantText: string | null) => {
  return {
    type: InputsStateTypes.UPDATE_VARIANT,
    payload: { variantId: variantId, image: image, variantText: variantText }
  };
};

export const updateVariantTitle = (variantId: string, variantTitle: string) => {
  return {
    type: InputsStateTypes.UPDATE_VARIANT_TITLE,
    payload: { variantId: variantId, variantTitle: variantTitle }
  };
};

export const updateInputImage = (inputId: string, image: File | null) => {
  return {
    type: InputsStateTypes.UPDATE_CATEGORYIMAGE,
    payload: { inputId: inputId, image: image }
  };
};

export const updateInputDescription = (inputId: string, description: string) => {
  return {
    type: InputsStateTypes.UPDATE_CATEGORYDESCRIPTION,
    payload: { inputId: inputId, description: description }
  };
};

export const updateInputTitle = (inputId: string, title: string) => {
  return {
    type: InputsStateTypes.UPDATE_CATEGORYTITLE,
    payload: { inputId: inputId, title: title }
  };
};

export const updateInputName = (inputId: string, name: string) => {
  return {
    type: InputsStateTypes.UPDATE_CATEGORYNAME,
    payload: { inputId: inputId, name: name }
  };
};

export const deleteInput = (inputId: string) => {
  return {
    type: InputsStateTypes.DELETE_CATEGORY,
    payload: inputId
  };
};

export const deleteInputSuccess = (inputId: string) => {
  return {
    type: InputsStateTypes.DELETE_CATEGORY_SUCCESS,
    payload: inputId
  };
};

export const updateInputSuccess = (inputDto: InputDto) => {
  return {
    type: InputsStateTypes.UPDATE_CATEGORY_SUCCESS,
    payload: inputDto
  };
};

export const addInputSuccess = (inputDto: InputDto) => {
  return {
    type: InputsStateTypes.ADD_CATEGORY,
    payload: inputDto
  };
};

export const getAllInputs = () => {
  return {
    type: InputsStateTypes.GET_ALL_CATEGORIES
  };
};

export const getAllInputsSuccess = (inputs: Array<InputDto>) => {
  return {
    type: InputsStateTypes.GET_ALL_CATEGORIES_SUCCESS,
    payload: inputs
  };
};