import { ApiMethodDto } from "./api-method-dto";
import { ApiPath } from "./apiPath";
import { callApi } from "./api-call-service";
import { PublicContactFormDto } from "../dto/publicContactFormDto";

export class AuthController {
  static async sendMessageToSupport(email: string, message: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const body = { email: email, message: message } as PublicContactFormDto;
      const options = {
        body: body,
        headers: { "Content-Type": "application/json" },
        method: "POST",
        expectedStatus: 200,
        noToast: true
      } as ApiMethodDto;


      await callApi(options.method, ApiPath.auth_sendMessageToSupport, options)
        .then((res: any) => {
          if (res.details.status === 200) {
            resolve(true);
          }
          else {
            reject(false);
          }
        })
        .catch(() => {
          reject(false);
        })
    });
  }
}