import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { ApiMethodDto, UrlParam } from "../../api/api-method-dto";
import { ApiPath } from "../../api/apiPath";
import { callApi } from "../../api/api-call-service";
import { SagaInputDto } from "../../api/saga-input-dto";
import EmbedStateActionTypes from "./embedState.types";
import { SuggestlyStore } from "../store.types";
import { EmbedDomainDto } from "../../dto/embedDomainDto";
import { ModalDto } from "../../dto/modalDto";
import { RoutePath } from "../../../route-paths";
import SuggestlyEntityStateActionTypes from "../suggestlyEntityState/suggestlyEntityState.types";

function* getEmbedDomainsAsync() {
  const token = yield select((state: SuggestlyStore) => state.user.token);
  const suggestlyEntityId = yield select((state: SuggestlyStore) => state.suggestlyEntityState.currentEntitySuggestlyId);

  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
    params: {
      name: "suggestlyEntityId",
      value: suggestlyEntityId
    } as UrlParam
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.embed_getEmbedDomains, options);

    yield put({
      type: EmbedStateActionTypes.GET_EXTERNALDOMAINS_SUCCESS,
      payload: res.data as Array<EmbedDomainDto>
    });

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* deleteEmbedDomainAsync(sagaInput_embedDomainId: SagaInputDto<string>) {
  const token = yield select((state: SuggestlyStore) => state.user.token);

  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
    params: [{
      name: "embedDomainId",
      value: sagaInput_embedDomainId.payload
    },
    ] as Array<UrlParam>
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.embed_deleteEmbedDomain, options);

    yield put({
      type: EmbedStateActionTypes.GET_EXTERNALDOMAINS_SUCCESS,
      payload: res.data as Array<EmbedDomainDto>
    });

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* addEmbedDomainAsync(sagaInput_origin: SagaInputDto<string>) {
  const token = yield select((state: SuggestlyStore) => state.user.token);
  const suggestlyEntityId = yield select((state: SuggestlyStore) => state.suggestlyEntityState.currentEntitySuggestlyId);

  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: [200, 201, 423],
    params: [{
      name: "origin",
      value: sagaInput_origin.payload
    }, {
      name: "suggestlyEntityId",
      value: suggestlyEntityId
    },
    ] as Array<UrlParam>
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.embed_addEmbedDomain, options);
    if (res.details.status === 423) {
      yield put({
        type: SuggestlyEntityStateActionTypes.SHOW_GLOBAL_MODAL_SUCCESS,
        payload: {
          title: "Przekroczono limit",
          description: "Twoje konto nie pozwala na osadzenie widżetu na większej liczbie domen. Aby zwiększyć limit, zaktualizuj swój plan cenowy, lub skontaktuj się z administratorem.",
          buttonTitle: "Zmień plan",
          path: RoutePath.SUBSCRIPTION_SETTINGS
        } as ModalDto
      });
    } else {
      yield put({
        type: EmbedStateActionTypes.GET_EXTERNALDOMAINS_SUCCESS,
        payload: res.data as Array<EmbedDomainDto>
      });
    }
  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* watchGetEmbedDomains() {
  yield takeLatest(EmbedStateActionTypes.GET_EXTERNALDOMAINS as any, getEmbedDomainsAsync);
}

function* watchDeleteEmbedDomain() {
  yield takeLatest(EmbedStateActionTypes.DELETE_EXTERNALDOMAIN as any, deleteEmbedDomainAsync);
}

function* watchAddEmbedDomain() {
  yield takeLatest(EmbedStateActionTypes.ADD_EXTERNALDOMAIN as any, addEmbedDomainAsync);
}

export default function* EmbedStateSagas() {
  yield all([
    call(watchGetEmbedDomains),
    call(watchDeleteEmbedDomain),
    call(watchAddEmbedDomain)]);
}