import { CompanyInformationsDto } from '../../dto/companyInformationsDto';
import CompanyInformationsStateTypes from './companyInformationsState.types';

const INITIAL_STATE = {
  companyInformations: {
    addressLineOne: "",
    addressLineTwo: "",
    addressPostalCode: "",
    addressCity: "",
    addressCountry: ""
  } as CompanyInformationsDto,
}

const companyInformationsStateReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CompanyInformationsStateTypes.GET_ADDRESSINFORMATIONS_SUCCESS:
      return {
        ...state,
        companyInformations: action.payload
      }
    case CompanyInformationsStateTypes.COMPANYINFORMATIONS_CLEAR_STATE:
      return {
        ...state,
        companyInformations: {
          addressLineOne: "",
          addressLineTwo: "",
          addressPostalCode: "",
          addressCity: "",
          addressCountry: ""
        }
      }
    default: {
      return state;
    }
  }
}

export default companyInformationsStateReducer;