import { createSelector } from 'reselect';
import { SuggestlyStore } from '../store.types';

const inputsState = (state: SuggestlyStore) => state.inputsState;

export const selectInputs = createSelector(
  [inputsState],
  inputsState => { 
    return inputsState.inputs
  }
);

export const selectInput = (index: number) => createSelector(
  [inputsState],
  inputsState => { 
    return inputsState.inputs[index]
  }
);