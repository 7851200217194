import React from "react";
import "./spinner.scss";

interface Props {
  isVisible: boolean;
  children?: React.ReactChild;
}

const Spinner: React.FC<Props> = (props: Props) => {
  return (
    props.isVisible ?
      <div className="spinnerWrapper">
        <div className="spinner"></div>
        <div className="children">
          {props.children}
        </div>
      </div> : <></>
  )
}

export default Spinner;
