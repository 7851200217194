import React from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { selectGlobalModal } from "../../redux/suggestlyEntityState/suggestlyEntityState.selectors";
import { ModalDto } from "../../dto/modalDto";
import { hideGlobalModal } from "../../redux/suggestlyEntityState/suggestlyEntityState.actions";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

const GlobalModal: React.FC = () => {
  const globalModal = useSelector(selectGlobalModal, shallowEqual) as ModalDto;
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideGlobalModal());
  }
  const handleRedirect = () => {
    setTimeout(() => {
      dispatch(hideGlobalModal());
    }, 50);
  }

  return (
    <>
      {globalModal ?
        <Modal show={globalModal.isVisible} onHide={handleClose}>
          <Modal.Header closeButton>
            <h5>{globalModal.title}</h5>
          </Modal.Header>
          <Modal.Body>
            {globalModal.description}
          </Modal.Body>
          <Modal.Footer>
            <Link to={globalModal.path as string}>
              <Button variant="secondary" onClick={handleRedirect}>
                {globalModal.buttonTitle}
              </Button>
            </Link>
            <Button variant="primary" onClick={handleClose}>
              Anuluj
            </Button>
          </Modal.Footer>
        </Modal >
        : null}
    </>
  )
}

export default GlobalModal;
