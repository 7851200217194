import React from "react";
import "./realize-coupon-settings.scss";
import Helmet from "react-helmet";
import { Container } from "react-bootstrap";

import MainSection from "../../../../components/main-section/main-section";
import CouponCodeForm from "../../../../components/couponCodeForm/couponCodeForm";


const RealizeCouponSettings: React.FC<any> = () => {
  return (
    <>
      <Helmet>
        <title>{'Modyfikacja konta | Suggestly'}</title>
      </Helmet>
      <div className="subscription-settings">
        <Container fluid={false}>
          <MainSection
            title="Panel modyfikacji konta"
            subtitle="Tutaj możesz dokonać modyfikacji, rozszerzającej uprawnienia twojego konta"
          />
          <CouponCodeForm />
        </Container>
      </div>
    </>
  )
}

export default RealizeCouponSettings;