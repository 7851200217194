import EmbedStateTypes from "./embedState.types";
import { EmbedDomainDto } from "../../dto/embedDomainDto";

export const getEmbedDomains = (suggestlyEntityId?: string) => {
  return {
    type: EmbedStateTypes.GET_EXTERNALDOMAINS,
    payload: suggestlyEntityId
  };
};

export const getEmbedDomainsSuccess = (embedDomains: Array<EmbedDomainDto>) => {
  return {
    type: EmbedStateTypes.GET_EXTERNALDOMAINS_SUCCESS,
    payload: embedDomains
  };
};

export const deleteEmbedDomain = (embedDomainId: string) => {
  return {
    type: EmbedStateTypes.DELETE_EXTERNALDOMAIN,
    payload: embedDomainId
  };
};

export const addEmbedDomain = (origin: string) => {
  return {
    type: EmbedStateTypes.ADD_EXTERNALDOMAIN,
    payload: origin
  };
};