import { createSelector } from 'reselect';
import { SuggestlyStore } from '../store.types';

const productsState = (state: SuggestlyStore) => state.productsState;

export const selectOutputs = createSelector(
  [productsState],
  productsState => { 
    return productsState.outputs
  }
);

export const selectOutput = (index: number) => createSelector(
  [productsState],
  productsState => { 
    return productsState.outputs[index]
  }
);