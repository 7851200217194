import React, { useState, useEffect } from "react";
import "./embed-form.scss";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useSelector, shallowEqual } from "react-redux";
import { selectCurrentSuggestlyEntityId } from "../../redux/suggestlyEntityState/suggestlyEntityState.selectors";
import { ApiRootPath } from "../../api/apiRootPath";
import { ToastsStore } from "react-toasts";

interface Props {
}

const EmbedForm: React.FC<Props> = () => {
  const [embedCode, setEmbedCode] = useState("")

  const currentSuggestlyEntityId = useSelector(selectCurrentSuggestlyEntityId, shallowEqual) as string;
  useEffect(() => {
    setEmbedCode(
      `<script>
      (function (w, d, s, o, f, js, fjs) {
      w['Suggestly-Widget'] = o; w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
      js = d.createElement(s), fjs = d.getElementsByTagName(s)[0]; js.id = o; js.src = f; js.async = 1;
      fjs.parentNode.insertBefore(js, fjs); }(window, document, 'script', 'suggestly', '${ApiRootPath.rootPath}script/widget.js'));
      suggestly('init', { targetElementId: 'suggestlyRootWidget', se_id: '${currentSuggestlyEntityId}' });
</script>`
    );
  }, [currentSuggestlyEntityId])

  const handleKeyDown = (e: any) => {
    e.preventDefault();
    if (e.target.name === "embedCode") {
      e.target.select();
      //copy and select
      document.execCommand('copy');
      ToastsStore.success('Your embed code has been copied to clipboard.');
    }
  }
  return (
    <div className="embed-form">
      <Card>
        <Card.Header>
          Integracja
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xl={{ span: 12 }}>
              <p>Umieść poniższy skrypt w kodzie swojej strony internetowej, na której będzie wykorzystywane Suggestly.</p>
              <Form onSubmit={(e: any) => e.preventDefault()}>
                <Form.Label>Skrypt osadzenia</Form.Label>
                <Form.Control
                  onChange={(e: any) => e.preventDefault()}
                  name="embedCode"
                  defaultValue={embedCode}
                  onClick={handleKeyDown}
                  className="form-control-alternative"
                  as="textarea"
                  rows={7}
                // disabled
                />
              </Form>
              <br />
              <Form onSubmit={(e: any) => e.preventDefault()}>
                <Form.Label>Przykładowy przycisk aktywujący osadzone Suggestly</Form.Label>
                <Form.Control
                  onChange={(e: any) => e.preventDefault()}
                  name="embedCode"
                  defaultValue={`<button onclick="suggestly('openwidget', true);">
    Open Widget
</button>`}
                  onClick={handleKeyDown}
                  className="form-control-alternative"
                  as="textarea"
                  rows={3}
                // disabled
                />
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  )
}

export default EmbedForm;