import CompanyInformationsStateTypes from "./companyInformationsState.types";
import { CompanyInformationsEnum } from "../../dto/companyInformationsEnum";
import { CompanyInformationsDto } from "../../dto/companyInformationsDto";

export const updateCompanyInformations = (value: string, type: CompanyInformationsEnum) => {
  return {
    type: CompanyInformationsStateTypes.UPDATE_ADDRESSFIELD,
    payload: { value: value, type: type }
  };
};

export const getCompanyInformations = () => {
  return {
    type: CompanyInformationsStateTypes.GET_ADDRESSINFORMATIONS
  };
};

export const getCompanyInformationsSuccess = (companyInformations: CompanyInformationsDto) => {
  return {
    type: CompanyInformationsStateTypes.GET_ADDRESSINFORMATIONS_SUCCESS,
    payload: companyInformations
  };
};