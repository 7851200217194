import React, { useState, useRef } from "react";
import "./product-item.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import { addOutput } from "../../redux/productState/productsState.actions";
import OutputTypeCardAffiliation from "./output-type-card-affiliation";
import OutputTypeCardLeadMagnet from "./output-type-card-leadMagnet";
import OutputTypeCardGrowthMList from "./output-type-card-growthMList";
import { OutputTypeEnum } from "../../dto/outputTypeEnum";
import Container from "react-bootstrap/Container";
import OutsideWrapperDetector from "../outside-wrapper-detector/outside-wrapper-detector.component";

interface Props {
  index: number
}

const ProductItemCreator: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("")
  const [outputType, setOutputType] = useState(null as unknown as OutputTypeEnum)
  const [step, setStep] = useState(0);
  const bottomRef = useRef<any>()
  const topRef = useRef<any>()

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' && name !== "") {
      handleCreate();
    }
  }

  const handleCancel = () => {
    setStep(0);
    setOutputType(null as unknown as OutputTypeEnum);
  }

  const handleCreate = () => {
    if (name !== "") {
      dispatch(addOutput(name, outputType as OutputTypeEnum));
      setStep(0);
      setOutputType(null as unknown as OutputTypeEnum);
      setTimeout(() => {
        bottomRef.current.scrollIntoView({ behavior: "smooth" })
      }, 100);
    }
  }

  const handleAddNewProduct = () => {
    setStep(1);
    setTimeout(() => {
      bottomRef.current.scrollIntoView({ behavior: "smooth" })
    }, 100);
  }

  const handleSetOutputType = (outputTypeArg: OutputTypeEnum) => {
    setStep(2);
    setOutputType(outputTypeArg);
  }

  const handleBack = () => {
    setStep(1);
    setOutputType(null as unknown as OutputTypeEnum);
  }

  return (
    <div className="product-item">
      <div ref={topRef} />
      {
        step > 0 ?
          <>
            <OutsideWrapperDetector handleClose={handleCancel}>
              <Container className="scenario_options">
                <div className="scenario_nav_wrapper">
                  {outputType && step > 1 ?
                    <div style={{ textAlign: 'left' }} className="deleteItemWrapper back" onClick={handleBack}>
                      <i className="fa fa-chevron-left" aria-hidden="true"></i>
                    </div> : null}
                  <div style={{ textAlign: 'right' }} className="deleteItemWrapper cancel" onClick={handleCancel}>
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </div>
                </div>
                {outputType && step > 1 ?
                  <>
                    <div className="outPutDescriptionInside">
                      {outputType === OutputTypeEnum.Affiliation ?
                        <OutputTypeCardAffiliation isActive={true} /> : (
                          outputType === OutputTypeEnum.LeadMagnet ?
                            <OutputTypeCardLeadMagnet isActive={true} /> : (
                              outputType === OutputTypeEnum.MailListGrowth ?
                                <OutputTypeCardGrowthMList isActive={true} /> : null
                            )
                        )}
                    </div>
                    < br />
                    <h4 className="scenarioHeader">Nazwij swoją sugestię</h4>
                    <Row>
                      <Col xl={{ span: 6, offset: 3 }}>
                        <Form onSubmit={(e: any) => e.preventDefault()}>
                          <InputGroup className="input-group-alternative" size="lg">
                            <Form.Control
                              className="form-control-alternative"
                              placeholder="Nazwa suggestii (pole widoczne tylko u Ciebie)"
                              autoFocus
                              onChange={(e: any) => setName(`${e.target.value}`)}
                              name="name"
                              size="lg"
                              type="text"
                              onKeyDown={handleKeyDown}
                            />
                          </InputGroup>
                        </Form>
                        <div style={{ textAlign: 'right' }} >
                          <Button variant="secondary" onClick={handleCreate}>Dodaj sugestię</Button>
                        </div>
                      </Col>
                    </Row>
                  </>
                  :
                  <>
                    <h4 className="scenarioHeader">Co chesz osiągnąć? <br/> Wybierz scenariusz, który spełnia Twoje cele</h4>
                    <div onClick={() => handleSetOutputType(OutputTypeEnum.Affiliation)}>
                      <OutputTypeCardAffiliation isActive={outputType === OutputTypeEnum.Affiliation} />
                    </div>
                    <div onClick={() => handleSetOutputType(OutputTypeEnum.LeadMagnet)}>
                      <OutputTypeCardLeadMagnet isActive={outputType === OutputTypeEnum.LeadMagnet} />
                    </div>
                    <div onClick={() => handleSetOutputType(OutputTypeEnum.MailListGrowth)}>
                      <OutputTypeCardGrowthMList isActive={outputType === OutputTypeEnum.MailListGrowth} />
                    </div>
                    <br />
                  </>
                }
              </Container>
            </OutsideWrapperDetector>
          </> :
          <div className="circleAddButton_with_dropdown">
            <Button variant="primary" size="sm" onClick={handleAddNewProduct}><i className="fa fa-plus" aria-hidden="true"></i>	&nbsp;&nbsp;dodaj nową sugestię</Button>
          </div>
      }
      <div ref={bottomRef} />
    </div >
  )
}

export default ProductItemCreator;