import React from "react";
import Helmet from "react-helmet";
import { Container } from "react-bootstrap";
import MainSection from "../../../../components/main-section/main-section";
import ContactForm from "../../../../components/contact-form/contact-form";


const ContactSettings: React.FC<any> = () => {
  return (
    <>
      <Helmet>
        <title>{'Contact | Suggestly'}</title>
      </Helmet>
      <div className="personal-data-settings">
        <Container fluid={false}>
          <MainSection
            title="Kontakt"
            subtitle="Masz pytania? Napisz do nas, postaramy się pomóc."
          />
          <ContactForm />
        </Container>
      </div>
    </>
  )
}

export default ContactSettings;