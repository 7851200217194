import React, { useState, useEffect } from "react";
import "./domains-config-form.scss";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { addEmbedDomain, deleteEmbedDomain, getEmbedDomains } from "../../redux/embedState/embedState.actions";
import { selectEmbedDomains } from "../../redux/embedState/embedState.selectors";
import { EmbedDomainDto } from "../../dto/embedDomainDto";
import Button from "react-bootstrap/Button";

interface Props {
}

const DomainsConfigForm: React.FC<Props> = (props: Props) => {
  const [embedDomain, setEmbedDomain] = useState("")

  const embedDomains = useSelector(selectEmbedDomains, shallowEqual) as Array<EmbedDomainDto>;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEmbedDomains());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      if (e.target.name === "embedDomain") {
        e.target.blur();
        setEmbedDomain("");
      }
    }
  }

  const handleAdd = (e: any) => {
    dispatch(addEmbedDomain(embedDomain));
    setEmbedDomain("");
  }

  const handleDelete = (embedDomain: EmbedDomainDto) => {
    dispatch(deleteEmbedDomain(embedDomain.id));
  }

  return (
    <div className="domains-config-form">
      <Card className="inputs-group-main">
        <Card.Header>
          Ustawienia domen
        </Card.Header>
        <Card.Body>
          <Form onSubmit={(e: any) => e.preventDefault()}>
            {embedDomains && embedDomains.length > 0 ?
              <>
                <Form.Label>Twoje domeny:</Form.Label>
                <div className="customBedgeWrapper">
                  {embedDomains.map((embedDomain: EmbedDomainDto) => {
                    return <div key={embedDomain.id} className="customBedge">
                      <a href={embedDomain.origin} target="_blank" rel="noopener noreferrer">{embedDomain.origin}</a>
                      <i className="fa fa-trash-o" aria-hidden="true" onClick={() => handleDelete(embedDomain)}></i></div>
                  })}
                </div></>
              : <p>Aby osadzić Suggestly na swojej stronie, podaj domenę.</p>
            }
          </Form>
          <Row>
            <Col xl={{ span: 6 }}>
              <Form onSubmit={(e: any) => e.preventDefault()}>
                <Form.Label>Dodaj domenę</Form.Label>
                <div className="inputWrapper">
                  <Form.Control
                    className="form-control-alternative"
                    placeholder="Domena główna"
                    type="text"
                    onChange={(e: any) => setEmbedDomain(`${e.target.value ? e.target.value : ""}`)}
                    name="embedDomain"
                    defaultValue={embedDomain}
                    onKeyDown={handleKeyDown}
                  />
                  <Button size='sm' variant="primary" onClick={handleAdd}>Dodaj</Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  )
}

export default DomainsConfigForm;