import React, { useState, useEffect, useRef } from "react";
import "./navbar-top.scss";
import Navbar from "react-bootstrap/Navbar";
import LogoImg from "./../../assets/logo.svg";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import DropdownItem from "react-bootstrap/DropdownItem";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { signOut } from "../../redux/user/user.actions";
import { selectSuggestlyEntities, selectCurrentUser } from "../../redux/user/user.selectors";
import { SuggestlyEntityDto } from "../../dto/suggestlyEntityDto";
import { selectCurrentSuggestlyEntityId } from "../../redux/suggestlyEntityState/suggestlyEntityState.selectors";
import { setCurrentSuggestlyEntityIdSuccess, getClientActions } from "../../redux/suggestlyEntityState/suggestlyEntityState.actions";
import CreateSuggestlyEntityModal, { openCreateSuggestlyEntityModal } from "./create-suggestly-entity-modal";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import { getAllOutputs } from "../../redux/productState/productsState.actions";
import { getAllInputs } from "../../redux/inputsState/inputsState.actions";
import { getAllWages } from "../../redux/wagesState/wagesState.actions";
import { RoutePath } from "../../../route-paths";
import { getEmbedDomains } from "../../redux/embedState/embedState.actions";
import { selectCompanyInformations } from "../../redux/companyInformationsState/companyInformationsState.selectors";
import { getCompanyInformations } from "../../redux/companyInformationsState/companyInformationsState.actions";
import { ApiRootPath } from "../../api/apiRootPath";
declare var suggestlyWidgetContainer: any;

const NavbarTop: React.FC<any> = (props: any & RouteComponentProps) => {
  const suggestlyEntites = useSelector(selectSuggestlyEntities, shallowEqual);
  const currentSuggestlyEntityId = useSelector(selectCurrentSuggestlyEntityId, shallowEqual);
  const user = useSelector(selectCurrentUser, shallowEqual);
  const companyInformations = useSelector(selectCompanyInformations, shallowEqual);
  const widgetContainerRef = useRef<any>();

  const dispatch = useDispatch();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [toggleSelectEntityOpen, setToggleSelectEntityOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);
  const toggleSelectEntity = () => setToggleSelectEntityOpen(prevState => !prevState);
  const handleShow = () => openCreateSuggestlyEntityModal();


  useEffect(() => {
    dispatch(getCompanyInformations());

    let suggestlyRootWidgetElem = document.getElementById('suggestlyRootWidget');
    if (suggestlyRootWidgetElem) {
      suggestlyRootWidgetElem.remove();
      suggestlyRootWidgetElem = null;
    }

    const embedCode =
      `(function (w, d, s, o, f, js, fjs) {
      w['Suggestly-Widget'] = o; w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
      js = d.createElement(s), fjs = d.getElementsByTagName(s)[0]; js.id = o; js.src = f; js.async = 1;
      fjs.parentNode.insertBefore(js, fjs); }(window, document, 'script', 'suggestlyWidgetContainer', '${ApiRootPath.rootPath}script/widget.js'));
      suggestlyWidgetContainer('init', { targetElementId: 'suggestlyRootWidget', se_id: '${currentSuggestlyEntityId}' });`;

      let suggestlyScriptRoot = document.getElementById('suggestlyScriptRoot');
      if(suggestlyScriptRoot && currentSuggestlyEntityId){
        (suggestlyScriptRoot as HTMLScriptElement).text = embedCode;
        suggestlyWidgetContainer('init', { targetElementId: 'suggestlyRootWidget', se_id: `${currentSuggestlyEntityId}` });
      }

      return () => {
        let suggestlyScriptRoot = document.getElementById('suggestlyScriptRoot');
        if(suggestlyScriptRoot){
          (suggestlyScriptRoot as HTMLScriptElement).text = '';
        }
        var oldElem = document.getElementById('suggestlyWidgetContainer');
        if (oldElem) {
          oldElem.remove();
          oldElem = null;
        }
      }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSuggestlyEntityId])


  const handleLiveButton = () => {
    suggestlyWidgetContainer('openwidget', true);
  }

  useEffect(() => {
    if (currentSuggestlyEntityId === "" && suggestlyEntites && suggestlyEntites.length > 0) {
      dispatch(setCurrentSuggestlyEntityIdSuccess(suggestlyEntites[0].id));
    }
    else if (currentSuggestlyEntityId !== "") {
      dispatch(setCurrentSuggestlyEntityIdSuccess(currentSuggestlyEntityId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSuggestlyEntityId, suggestlyEntites])

  const handleSignOut = async (e: any) => {
    e.preventDefault();
    dispatch(signOut());
  };

  const handleSelectSuggestlyEntity = (e: any) => {
    e.preventDefault();
    dispatch(setCurrentSuggestlyEntityIdSuccess(e.target.id));
    dispatch(getAllOutputs());
    dispatch(getAllInputs());
    dispatch(getAllWages());
    dispatch(getEmbedDomains());
    dispatch(getClientActions());
  }

  const SelectEntity = () => <div className="selectEntityWrapper">
    <CreateSuggestlyEntityModal />
    <Dropdown show={toggleSelectEntityOpen} onToggle={toggleSelectEntity}>
      <DropdownToggle id="careta" variant="outline-secondary">
        <div className="dropdownSectionWrapper_item">
          {suggestlyEntites.find(x => x.id === currentSuggestlyEntityId) ? suggestlyEntites.find(x => x.id === currentSuggestlyEntityId)!.name : "default"}
        </div>
      </DropdownToggle>
      <DropdownMenu>
        {suggestlyEntites ? suggestlyEntites.map((suggestlyEntity: SuggestlyEntityDto, index: number) => {
          return (
            <DropdownItem key={index} id={suggestlyEntity.id} onClick={handleSelectSuggestlyEntity}>{suggestlyEntity.name}</DropdownItem>
          )
        }) : null}
        <Dropdown.Divider />
        <DropdownItem onClick={handleShow}>STWÓRZ NOWY<i className="fa fa-plus-square-o" aria-hidden="true" /></DropdownItem>
      </DropdownMenu>
    </Dropdown>
  </div>

  return (
    <div className={props.isDisabled ? "navbarTop navbarTop--disabled " : "navbarTop"}>
      <Navbar bg="light">
        <Link to={RoutePath.HOME_PANEL}>
          <div className="brand">
            <img src={LogoImg} alt="LogoImg" />
          </div>
        </Link>
        {props.isPanelSwitch && props.isDisabled !== true ?
          <>
            <div ref={widgetContainerRef}></div>
            <div className="liveButton" onClick={handleLiveButton}>
              <i className="fa fa-eye" area-hidden="true"></i> &nbsp;Podgląd projektu {suggestlyEntites.find(x => x.id === currentSuggestlyEntityId) ? suggestlyEntites.find(x => x.id === currentSuggestlyEntityId)!.name : "default"}
            </div>
          </>
          : null}
        <Dropdown show={dropdownOpen} onToggle={toggle} className="actionBtn" alignRight={true}>
          <DropdownToggle id="caret">
            <div className="actionBtnInsideWrapper">
              <p>Zalogowany</p>
              {companyInformations && companyInformations.userFirstname && companyInformations.userLastname ?
                <p>{companyInformations ? companyInformations.userFirstname + ' ' + companyInformations.userLastname : null}</p> :
                <p>{user ? user.email : null} </p>
              }
            </div>
            <i className="fa fa-user-circle" aria-hidden="true"></i>
          </DropdownToggle>
          <DropdownMenu >
            <div className="accountMenuDropdown">
              <div className="userSectionWrapper">
                <div className="userAvatarWrapper">
                  <i className="fa fa-user-circle" aria-hidden="true"></i>
                </div>
                {companyInformations && companyInformations.userFirstname && companyInformations.userLastname ?
                  <p className="userName">{companyInformations.userFirstname + ' ' + companyInformations.userLastname}</p>
                  : null}
                <p className="userEmail">{user ? user.email : null} </p>
              </div>
              {props.isDisabled ? null : <div className="dropdownSectionWrapper">
                <div className="dropdownSectionWrapper_title">
                  <strong>
                    Aktualny projekt
                  </strong>
                </div>
                <div className="dropdownSectionWrapper_itemWrapper">
                  <SelectEntity />
                </div>
              </div>}
              <Dropdown.Divider />
              <div className="dropdownSection">
                <Link to={RoutePath.HOME_SETTINGS}>
                  <div className="dropdownSectionWrapperCol">
                    <div className="dropdownSectionWrapper_title"><i className="fa fa-cog" aria-hidden="true" /></div>
                    <div className="dropdownSectionWrapper_item">Ustawienia konta</div>
                  </div>
                </Link>
                <Link to={RoutePath.PERSONAL_DATA_SETTINGS}>
                  <div className="dropdownSectionWrapperCol">
                    <div className="dropdownSectionWrapper_title"><i className="fa fa-address-card" aria-hidden="true" /></div>
                    <div className="dropdownSectionWrapper_item">Dane do faktury</div>
                  </div>
                </Link>
              </div>
              <Dropdown.Divider />
              <DropdownItem className="align-center" onClick={handleSignOut}><i className="fa fa-sign-out" aria-hidden="true" /> Wyloguj</DropdownItem>
            </div>
          </DropdownMenu>
        </Dropdown>
      </Navbar>
    </div >
  )
}

export default withRouter(NavbarTop);