import { ApiMethodDto, UrlParam } from "./api-method-dto";
import { ApiPath } from "./apiPath";
import { StripePlanIdEnum } from "../dto/stripe-plan-id-enum";
import { callApi } from "./api-call-service";
import { store } from "../redux/store";
import { CompanyInformationsDto } from "../dto/companyInformationsDto";
import UserActionTypes from "../redux/user/user.types";
import history from '../../history';
import { RoutePath } from "../../route-paths";
import { UserDto } from "../dto/user-dto";

declare var Stripe: any;

export class PaymentController {
  static async subscribe(stripePlanIdEnum: StripePlanIdEnum): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const token = (store.getState().user as UserDto).token;

      const options = {
        headers: { Authorization: token },
        method: "GET",
        expectedStatus: 200,
        params: { name: "plan", value: stripePlanIdEnum }
      } as ApiMethodDto;


      const companyInformations = store.getState().companyInformationsState.companyInformations as CompanyInformationsDto;
      if (!(companyInformations.addressCity && companyInformations.addressCountry && companyInformations.addressLineOne && companyInformations.addressPostalCode && companyInformations.userFirstname && companyInformations.userLastname)) {
        reject();
      }
      else {
        await callApi(options.method, ApiPath.payment_subscribe, options)
          .then((res: any) => {
            if (res.details.status === options.expectedStatus) {
              store.dispatch({
                type: UserActionTypes.SET_ISWAITINGFORNEWROLESACTIVE_SUCCESS,
                payload: { isActive: true, text: "Twoja płatność aktualnie jest weryfikowana" }
              });
              const stripe = Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);//process.env.REACT_APP_STRIPE_PUBLIC_KEY as string)
              stripe.redirectToCheckout({
                sessionId: res.data.id
              })

              resolve();
            }
          })
      }
    }
    );
  }

  static async updatePlan(stripePlanIdEnum: StripePlanIdEnum): Promise<void> {
    return new Promise(async (resolve) => {
      const token = (store.getState().user as UserDto).token;

      const options = {
        headers: { Authorization: token },
        method: "GET",
        expectedStatus: 200,
        params: { name: "plan", value: stripePlanIdEnum }
      } as ApiMethodDto;

      await callApi(options.method, ApiPath.payment_updatePlan, options)
        .then((res: any) => {
          if (res.details.status === options.expectedStatus) {
            // TODO aktualizacja stora (tej roli), jakieś info na frontendzie (np. toast)

            store.dispatch({
              type: UserActionTypes.SET_ISWAITINGFORNEWROLESACTIVE_SUCCESS,
              payload: { isActive: true, text: "Twój plan jest aktualizowany" }
            });

            history.push(RoutePath.HOME_PAYMENTCHECKOUT);

            resolve();
          }
        })
    });
  }

  static async updateCard(): Promise<void> {
    return new Promise(async (resolve) => {
      const token = (store.getState().user as UserDto).token;

      const options = {
        headers: { Authorization: token },
        method: "GET",
        expectedStatus: 200,
      } as ApiMethodDto;

      await callApi(options.method, ApiPath.payment_updateCard, options)
        .then((res: any) => {
          if (res.details.status === options.expectedStatus) {
            const stripe = Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);//process.env.REACT_APP_STRIPE_PUBLIC_KEY as string)

            store.dispatch({
              type: UserActionTypes.SET_ISWAITINGFORNEWROLESACTIVE_SUCCESS,
              payload: { isActive: true, text: "Twoje konto jest aktualizowane" }
            });

            stripe.redirectToCheckout({
              sessionId: res.data.id
            })

            resolve();
          }
        })
    });
  }

  static async unsubscribe(): Promise<void> {
    return new Promise(async (resolve) => {
      const token = (store.getState().user as UserDto).token;

      const options = {
        headers: { Authorization: token },
        method: "GET",
        expectedStatus: 200,
      } as ApiMethodDto;

      await callApi(options.method, ApiPath.payment_unsubscribe, options)
        .then((res: any) => {
          if (res.details.status === options.expectedStatus) {
            // TODO jakieś info na frontendzie (np. toast)
            store.dispatch({ type: UserActionTypes.GET_USERROLES });

            store.dispatch({
              type: UserActionTypes.SET_ISWAITINGFORNEWROLESACTIVE_SUCCESS,
              payload: { isActive: true, text: "Twoje subskrybcja jest anulowana" }
            });

            history.push(RoutePath.HOME_PAYMENTCHECKOUT);

            resolve();
          }
        })
    });
  }

  static async RealizeCoupon(coupon: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const token = (store.getState().user as UserDto).token;

      const options = {
        headers: { Authorization: token },
        method: "GET",
        expectedStatus: 200,
        params: [{ name: 'coupon', value: coupon }] as Array<UrlParam>
      } as ApiMethodDto;

      await callApi(options.method, ApiPath.payment_realizeCoupon, options)
        .then((res: any) => {
          if (res.details.status === options.expectedStatus) {
            
            store.dispatch({ type: UserActionTypes.GET_USERROLES });
            store.dispatch({
              type: UserActionTypes.SET_ISWAITINGFORNEWROLESACTIVE_SUCCESS,
              payload: { isActive: true, text: "Status konta jest aktualizowany" }
            });

            history.push(RoutePath.HOME_PAYMENTCHECKOUT);

            resolve();
          }
        })
        .catch(() => {
          reject();
        })
    });
  }
}