const AccountSettingsStateTypes = {
  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
  GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATIONS_SUCCESS',

  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHANGE_NOTIFICATIONS: 'CHANGE_NOTIFICATIONS',

  SEND_MESSAGETOSUPPORT: 'SEND_MESSAGETOSUPPORT',

  ACCOUNTSETTINGS_CLEAR_STATE: 'ACCOUNTSETTINGS_CLEAR_STATE'
};

export default AccountSettingsStateTypes;