import { createSelector } from 'reselect';
import { SuggestlyStore } from '../store.types';

const selectUser = (state: SuggestlyStore) => state.user;

export const selectCurrentUser = createSelector(
  [selectUser],
  user => user
);

export const selectSuggestlyEntitity = (id: string) => createSelector(
  [selectUser],
  user => {
    return user.suggestlyEntities.find(x => x.id === id)
  }
);

export const selectSuggestlyEntities = createSelector(
  [selectUser],
  user => user.suggestlyEntities
);

export const selectIsWaitingForNewRolesActive = createSelector(
  [selectUser],
  user => user.isWaitingForNewRolesActive
);

export const selectIsSignUpTutorialActive = createSelector(
  [selectUser],
  user => user.isSignUpTutorialActive
);

