import React, { useState } from "react";
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import "./sign-upin-form.scss";
import Button from "react-bootstrap/Button";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import Form from "react-bootstrap/Form";
import { resetPassword } from "../../redux/user/user.actions";
import Spinner from "react-bootstrap/Spinner";

const ResetPasswordForm = () => {
  const [email, setEmail] = useState('');
  const [disableButton, setdisableButton] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser, shallowEqual);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setdisableButton(true);
    setTimeout(() => {
      setdisableButton(false);
    }, 3500);
    dispatch(resetPassword(email));
  };

  return (
    <form className="sign-upin-form sign-up-email-form" onSubmit={handleSubmit} >
      <>
        <Form.Label>Email</Form.Label>
        <Form.Control
          name="email"
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setEmail(e.target.value)}
          required
        />
        <div className="btnWrapper">
          <Button type="submit" disabled={((user && user.token) || disableButton) as boolean}>
            Resetuj {disableButton ? <Spinner animation="border" /> : null}</Button>
        </div>
      </>
    </form >
  )
}

export default ResetPasswordForm;