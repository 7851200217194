

import React, { useState, useEffect } from "react";
import "./navbar-top.scss";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import { useDispatch } from "react-redux";
import { addSuggestlyEntity } from "../../redux/suggestlyEntityState/suggestlyEntityState.actions";

var isCreateSuggestlyEntityModalOpen = false;
export const openCreateSuggestlyEntityModal = () => {
  isCreateSuggestlyEntityModalOpen = true;
}

const CreateSuggestlyEntityModal = () => {
  const [showSuggestlyEntityModal, setShowSuggestlyEntityModal] = useState(isCreateSuggestlyEntityModalOpen);
  const handleCloseSuggestlyEntityModal = () => setShowSuggestlyEntityModal(false)

  useEffect(() => {
    if (isCreateSuggestlyEntityModalOpen === true) {
      setShowSuggestlyEntityModal(true);
      isCreateSuggestlyEntityModalOpen = false;
    }
  }, [showSuggestlyEntityModal])

  const dispatch = useDispatch();
  const [name, setName] = useState("")


  const handleCreate = (e: any) => {
    if (name !== "") {
      dispatch(addSuggestlyEntity(name));
      setShowSuggestlyEntityModal(false);
    }
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' && name !== "") {
      dispatch(addSuggestlyEntity(name));
      setShowSuggestlyEntityModal(false);
    }
  }

  return (
    <>
      <Modal show={showSuggestlyEntityModal} onHide={handleCloseSuggestlyEntityModal} size="sm" className="create-suggestly-entity-modal">
        <Modal.Header closeButton>
          <h5>Tworzenie nowego projektu</h5>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e: any) => e.preventDefault()}>
            <Form.Label>Nazwij swój nowy projekt</Form.Label>
            <FormGroup>
              <Form.Control
                placeholder="Nazwa (pole widoczne tylko u Ciebie)"
                autoFocus
                onChange={(e: any) => setName(`${e.target.value}`)}
                name="name"
                type="text"
                onKeyDown={handleKeyDown}
              />
            </FormGroup>
          </Form>
        </Modal.Body>
        {/* <Modal.Footer> */}
        <Button variant="secondary" onClick={handleCreate}>
          Stwórz
        </Button>
        <Button variant="primary" onClick={handleCloseSuggestlyEntityModal}>
          Anuluj
        </Button>
        {/* </Modal.Footer> */}
      </Modal>
    </>
  )
};

export default CreateSuggestlyEntityModal;