import InputsStateTypes from './inputsState.types';
import { InputDto } from '../../dto/inputDto';

const INITIAL_STATE = {
  inputs: [] as Array<InputDto>,
}

const inputsStateReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case InputsStateTypes.ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        inputs: [...state.inputs, action.payload]
      }
    case InputsStateTypes.DELETE_CATEGORY_SUCCESS:
      let inputsFiltered = state.inputs.filter(x => x.id !== action.payload);
      return {
        ...state,
        inputs: inputsFiltered
      }
    case InputsStateTypes.GET_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        inputs: action.payload
      }
    case InputsStateTypes.UPDATE_CATEGORY_SUCCESS:
      let indexOfCat = state.inputs.findIndex(x => x.id === action.payload.id);
      let inputsUpdated = state.inputs;
      inputsUpdated[indexOfCat] = action.payload;
      return {
        ...state,
        inputs: inputsUpdated
      }
    case InputsStateTypes.CATEGORIES_CLEAR_STATE:
      return {
        ...state,
        inputs: [] as Array<InputDto>
      }
    default: {
      return state;
    }
  }
}

export default inputsStateReducer;