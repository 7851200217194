

import React, { ReactNode } from "react";
import "./main-section.scss";

interface Props {
  title?: string,
  subtitle?: string | ReactNode,
  smallTitle?: string,
  children?: ReactNode,
}

const MainSection: React.FC<Props> = (props: Props) => {
  const { title, smallTitle, subtitle, children } = props;

  return (
    <section className="main-section">
      <div className="main-header">
        {title ? <h2>{title}</h2> : null}
        {smallTitle ? <h3>{smallTitle}</h3> : null}
        {subtitle ? <p>{subtitle}</p> : null}
      </div>
      {children ? <div className="main-body">{children}</div> : null}
    </section>
  )
}

export default MainSection;
