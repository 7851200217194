import LeadsStateTypes from "./leadsState.types";
import { LeadDto } from "../../dto/leadDto";


export const deleteLead = (leadId: string) => {
  return {
    type: LeadsStateTypes.DELETE_LEAD,
    payload: leadId
  };
};

export const deleteLeadSuccess = (leadId: string) => {
  return {
    type: LeadsStateTypes.DELETE_LEAD_SUCCESS,
    payload: leadId
  };
};


export const getAllLeads = (page: number, count: number) => {
  return {
    type: LeadsStateTypes.GET_ALL_LEADS,
    payload: { page: page, count: count }
  };
};

export const getAllLeadsSuccess = (leads: Array<LeadDto>) => {
  return {
    type: LeadsStateTypes.GET_ALL_LEADS_SUCCESS,
    payload: leads
  };
};