import React from "react";
import "./spinner.scss";

interface Props {
  isVisible: boolean
}

const MicroSpinner: React.FC<Props> = (props: Props) => {
  return (
    props.isVisible ?
      <div className="microSpinner"></div> : <></>
  )
}

export default MicroSpinner;
