import AccountSettingsStateTypes from './accountSettingsState.types';
import { NewsletterSettingsDto } from '../../dto/newsletterSettingsDto';

const INITIAL_STATE = {
  notifications: {} as NewsletterSettingsDto
}

const accountSettingsStateReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case AccountSettingsStateTypes.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload
      }
    case AccountSettingsStateTypes.ACCOUNTSETTINGS_CLEAR_STATE:
      return {
        ...state,
        notifications: {} as NewsletterSettingsDto
      }
    default: {
      return state;
    }
  }
}

export default accountSettingsStateReducer;