import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { ToastsStore } from "react-toasts";
import { ApiMethodDto, UrlParam } from "../../api/api-method-dto";
import { ApiPath } from "../../api/apiPath";
import { callApi } from "../../api/api-call-service";
import { SagaInputDto } from "../../api/saga-input-dto";
import InputsStateActionTypes from "./inputsState.types";
import { InputDto } from "../../dto/inputDto";
import { VariantTypeEnum } from "../../dto/variantTypeEnum";
import { SuggestlyStore } from "../store.types";
import SuggestlyEntityStateActionTypes from "../suggestlyEntityState/suggestlyEntityState.types";
import { ModalDto } from "../../dto/modalDto";
import { RoutePath } from "../../../route-paths";

function* addInputAsync(sagaInput_name: SagaInputDto<string>) {
  const token = yield select(state => state.user.token);
  const suggestlyEntityId = yield select((state: SuggestlyStore) => state.suggestlyEntityState.currentEntitySuggestlyId);

  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: [201, 423],
    params: [{
      name: "suggestlyEntityId",
      value: suggestlyEntityId
    }, {
      name: "name",
      value: sagaInput_name.payload
    }] as Array<UrlParam>
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.input_addInput, options);
    if (res.details.status === 423) {
      yield put({
        type: SuggestlyEntityStateActionTypes.SHOW_GLOBAL_MODAL_SUCCESS,
        payload: {
          title: "Osiągnięto limit",
          description: "Aktualnie Suggestly nie umożliwia na stworzenie większej liczby możliwych sugestii dla pojedynczego projektu. Jeśli chcesz się dowiedzieć więcej, skontaktuj się z administracją.",
          buttonTitle: "Kontakt",
          path: RoutePath.CONTACT_SETTINGS
        } as ModalDto
      });
    } else {
      yield put({
        type: InputsStateActionTypes.ADD_CATEGORY_SUCCESS,
        payload: res.data as InputDto
      });

      ToastsStore.success('Question created successfully');
    }
  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* addVariantAsync(sagaInput_variant: SagaInputDto<{ inputId: string, variantType: VariantTypeEnum }>) {
  const token = yield select(state => state.user.token);
  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: [201, 423],
    params: [{
      name: "inputId",
      value: sagaInput_variant.payload.inputId
    }, {
      name: "variantType",
      value: sagaInput_variant.payload.variantType
    }] as Array<UrlParam>
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.input_addVariant, options);
    if (res.details.status === 423) {
      yield put({
        type: SuggestlyEntityStateActionTypes.SHOW_GLOBAL_MODAL_SUCCESS,
        payload: {
          title: "Osiągnięto limit",
          description: "Aktualnie Suggestly nie umożliwia na stworzenie większej liczby wariantów dla pojedynczego pytania. Jeśli chcesz się dowiedzieć więcej, skontaktuj się z administracją.",
          buttonTitle: "Kontakt",
          path: RoutePath.CONTACT_SETTINGS
        } as ModalDto
      });
    } else {
      yield put({
        type: InputsStateActionTypes.UPDATE_CATEGORY_SUCCESS,
        payload: res.data as InputDto
      });

      ToastsStore.success('created');
    }
  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* getAllInputsAsync() {
  const token = yield select(state => state.user.token);
  const suggestlyEntityId = yield select((state: SuggestlyStore) => state.suggestlyEntityState.currentEntitySuggestlyId);

  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
    params: {
      name: "suggestlyEntityId",
      value: suggestlyEntityId
    } as UrlParam
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.input_getAllInputs, options);

    yield put({
      type: InputsStateActionTypes.GET_ALL_CATEGORIES_SUCCESS,
      payload: res.data as Array<InputDto>
    });
  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* deleteInputAsync(sagaInput_inputId: SagaInputDto<string>) {
  const token = yield select(state => state.user.token);
  const options = {
    headers: { Authorization: token },
    method: "DELETE",
    expectedStatus: 200,
    params: {
      name: "inputId",
      value: sagaInput_inputId.payload
    } as UrlParam
  } as ApiMethodDto;

  try {
    yield call(callApi, "delete", ApiPath.input_deleteInput, options);

    yield put({
      type: InputsStateActionTypes.DELETE_CATEGORY_SUCCESS,
      payload: sagaInput_inputId.payload
    });

    ToastsStore.warning('deleted');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* deleteVariantAsync(sagaInput_variantId: SagaInputDto<string>) {
  const token = yield select(state => state.user.token);
  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
    params: {
      name: "variantId",
      value: sagaInput_variantId.payload
    } as UrlParam
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.input_deleteVariant, options);

    yield put({
      type: InputsStateActionTypes.UPDATE_CATEGORY_SUCCESS,
      payload: res.data as InputDto
    });

    ToastsStore.warning('deleted');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* updateInputDescriptionAsync(sagaInput_description: SagaInputDto<{ inputId: string, description: string }>) {
  const token = yield select(state => state.user.token);
  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
    params: [{
      name: "inputId",
      value: sagaInput_description.payload.inputId
    }, {
      name: "description",
      value: sagaInput_description.payload.description
    }] as Array<UrlParam>
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.input_updateInputDescription, options);

    yield put({
      type: InputsStateActionTypes.UPDATE_CATEGORY_SUCCESS,
      payload: res.data as InputDto
    });

    ToastsStore.success('saved');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* updateVariantTitleAsync(sagaInput_variant: SagaInputDto<{ variantId: string, variantTitle: string | null }>) {
  const token = yield select(state => state.user.token);

  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
    params: [{
      name: "variantId",
      value: sagaInput_variant.payload.variantId
    }, {
      name: "variantTitle",
      value: sagaInput_variant.payload.variantTitle
    }] as Array<UrlParam>
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.input_updateVariantTitle, options);

    yield put({
      type: InputsStateActionTypes.UPDATE_CATEGORY_SUCCESS,
      payload: res.data as InputDto
    });

    ToastsStore.success('saved');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* updateVariantAsync(sagaInput_variant: SagaInputDto<{ variantId: string, image: File | null, variantText: string | null }>) {
  const token = yield select(state => state.user.token);
  let formData = new FormData();

  formData.append('variantId', sagaInput_variant.payload.variantId);
  if (sagaInput_variant.payload.image)
    formData.append('file', sagaInput_variant.payload.image!);

  if (sagaInput_variant.payload.variantText)
    formData.append('variantText', sagaInput_variant.payload.variantText!);

  const options = {
    body: formData,
    headers: { Authorization: token },
    method: "POST",
    expectedStatus: 200,
    datatype: "FormData"
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "post", ApiPath.input_updateVariant, options);

    yield put({
      type: InputsStateActionTypes.UPDATE_CATEGORY_SUCCESS,
      payload: res.data as InputDto
    });

    ToastsStore.success('saved');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* updateInputImageAsync(sagaInput_image: SagaInputDto<{ inputId: string, image: File }>) {
  const token = yield select(state => state.user.token);
  let formData = new FormData();

  formData.append('file', sagaInput_image.payload.image);
  formData.append('inputId', sagaInput_image.payload.inputId);

  const options = {
    body: formData,
    headers: { Authorization: token },
    method: "POST",
    expectedStatus: 200,
    datatype: "FormData"
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "post", ApiPath.input_updateInputImage, options);

    yield put({
      type: InputsStateActionTypes.UPDATE_CATEGORY_SUCCESS,
      payload: res.data as InputDto
    });

    ToastsStore.success('saved');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* updateInputTitleAsync(sagaInput_title: SagaInputDto<{ inputId: string, title: string }>) {
  const token = yield select(state => state.user.token);
  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
    params: [{
      name: "inputId",
      value: sagaInput_title.payload.inputId
    }, {
      name: "title",
      value: sagaInput_title.payload.title
    }] as Array<UrlParam>
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.input_updateInputTitle, options);

    yield put({
      type: InputsStateActionTypes.UPDATE_CATEGORY_SUCCESS,
      payload: res.data as InputDto
    });

    ToastsStore.success('saved');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* updateInputNameAsync(sagaInput_name: SagaInputDto<{ inputId: string, name: string }>) {
  const token = yield select(state => state.user.token);
  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
    params: [{
      name: "inputId",
      value: sagaInput_name.payload.inputId
    }, {
      name: "name",
      value: sagaInput_name.payload.name
    }] as Array<UrlParam>
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.input_updateInputName, options);

    yield put({
      type: InputsStateActionTypes.UPDATE_CATEGORY_SUCCESS,
      payload: res.data as InputDto
    });

    ToastsStore.success('saved');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* watchUpdateInputDescription() {
  yield takeLatest(InputsStateActionTypes.UPDATE_CATEGORYDESCRIPTION as any, updateInputDescriptionAsync);
}

function* watchUpdateInputImage() {
  yield takeLatest(InputsStateActionTypes.UPDATE_CATEGORYIMAGE as any, updateInputImageAsync);
}

function* watchUpdateVariant() {
  yield takeLatest(InputsStateActionTypes.UPDATE_VARIANT as any, updateVariantAsync);
}

function* watchUpdateVariantTitle() {
  yield takeLatest(InputsStateActionTypes.UPDATE_VARIANT_TITLE as any, updateVariantTitleAsync);
}

function* watchUpdateInputTitle() {
  yield takeLatest(InputsStateActionTypes.UPDATE_CATEGORYTITLE as any, updateInputTitleAsync);
}

function* watchUpdateInputName() {
  yield takeLatest(InputsStateActionTypes.UPDATE_CATEGORYNAME as any, updateInputNameAsync);
}

function* watchAddInput() {
  yield takeLatest(InputsStateActionTypes.ADD_CATEGORY as any, addInputAsync);
}

function* watchAddVariant() {
  yield takeLatest(InputsStateActionTypes.ADD_VARIANT as any, addVariantAsync);
}

function* watchDeleteInput() {
  yield takeLatest(InputsStateActionTypes.DELETE_CATEGORY as any, deleteInputAsync);
}

function* watchDeleteVariant() {
  yield takeLatest(InputsStateActionTypes.DELETE_VARIANT as any, deleteVariantAsync);
}
function* watchGetAllInputs() {
  yield takeLatest(InputsStateActionTypes.GET_ALL_CATEGORIES as any, getAllInputsAsync);
}

export default function* InputsStateSagas() {
  yield all([
    call(watchAddInput),
    call(watchAddVariant),
    call(watchUpdateVariant),
    call(watchDeleteInput),
    call(watchDeleteVariant),
    call(watchUpdateVariantTitle),
    call(watchGetAllInputs),
    call(watchUpdateInputName),
    call(watchUpdateInputTitle),
    call(watchUpdateInputImage),
    call(watchUpdateInputDescription)]);
}