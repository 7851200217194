import React, { ReactNode, useState } from "react";
import "./speedo-meter.scss";
import { WageDto } from "../../dto/wageDto";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import SpeedoMeterSelector from "./speedo-meter-selector";

interface Props {
  wage: WageDto;
  children: ReactNode;
}

const SpeedoMeter: React.FC<Props> = (props: Props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <div className="speedo-meter">
      <Dropdown drop={"up"} show={dropdownOpen} onToggle={toggle} className={'speedo-meter-dropdown'}>
        <DropdownToggle id={props.wage.id}>
          <div className={dropdownOpen ? "isActive" : ""}>
            {props.children}
          </div>
        </DropdownToggle>
        <DropdownMenu>
          <div className="dropdown-menu-insider">
            {dropdownOpen ? <SpeedoMeterSelector wage={props.wage.value < 0 ? ({ ...props.wage, value: 0 } as WageDto) : props.wage} /> : null}
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default SpeedoMeter;