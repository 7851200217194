import UserActionTypes from './user.types';

const INITIAL_STATE = {
  error: null,
  userId: null,
  token: null,
  email: null,
  suggestlyEntities: null,
  roles: null,
  rolePlan: null,
  signIn: null,
  expiresEnd: null,
  expiresStart: null,
  lastRequestTime: null,
  isWaitingForNewRolesActive: null,
  isSignUpTutorialActive: null
}

const userReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case UserActionTypes.AUTH_FAILURE:
      return {
        ...state,
        error: action.payload,
        token: null
      }
    case UserActionTypes.AUTH_SIGNUPTUTORIALFINISH:
      return {
        ...state,
        isSignUpTutorialActive: null
      }
    case UserActionTypes.AUTH_SIGNUP:
      return {
        ...state,
        userId: action.payload.userId,
        email: action.payload.email,
        token: action.payload.token,
        expiresStart: action.payload.expiresStart,
        expiresEnd: action.payload.expiresEnd,
        roles: action.payload.roles,
        rolePlan: action.payload.rolePlan,
        suggestlyEntities: action.payload.suggestlyEntities,
        isSignUpTutorialActive: true
      }
    case UserActionTypes.GET_ALLSUGGESTLYENTITIES_SUCCESS:
      return {
        ...state,
        suggestlyEntities: action.payload
      }
    case UserActionTypes.SIGN_IN:
      return {
        ...state,
        signIn: null
      }
    case UserActionTypes.SET_ISWAITINGFORNEWROLESACTIVE_SUCCESS:
      return {
        ...state,
        isWaitingForNewRolesActive: action.payload
      }
    case UserActionTypes.GET_USERROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload.roles,
        rolePlan: action.payload.rolePlan,
      }
    case UserActionTypes.SIGN_OUT_SUCCESS:
      return {
        ...state,
        error: null,
        userId: null,
        token: null,
        email: null,
        roles: null,
        rolePlan: null,
        signIn: null,
        expiresEnd: null,
        expiresStart: null,
        lastRequestTime: null,
        suggestlyEntities: null,
        isWaitingForNewRolesActive: null,
        isSignUpTutorialActive: null
      }
    case UserActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        userId: action.payload.userId,
        token: action.payload.token,
        email: action.payload.email,
        roles: action.payload.roles,
        rolePlan: action.payload.rolePlan,
        expiresStart: action.payload.expiresStart,
        expiresEnd: action.payload.expiresEnd,
        lastRequestTime: action.payload.lastRequestTime,
        suggestlyEntities: action.payload.suggestlyEntities
      }
    case UserActionTypes.TOKEN_REFRESH_SUCCESS:
      return {
        ...state,
        token: action.payload.token
      }
    case UserActionTypes.TOKEN_REFRESH_FAILURE:
      return {
        ...state,
        user: null,
        message: action.payload.message
      }
    case UserActionTypes.UPDATE_REQUEST_TIME:
      return {
        ...state,
        lastRequestTime: action.payload
      }
    default: {
      return state;
    }
  }
}

export default userReducer;