import ProductsStateTypes from './productsState.types';
import { OutputDto } from '../../dto/outputDto';

const INITIAL_STATE = {
  outputs: [] as Array<OutputDto>,
}

const productsStateReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ProductsStateTypes.ADD_OUTPUT_SUCCESS:
      return {
        ...state,
        outputs: [...state.outputs, action.payload]
      }
    case ProductsStateTypes.DELETE_OUTPUT_SUCCESS:
      let productsFiltered = state.outputs.filter(x => x.id !== action.payload);
      return {
        ...state,
        outputs: productsFiltered
      }
    case ProductsStateTypes.GET_ALL_OUTPUTS_SUCCESS:
      return {
        ...state,
        outputs: action.payload
      }
    case ProductsStateTypes.UPDATE_OUTPUT_SUCCESS:
      let indexOfCat = state.outputs.findIndex(x => x.id === action.payload.id);
      let outputsUpdated = state.outputs;
      outputsUpdated[indexOfCat] = action.payload;
      return {
        ...state,
        outputs: outputsUpdated
      }
    case ProductsStateTypes.PRODUCTS_CLEAR_STATE:
      return {
        ...state,
        outputs: [] as Array<OutputDto>,
      }
    default: {
      return state;
    }
  }
}

export default productsStateReducer;