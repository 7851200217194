import React, { useState, useEffect } from "react";
import "../product-item/product-item.scss";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { selectNotifications } from "../../redux/accountSettingsState/accountSettingsState.selectors";
import { NewsletterSettingsDto } from "../../dto/newsletterSettingsDto";
import Switch from "../switch/switch";
import { changeNotifications, getNotifications } from "../../redux/accountSettingsState/accountSettingsState.actions";

const ChangeNotificationsForm: React.FC = () => {
  const [isAbout, setisAbout] = useState(false);
  const [isCodeebo, setisCodeebo] = useState(false);

  const notifications = useSelector(selectNotifications, shallowEqual) as NewsletterSettingsDto;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNotifications());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (notifications.aboutUpdates !== undefined)
      setisAbout(notifications.aboutUpdates);
    if (notifications.sendInformationsAboutCodeeboTeamOtherProjects !== undefined)
      setisCodeebo(notifications.sendInformationsAboutCodeeboTeamOtherProjects);
  }, [notifications, dispatch])

  const handleClickIsAbout = () => {
    let notificationsTemp = { aboutUpdates: !isAbout, sendInformationsAboutCodeeboTeamOtherProjects: isCodeebo } as NewsletterSettingsDto;
    dispatch(changeNotifications(notificationsTemp));
  }

  const handleClickIsCodeebo = () => {
    let notificationsTemp = { aboutUpdates: isAbout, sendInformationsAboutCodeeboTeamOtherProjects: !isCodeebo } as NewsletterSettingsDto;
    dispatch(changeNotifications(notificationsTemp));
  }

  return (
    <div className="product-item">
      <Card className="inputs-group-main">
        <Card.Header>
          Ustawienia notyfikacji
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xl={{ span: 10 }}>
              <Form.Label>Chcę otrzymywać wiadomości email dotyczące aktualizacji Suggestly, ważnych zmian i nowości.</Form.Label>
            </Col>
            <Col xl={{ span: 2 }} style={{ paddingTop: '10px' }}>
              <Switch
                checked={isAbout}
                onChange={() => handleClickIsAbout()}
              >
              </Switch>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xl={{ span: 10 }}>
              <Form.Label>Chcę otrzymywać informacje dotyczące pozostałych projektów tworzonych przez Codeebo.</Form.Label>
            </Col>
            <Col xl={{ span: 2 }} style={{ paddingTop: '10px' }}>
              <Switch
                checked={isCodeebo}
                onChange={() => handleClickIsCodeebo()}
              >
              </Switch>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  )
}

export default ChangeNotificationsForm;