import React, { } from "react";
import Helmet from "react-helmet";
import Container from "react-bootstrap/Container";
import MainSection from "../../../../components/main-section/main-section";
import EntityConfigForm from "../../../../components/entity-config-form/entity-config-form";


const ProjectSettingsPanel: React.FC<any> = () => {
  return (
    <>
      <Helmet>
        <title>{'Ustawienia projektu | Suggestly'}</title>
      </Helmet>
      <div className="embed-panel">
        <Container fluid={false}>
          <MainSection
            title="Ustawienia projektu"
          />
          <EntityConfigForm />
        </Container>
      </div>
    </>
  )
}

export default ProjectSettingsPanel;