import { createSelector } from 'reselect';
import { SuggestlyStore } from '../store.types';

// const companyInformationsState = (state: SuggestlyStore) => state.companyInformationsState;

// export const selectCompanyInformations = (state: SuggestlyStore) => createSelector(
//   [companyInformationsState],
//   companyInformationsState => {
//     return companyInformationsState.companyInformations
//   }
// );

const companyInformationsState = (state: SuggestlyStore) => state.companyInformationsState;

export const selectCompanyInformations = createSelector(
  [companyInformationsState],
  companyInformationsState => {
    return companyInformationsState.companyInformations
  }
);
