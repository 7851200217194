import CookiePolicyPopupTypes from './landingPageState.types';

const INITIAL_STATE = {
  signUpEmail: '' as string,
}

const landingPageStateReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CookiePolicyPopupTypes.SET_SIGNUPEMAIL:
      return {
        ...state,
        signUpEmail: action.payload
      }
    default: {
      return state;
    }
  }
}

export default landingPageStateReducer;