import React from 'react';

interface Props {
  children: React.ReactChild;
  handleClose?: any;
  closeOnClickAny?: boolean;
}

interface State {
  isMouseDownOutside: boolean;
}

export class OutsideWrapperDetector extends React.Component<Props, State> {
  private wrapperRef: React.RefObject<HTMLDivElement>;

  constructor(props: any) {
    super(props);

    this.state = {
      isMouseDownOutside: false
    }

    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick, false);
    document.addEventListener('mousedown', this.handleMouseDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
    document.removeEventListener('mousedown', this.handleMouseDown, false);
  }

  handleMouseDown = (clickEvent: MouseEvent) => {
    if (this.props.closeOnClickAny || (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(clickEvent.target as Node))) {
      this.setState({ isMouseDownOutside: true });
    }
  }

  handleOutsideClick = (clickEvent: MouseEvent) => {
    if (this.state.isMouseDownOutside && (this.props.closeOnClickAny || (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(clickEvent.target as Node)))) {
      clickEvent.preventDefault();
      this.props.handleClose();
    }
  }

  render() {
    return (
      <div ref={this.wrapperRef}>
        {this.props.children}
      </div>
    );
  }
}

export default OutsideWrapperDetector;
