import React, { useState, useEffect } from "react";
import "./billing-informations.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { CompanyInformationsDto } from "../../dto/companyInformationsDto";
import { selectCompanyInformations } from "../../redux/companyInformationsState/companyInformationsState.selectors";
import { updateCompanyInformations, getCompanyInformations } from "../../redux/companyInformationsState/companyInformationsState.actions";
import { CompanyInformationsEnum } from "../../dto/companyInformationsEnum";

interface Props {
}

const BillingInformationsForm: React.FC<Props> = (props: Props) => {
  const [userFirstname, setUserFirstname] = useState("");
  const [userLastname, setUserLastname] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyTaxId, setCompanyTaxId] = useState("");
  const [addressLineOne, setAddressLineOne] = useState("");
  const [addressLineTwo, setAddressLineTwo] = useState("");
  const [addressPostalCode, setAddressPostalCode] = useState("");
  const [addressCity, setAddressCity] = useState("");
  const [addressCountry, setAddressCountry] = useState("");

  const companyInformations = useSelector(selectCompanyInformations, shallowEqual) as CompanyInformationsDto;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompanyInformations());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (companyInformations) {
      setUserFirstname(companyInformations.userFirstname);
      setUserLastname(companyInformations.userLastname);
      setCompanyName(companyInformations.companyName);
      setCompanyTaxId(companyInformations.companyTaxId);
      setAddressLineOne(companyInformations.addressLineOne);
      setAddressLineTwo(companyInformations.addressLineTwo);
      setAddressPostalCode(companyInformations.addressPostalCode);
      setAddressCity(companyInformations.addressCity);
      setAddressCountry(companyInformations.addressCountry);
    }
  }, [companyInformations])

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      if (e.target.name === 'userFirstname' ||
        e.target.name === 'userLastname' ||
        e.target.name === 'companyName' ||
        e.target.name === 'companyTaxId' ||
        e.target.name === "addressLineOne" ||
        e.target.name === 'addressLineTwo' ||
        e.target.name === 'addressPostalCode' ||
        e.target.name === 'addressCity' ||
        e.target.name === 'addressCountry') {
        e.target.blur();
      }
    }
  }

  // const handleBlur = (e: any) => {
  //   if (e.target.name === "userFirstname") {
  //     setUserFirstname(e.target.value);
  //   }
  //   if (e.target.name === "userLastname") {
  //     setUserLastname(e.target.value);
  //   }
  //   if (e.target.name === "companyName") {
  //     setCompanyName(e.target.value);
  //   }
  //   if (e.target.name === "companyTaxId") {
  //     setCompanyTaxId(e.target.value);
  //   }
  //   if (e.target.name === "addressLineOne") {
  //     setAddressLineOne(e.target.value);
  //   }
  //   if (e.target.name === "addressLineTwo") {
  //     setAddressLineTwo(e.target.value);
  //   }
  //   if (e.target.name === "addressPostalCode") {
  //     setAddressPostalCode(e.target.value);
  //   }
  //   if (e.target.name === "addressCity") {
  //     setAddressCity(e.target.value);
  //   }
  //   if (e.target.name === "addressCountry") {
  //     setAddressCountry(e.target.value);
  //   }
  // }

  const handleBlur = (e: any) => {
    if (e.target.name === "userFirstname") {
      dispatch(updateCompanyInformations(e.target.value, CompanyInformationsEnum.userFirstname));
    }
    if (e.target.name === "userLastname") {
      dispatch(updateCompanyInformations(e.target.value, CompanyInformationsEnum.userLastname));
    }
    if (e.target.name === "companyName") {
      dispatch(updateCompanyInformations(e.target.value, CompanyInformationsEnum.companyName));
    }
    if (e.target.name === "companyTaxId") {
      dispatch(updateCompanyInformations(e.target.value, CompanyInformationsEnum.companyTaxId));
    }
    if (e.target.name === "addressLineOne") {
      dispatch(updateCompanyInformations(e.target.value, CompanyInformationsEnum.addressLineOne));
    }
    if (e.target.name === "addressLineTwo") {
      dispatch(updateCompanyInformations(e.target.value, CompanyInformationsEnum.addressLineTwo));
    }
    if (e.target.name === "addressPostalCode") {
      dispatch(updateCompanyInformations(e.target.value, CompanyInformationsEnum.addressPostalCode));
    }
    if (e.target.name === "addressCity") {
      dispatch(updateCompanyInformations(e.target.value, CompanyInformationsEnum.addressCity));
    }
    if (e.target.name === "addressCountry") {
      dispatch(updateCompanyInformations(e.target.value, CompanyInformationsEnum.addressCountry));
    }
  }

  return (
    <div className="domains-config-form">
      <Row>
        <Col xl={{ span: 6 }}>
          <Form onSubmit={(e: any) => e.preventDefault()}>
            <Form.Label>Imię</Form.Label>
            <Form.Control
              className="form-control-alternative"
              placeholder="Imię"
              type="text"
              onChange={(e: any) => setUserFirstname(`${e.target.value ? e.target.value : ""}`)}
              name="userFirstname"
              defaultValue={userFirstname}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
            />
          </Form>
        </Col>
        <Col xl={{ span: 6 }}>
          <Form onSubmit={(e: any) => e.preventDefault()}>
            <Form.Label>Nazwisko</Form.Label>
            <Form.Control
              className="form-control-alternative"
              placeholder="Nazwisko"
              type="text"
              onChange={(e: any) => setUserLastname(`${e.target.value ? e.target.value : ""}`)}
              name="userLastname"
              defaultValue={userLastname}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
            />
          </Form>
        </Col>
        <Col xl={{ span: 6 }}>
          <Form onSubmit={(e: any) => e.preventDefault()}>
            <Form.Label>Pełna nazwa firmy (opcjonalne)</Form.Label>
            <Form.Control
              className="form-control-alternative"
              placeholder="Pełna nazwa firmy"
              type="text"
              onChange={(e: any) => setCompanyName(`${e.target.value ? e.target.value : ""}`)}
              name="companyName"
              defaultValue={companyName}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
            />
          </Form>
        </Col>
        <Col xl={{ span: 6 }}>
          <Form onSubmit={(e: any) => e.preventDefault()}>
            <Form.Label>NIP (opcjonalne)</Form.Label>
            <Form.Control
              className="form-control-alternative"
              placeholder="NIP"
              type="text"
              onChange={(e: any) => setCompanyTaxId(`${e.target.value ? e.target.value : ""}`)}
              name="companyTaxId"
              defaultValue={companyTaxId}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
            />
          </Form>
        </Col>
        <Col xl={{ span: 6 }}>
          <Form onSubmit={(e: any) => e.preventDefault()}>
            <Form.Label>Adres siedziby</Form.Label>
            <Form.Control
              className="form-control-alternative"
              placeholder="Adres linia 1"
              type="text"
              onChange={(e: any) => setAddressLineOne(`${e.target.value ? e.target.value : ""}`)}
              name="addressLineOne"
              defaultValue={addressLineOne}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
            />
            <Form.Control
              className="form-control-alternative"
              placeholder="Adres linia 2"
              type="text"
              onChange={(e: any) => setAddressLineTwo(`${e.target.value ? e.target.value : ""}`)}
              name="addressLineTwo"
              defaultValue={addressLineTwo}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
            />
          </Form>
        </Col>
        <Col xl={{ span: 6 }}>
          <Form onSubmit={(e: any) => e.preventDefault()}>
            <Form.Label>Kod pocztowy</Form.Label>
            <Form.Control
              className="form-control-alternative"
              placeholder="Kod pocztowy"
              type="text"
              onChange={(e: any) => setAddressPostalCode(`${e.target.value ? e.target.value : ""}`)}
              name="addressPostalCode"
              defaultValue={addressPostalCode}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
            />
          </Form>
        </Col>
        <Col xl={{ span: 6 }}>
          <Form onSubmit={(e: any) => e.preventDefault()}>
            <Form.Label>Miasto</Form.Label>
            <Form.Control
              className="form-control-alternative"
              placeholder="City"
              type="text"
              onChange={(e: any) => setAddressCity(`${e.target.value ? e.target.value : ""}`)}
              name="addressCity"
              defaultValue={addressCity}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
            />
          </Form>
        </Col>
        <Col xl={{ span: 6 }}>
          <Form onSubmit={(e: any) => e.preventDefault()}>
            <Form.Label>Państwo</Form.Label>
            <Form.Control
              className="form-control-alternative"
              placeholder="Państwo"
              type="text"
              onChange={(e: any) => setAddressCountry(`${e.target.value ? e.target.value : ""}`)}
              name="addressCountry"
              defaultValue={addressCountry}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
            />
          </Form>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xl={{ span: 12 }}>
          <p style={{ color: "lightgray" }}>Jeżeli nie posiadasz firmy, zostaw pole "Pełna nazwa firmy" oraz "NIP" puste, a otrzymasz fakturę imienną.</p>
        </Col>
      </Row>
    </div >
  )
}

export default BillingInformationsForm;