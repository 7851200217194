import React, { ReactNode } from "react";
import "./switch-with-labels.scss";

interface Props {
  children: ReactNode,
  isLeftActive: boolean
  leftLabel: ReactNode,
  rightLabel: ReactNode
}

const SwitchWithLabels: React.FC<Props> = (props: Props) => {
  const {
    children,
    isLeftActive,
    leftLabel,
    rightLabel
  } = props;

  return (
    <div className="switchWithLabels">
      <div className={isLeftActive ? "leftLabel" : "leftLabel active"}>
        {leftLabel}
      </div>
      <div className="midLabel">
        {children}
      </div>
      <div className={isLeftActive ? "rightLabel active" : "rightLabel"}>
        {rightLabel}
      </div>
    </div>
  )
}

export default SwitchWithLabels;
