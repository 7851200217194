import React, { useState } from "react";
import "./sidenav.scss";
import ListGroup from "react-bootstrap/ListGroup";
import { RoutePath } from "../../../route-paths";
import history from '../../../history';

const SideBarAccountSettings: React.FC<any> = () => {
  const navItem = [
    { routePath: RoutePath.HOME_SETTINGS, name: "Ustawienia konta" },
    { routePath: RoutePath.PERSONAL_DATA_SETTINGS, name: "Dane do faktury" },
    { routePath: RoutePath.SUBSCRIPTION_SETTINGS, extendedItem: RoutePath.REALIZECOUPON_SETTINGS, name: "Ustawienia subskrypcji" },
    { routePath: RoutePath.CONTACT_SETTINGS, name: "Kontakt" },
  ]

  const getDefault = (): number => {
    const activeIndex = navItem.findIndex(x => x.routePath === history.location.pathname || (x.extendedItem && x.extendedItem === history.location.pathname));
    return activeIndex;
  }
  const [activeKey] = useState(getDefault)

  const setActive = (key: number, routePath: keyof RoutePath) => {
    history.push(routePath as string);
  }

  return (
    <ListGroup defaultActiveKey={activeKey} activeKey={activeKey} as="ul" className="sidenav">
      {navItem.map((item, index: number) => {
        return (
          <ListGroup.Item key={index} eventKey={index} as="li" onClick={() => setActive(index, item.routePath)}>
            {item.name}
          </ListGroup.Item>
        )
      })}

      <ListGroup.Item as="li" className={"backButton"} onClick={() => setActive(-1, RoutePath.HOME_PANEL)}>
        Wróć do panelu
          </ListGroup.Item>
    </ListGroup>
  )
}

export default SideBarAccountSettings;