import React, { useState, useEffect } from "react";
import "./input-item.scss";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "react-bootstrap/FormGroup";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Dropzone from "../drop-zone/drop-zone.component";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import DropdownItem from "react-bootstrap/DropdownItem";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import { InputDto } from "../../dto/inputDto";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { updateInputName, updateInputTitle, updateInputDescription, updateInputImage, deleteInput, addVariant } from "../../redux/inputsState/inputsState.actions";
import { ApiRootPath } from "../../api/apiRootPath";
import { selectInput } from "../../redux/inputsState/inputsState.selectors";
import { VariantDto } from "../../dto/variantDto";
import { VariantTypeEnum } from "../../dto/variantTypeEnum";
import VariantItem from "../variant-item/variant-item";

interface Props {
  index: number;
}

const InputItem: React.FC<Props> = (props: Props) => {

  const [name, setName] = useState("")
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [mainImage, setMainImage] = useState("")
  const input = useSelector(selectInput(props.index), shallowEqual) as InputDto;

  const dispatch = useDispatch();

  useEffect(() => {
    setName(input.name);
    setTitle(input.title);
    setDescription(input.description);
    if (input.mainImg != null && input.mainImg.length > 0) {
      setMainImage(ApiRootPath.rootPath + input.mainImg);
    }
    else {
      setMainImage("");
    }
  }, [input])

  const onFileDelete = () => {
    dispatch(updateInputImage(input.id, null));
  }
  const handleAddVariantImage = () => {
    dispatch(addVariant(input.id, VariantTypeEnum.Image));
  }
  const handleAddVariantText = () => {
    dispatch(addVariant(input.id, VariantTypeEnum.Text));
  }

  const onFileAdded = (item: any) => {
    dispatch(updateInputImage(input.id, item.file));
  }

  const handleDelete = (e: any) => {
    dispatch(deleteInput(input.id));
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      if (e.target.name === "name" || e.target.name === 'title' || e.target.name === 'description') {
        e.target.blur();
      }
    }
  }
  const handleBlur = (e: any) => {
    if (e.target.name === "name") {
      dispatch(updateInputName(input.id, e.target.value));
    }
    if (e.target.name === "title") {
      dispatch(updateInputTitle(input.id, e.target.value));
    }
    if (e.target.name === "description") {
      dispatch(updateInputDescription(input.id, e.target.value));
    }
  }

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  const { index } = props;

  return (
    <div className="input-item">
      <Card className="inputs-group-main">
        <Card.Header>
          <Row>
            <Col>
              <Form onSubmit={(e: any) => e.preventDefault()}>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroup.Prepend>
                      <InputGroup.Text>{index + 1}. </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      className="form-control-alternative"
                      placeholder="Nazwa pytania (pole widoczne tylko u Ciebie)"
                      type="text"
                      onChange={(e: any) => setName(`${e.target.value ? e.target.value : ""}`)}
                      name="name"
                      defaultValue={name}
                      onKeyDown={handleKeyDown}
                      onBlur={handleBlur}
                    />
                  </InputGroup>
                </FormGroup>
              </Form>
            </Col>
            <Col style={{ textAlign: 'right' }}>
              <div className="deleteItemWrapper" onClick={handleDelete}><i className="fa fa-trash" aria-hidden="true"></i></div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xl={{ span: 7 }}>
              <h5>Ustawienia pytania</h5>
              <Form onSubmit={(e: any) => e.preventDefault()}>
                <Form.Label>Treść pytania</Form.Label>
                <Form.Control
                  className="form-control-alternative"
                  placeholder="Treść pytania"
                  type="text"
                  onChange={(e: any) => setTitle(`${e.target.value ? e.target.value : ""}`)}
                  name="title"
                  defaultValue={title}
                  onKeyDown={handleKeyDown}
                  onBlur={handleBlur}
                />
              </Form>
              <Form>
                <Form.Label>Zdjęcie/grafika do pytania</Form.Label>
                <Dropzone
                  imagePreviewUrl={mainImage}
                  onFileDelete={() => onFileDelete()}
                  onFileAdded={(e: any) => onFileAdded(e)}
                  disabled={false}
                />
              </Form>
              <Form>
                <Form.Label>Opis - jaki cel ma to pytanie</Form.Label>
                <Form.Control
                  className="form-control-alternative"
                  placeholder="Dlaczego to pytanie jest ważne ..."
                  as="textarea"
                  rows={6}
                  onChange={(e: any) => setDescription(`${e.target.value ? e.target.value : ""}`)}
                  name="description"
                  defaultValue={description}
                  onKeyDown={handleKeyDown}
                  onBlur={handleBlur}
                />
              </Form>
            </Col>
            <Col xl={{ span: 5 }}>
              <h5>Warianty odpowiedzi</h5>

              {input.variants.map((item: VariantDto, index: number) => {
                return (
                  <VariantItem index={index} variantDto={item} key={item.id} />
                )
              })}
              <Dropdown show={dropdownOpen} onToggle={toggle} className="circleAddButton_with_dropdown" alignRight={true}>
                <DropdownToggle id="caret" size="sm">
                  <i className="fa fa-plus" aria-hidden="true"></i> &nbsp;&nbsp;dodaj wariant
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={handleAddVariantImage}>Wariant z grafiką</DropdownItem>
                  <DropdownItem onClick={handleAddVariantText}>Wariant z opisem</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  )
}

export default InputItem;