export enum CompanyInformationsEnum {
  userFirstname = 1,
  userLastname = 2,
  companyName = 3,
  companyTaxId = 4,
  addressLineOne = 5,
  addressLineTwo = 6,
  addressPostalCode = 7,
  addressCity = 8,
  addressCountry = 9
}